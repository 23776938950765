<template>
	<v-dialog v-model="value" fullscreen persistent @input="onDialogClosed">
		<v-card class="p-0">
			<v-toolbar flat>
				<v-toolbar-title>{{ $t("edit_template") }}</v-toolbar-title>
				<v-select
					:items="templates"
					item-text="name"
					item-value="_id"
					v-model="selectedTemplateId"
					class="ml-4 mt-4"
					:label="$t('template')"
					:loading="loadingTemplates"
					:disabled="loadingTemplates || !isIframeReady"
					append-icon="icon-angle-down"
					dense
					hide-details
					style="max-width: 300px"
					:menu-props="{ offsetY: true }"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title>{{ item.name }}</v-list-item-title>
							<v-list-item-subtitle>{{ $t(item.origin) }}</v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-select>
				<v-spacer></v-spacer>
				<v-spacer />
				<v-btn
					text
					outlined
					elevation="0"
					@click="deleteSelectedTemplate"
					:disabled="!isIframeReady || selectedTemplate?.origin === 'general'"
					class="mx-2 my-1"
				>
					<v-icon color="red">icon-delete</v-icon>
					<span class="ml-1 v-typography--body-sm">
						{{ $t("delete_template") }}
					</span>
				</v-btn>
				<v-btn color="primary" class="mx-2 my-1" @click="openAddTemplateDialog" :disabled="!isIframeReady">
					<span class="ml-1 v-typography--body-sm">
						{{ $t("template_actions") }}
					</span>
				</v-btn>
				<v-btn icon @click="onDialogClosed">
					<v-icon>icon-cross</v-icon>
				</v-btn>
			</v-toolbar>
			<v-divider />
			<div>
				<iframe ref="designerIframe" :src="designerSrc" frameborder="0" class="full-iframe"></iframe>
			</div>
		</v-card>
		<add-pdf-template-dialog
			:visibility.sync="isAddTemplateDialogVisible"
			:existingTemplates="corporationTemplates"
			:initialSelectedTemplateId="selectedTemplateId"
			@saved="onAddTemplateSaved"
		/>
	</v-dialog>
</template>

<script>
import { mapState } from "vuex";
import remote from "../../../../data/remote";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import AddPdfTemplateDialog from "../../proforma_invoice/dialogs/AddPdfTemplateDialog.vue";

const DESIGNER_ORIGIN = process.env.VUE_APP_PDF_CREATE_URL_ROOT;

export default {
	name: "TemplateDesignDialog",
	mixins: [uiMixin],
	props: {
		value: { type: Boolean, required: true },
		selectedValue: { type: String, default: null },
	},
	components: {
		AddPdfTemplateDialog,
	},
	data() {
		return {
			templates: [],
			selectedTemplateId: null,
			loadingTemplates: false,
			isIframeReady: false,
			designerSrc: DESIGNER_ORIGIN,
			isAddTemplateDialogVisible: false,
			isSavingTemplate: false,
			pendingAddTemplateName: null,
			overrideTemplateId: null,
			isOverrideTemplate: false,
		};
	},
	computed: {
		...mapState(["corporation"]),
		selectedTemplate() {
			const template = this.templates.find((t) => t._id === this.selectedTemplateId);
			return template;
		},
		corporationTemplates() {
			return this.templates.filter((template) => template.origin === "corporation");
		},
	},
	methods: {
		deleteSelectedTemplate() {
			if (!this.selectedTemplate) {
				this.showSnackBar({ message: "No template selected", color: "warning" });
				return;
			}

			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					const origin = this.selectedTemplate.origin;
					if (origin === "corporation") {
						remote.corporations.deletePdfTemplate(this.corporation.id, this.selectedTemplate._id, {
							onSuccess: () => {
								this.showSnackBar({ message: this.$t("template_deleted"), color: "success" });
								this.fetchAllPdfTemplates();
							},
							onFail: (error) => console.error("Error deleting corporation template:", error),
						});
					}
					this.$emit("change");
				},
			});
		},
		overridePdfTemplate(templateId) {
			this.overrideTemplateId = templateId;
			this.isOverrideTemplate = true;
			this.saveTemplate();
		},
		saveTemplate() {
			this.postMessageToIframe({ type: "SAVE_TEMPLATE" });
		},
		onAddTemplateSaved(payload) {
			if (payload.action === "save-as-new") {
				this.pendingAddTemplateName = payload.name;
				this.isSavingTemplate = true;
				this.saveTemplate();
			} else if (payload.action === "override") {
				this.isOverrideTemplate = true;
				this.overridePdfTemplate(payload.id);
			}
		},
		openAddTemplateDialog() {
			this.isAddTemplateDialogVisible = true;
		},
		fetchAllPdfTemplates() {
			this.loadingTemplates = true;
			remote.corporations.getPdfTemplates({
				onSuccess: (generalTemplates) => {
					remote.corporations.getPdfTemplate(this.corporation.id, {
						onSuccess: (corpTemplates) => {
							this.templates = [
								...corpTemplates
									.slice()
									.reverse()
									.map((t) => ({ ...t, origin: "corporation" })),
								...generalTemplates.map((t) => ({ ...t, origin: "general" })),
							];
							this.loadingTemplates = false;
							if (this.templates.length) {
								for (const template of this.templates) {
									if (template._id === this.selectedValue) {
										this.selectedTemplateId = this.selectedValue;
										break;
									} else {
										this.selectedTemplateId = this.templates[0]._id;
									}
								}
							} else {
								console.warn("No templates available.");
							}
						},
						onError: (error) => {
							console.error("Error loading corporate templates:", error);
							this.showSnackBar({
								message: this.$t("template_load_error", "Error loading templates."),
								color: "error",
							});
							this.loadingTemplates = false;
						},
					});
				},
				onError: (error) => {
					console.error("Error loading general templates:", error);
					this.showSnackBar({ message: this.$t("template_load_error", "Error loading templates."), color: "error" });
					this.loadingTemplates = false;
				},
			});
		},
		postMessageToIframe(message) {
			const iframe = this.$refs.designerIframe;
			if (iframe && iframe.contentWindow) {
				iframe.contentWindow.postMessage(message, DESIGNER_ORIGIN);
			}
		},
		sendTemplateToDesigner() {
			const iframe = this.$refs.designerIframe;
			if (!iframe?.contentWindow || !this.selectedTemplate) {
				console.warn("Iframe not ready or no template selected.");
				return;
			}

			const templateData = this.prepareTemplateSchema(this.selectedTemplate);

			iframe.contentWindow.postMessage(
				{
					type: "LOAD_TEMPLATE",
					data: templateData,
				},
				DESIGNER_ORIGIN
			);
		},
		prepareTemplateSchema(template) {
			const schema = {
				_id: template._id,
				name: template.name,
				schemas: template.schemas,
				basePdf: template.basePdf,
			};
			return schema;
		},
		adjustReadOnlyInTemplate(templateData, value) {
			templateData.schemas.forEach((page) => {
				page.forEach((field) => {
					if ("readOnly" in field) {
						field.readOnly = value;
					}
				});
			});
		},
		addPdfTemplate(template) {
			remote.corporations.addPdfTemplate(this.corporation.id, template, {
				onSuccess: () => {
					this.showSnackBar({ message: this.$t("template_added"), color: "success" });
					this.$emit("change");
					this.fetchAllPdfTemplates();
				},
				onFail: (error) => {
					console.error("Error adding PDF Template:", error);
				},
			});
		},
		handleDesignerMessage(event) {
			if (event.origin + "/" !== DESIGNER_ORIGIN) return;

			const messageData = event.data || {};
			switch (messageData.type) {
				case "READY":
					this.isIframeReady = true;
					if (this.selectedTemplate) {
						this.sendTemplateToDesigner();
					}
					break;

				case "LOADED":
					this.hideProgressBar();
					this.isIframeReady = true;
					break;

				case "TEMPLATE_DATA":
					{
						const templatePayload = messageData.template;
						this.adjustReadOnlyInTemplate(templatePayload, false);
						if (this.isSavingTemplate && this.pendingAddTemplateName) {
							const newTemplate = {
								name: this.pendingAddTemplateName,
								schemas: templatePayload.schemas,
								basePdf: templatePayload.basePdf,
							};
							this.pendingAddTemplateName = null;
							this.isSavingTemplate = false;
							this.addPdfTemplate(newTemplate);
						} else if (this.isOverrideTemplate) {
							const templateData = { schemas: templatePayload.schemas };
							remote.corporations.updatePdfTemplate(this.corporation.id, this.overrideTemplateId, templateData, {
								onSuccess: () => {
									this.isOverrideTemplate = false;
									this.showSnackBar({ message: this.$t("template_updated"), color: "success" });
									this.$emit("change");
									this.fetchAllPdfTemplates();
								},
								onFail: (err) => {
									console.error("Error overriding PDF Template:", err);
									this.isOverrideTemplate = false;
								},
							});
						} else {
							console.warn("Received TEMPLATE_DATA without a known operation.");
						}
					}
					break;
				default:
					console.warn("Unknown message type:", messageData.type);
			}
		},
		onDialogClosed() {
			this.$emit("input", false);
			this.$emit("closed", this.selectedTemplateId);
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(newVal) {
				if (newVal) {
					window.addEventListener("message", this.handleDesignerMessage);
					this.fetchAllPdfTemplates();
				} else {
					window.removeEventListener("message", this.handleDesignerMessage);
				}
			},
		},
		selectedTemplateId(newVal) {
			if (this.isIframeReady && newVal) {
				this.sendTemplateToDesigner();
			}
		},
	},
	beforeDestroy() {
		window.removeEventListener("message", this.handleDesignerMessage);
	},
};
</script>

<style scoped>
.full-iframe {
	width: 100%;
	height: 90vh;
	border: none;
	overflow: hidden;
}
</style>
