<template>
	<v-dialog v-model="value" fullscreen persistent @input="onDialogClosed">
		<v-card class="p-0">
			<v-toolbar flat>
				<v-toolbar-title> {{ $t("document_manager") }} </v-toolbar-title>

				<v-select
					v-model="selectedTemplateId"
					:items="templates"
					item-text="name"
					item-value="_id"
					:label="$t('document')"
					hide-details
					dense
					class="mt-4 ml-4"
					style="max-width: 200px"
					append-icon="icon-angle-down"
					:menu-props="{ offsetY: true }"
					:loading="loadingTemplates"
					:disabled="loadingTemplates || templates.length === 0"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title>{{ item.name }}</v-list-item-title>
							<v-list-item-subtitle>{{ $t(item.origin) }}</v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-select>

				<v-select
					:items="availableLanguages"
					item-text="text"
					item-value="value"
					:label="$t('language')"
					append-icon="icon-angle-down"
					v-model="selectedLanguage"
					:disabled="!isIframeReady"
					class="mx-2 mt-4"
					:menu-props="{ offsetY: true }"
					dense
					hide-details
					style="max-width: 100px"
				>
					<template v-slot:item="{ item }">
						{{ item.text }}
					</template>
				</v-select>
				<v-btn color="primary" class="mx-2 my-1" @click="showDesigner = true">
					<span class="ml-1 v-typography--body-sm">
						{{ $t("edit_template") }}
					</span>
				</v-btn>

				<v-spacer />
				<v-btn
					text
					outlined
					elevation="0"
					@click="deleteSelectedTemplate"
					:disabled="!isIframeReady || !selectedTemplateObject || selectedTemplateObject.origin !== 'document'"
					class="mx-2 my-1"
				>
					<v-icon color="red">icon-delete</v-icon>
					<span class="ml-1 v-typography--body-sm">
						{{ $t("delete_document") }}
					</span>
				</v-btn>

				<v-btn color="primary" @click="isSavePdfDialogVisible = true" :disabled="!isIframeReady">
					<span class="ml-1 v-typography--body-sm">
						{{ $t("document_actions") }}
					</span>
				</v-btn>

				<v-btn icon @click="onDialogClosed">
					<v-icon>icon-cross</v-icon>
				</v-btn>
			</v-toolbar>
			<v-divider />

			<div class="mt-2 px-5" flat>
				<div class="info-bg">
					<div class="d-flex justify-center">
						<v-icon color="#fff" class="mr-2">icon-comment-info</v-icon>
						<div>{{ currentText }}</div>
					</div>
					<div class="d-flex justify-center">
						<v-btn icon @click="prevText">
							<v-icon color="#fff">icon-angle-left</v-icon>
						</v-btn>
						<v-btn icon @click="nextText">
							<v-icon color="#fff">icon-angle-right</v-icon>
						</v-btn>
					</div>
				</div>
			</div>

			<div>
				<iframe v-if="value" ref="reactIframe" :src="iframeSrc" frameborder="0" class="full-iframe"></iframe>
			</div>
		</v-card>

		<designer
			v-model="showDesigner"
			@change="handleDesignerChange"
			@closed="onDesignerClosed"
			:selectedValue="selectedTemplateId"
		/>
		<save-pdf-dialog
			:visibility.sync="isSavePdfDialogVisible"
			:existing-docs="templates.filter((t) => t.origin === 'document')"
			:initial-selected-doc-id="validSelectedTemplateId"
			@saved="onSavePdfDialogSaved"
		/>
	</v-dialog>
</template>

<script>
import Designer from "./Designer.vue";
import SavePdfDialog from "../../proforma_invoice/dialogs/SavePdfDialog.vue";
import remote from "../../../../data/remote";
import { mapGetters, mapState } from "vuex";
import { AccessModifier } from "../../../../utils/enum";
import uiMixin from "../../../../store/interfaces/ui.mixin";

const ALLOWED_ORIGIN = process.env.VUE_APP_DEMO_GRID_URL;

export default {
	name: "DemoGridDialog",
	props: {
		value: { type: Boolean, required: true },
		documentObject: { type: Object, required: true },
		documentType: { type: String, required: true },
	},
	mixins: [uiMixin],
	components: {
		Designer,
		SavePdfDialog,
	},
	emits: ["closed", "change"],
	data() {
		return {
			showDesigner: false,
			isIframeReady: false,
			iframeSrc: ALLOWED_ORIGIN,
			allowedOrigins: [new URL(ALLOWED_ORIGIN).origin],
			templates: [],
			selectedTemplateId: null,
			loadingTemplates: false,
			isSavePdfDialogVisible: false,
			pendingAttachToDoc: false,
			pendingSaveDocument: false,
			pendingDocumentFilename: null,
			pendingAttachmentFilename: null,

			isOverrideDocument: false,
			overrideDocumentId: null,
			snackBarMessage: "",

			selectedLanguage: "en",
			availableLanguages: [
				// { value: "ar", text: this.$t("$languageLocal.ar") },
				{ value: "de", text: this.$t("$languageLocal.de") },
				{ value: "en", text: this.$t("$languageLocal.en") },
				{ value: "es", text: this.$t("$languageLocal.es") },
				{ value: "fr", text: this.$t("$languageLocal.fr") },
				// { value: "hi", text: this.$t("$languageLocal.hi") },
				{ value: "ind", text: this.$t("$languageLocal.ind") },
				{ value: "it", text: this.$t("$languageLocal.it") },
				// { value: "ja", text: this.$t("$languageLocal.ja") },
				// { value: "ko", text: this.$t("$languageLocal.ko") },
				{ value: "pt", text: this.$t("$languageLocal.pt") },
				{ value: "ru", text: this.$t("$languageLocal.ru") },
				{ value: "tr", text: this.$t("$languageLocal.tr") },
				{ value: "vi", text: this.$t("$languageLocal.vi") },
				// { value: "zh", text: this.$t("$languageLocal.zh") },
			],
			translation: {
				table_inputs: this.$t("table_inputs"),
				number_of_rows: this.$t("number_of_rows"),
				okay: this.$t("ok"),
				inputs: this.$t("inputs"),
				preview: this.$t("preview"),
			},
			translations: null,
			currentIndex: 0,
			sliderInterval: null,
		};
	},
	computed: {
		...mapState(["corporation"]),
		...mapGetters(["company"]),
		sliderMessages() {
			return [
				this.$t("$documentManager.message_1"),
				this.$t("$documentManager.message_2"),
				this.$t("$documentManager.message_3"),
				this.$t("$documentManager.message_4"),
				this.$t("$documentManager.message_5"),
				this.$t("$documentManager.message_6"),
				this.$t("$documentManager.message_7"),
			];
		},
		currentText() {
			return this.sliderMessages[this.currentIndex];
		},
		selectedTemplateObject() {
			return this.templates.find((t) => t._id === this.selectedTemplateId) ?? null;
		},
		validSelectedTemplateId() {
			return this.templates.some((doc) => doc._id === this.selectedTemplateId) ? this.selectedTemplateId : null;
		},
	},
	watch: {
		value(newVal) {
			if (newVal) {
				this.resetState();
				this.isIframeReady = false;
				this.selectedTemplateId = null;
				this.iframeSrc = `${ALLOWED_ORIGIN}?t=${Date.now()}`;
				this.showProgressBar();
				this.getPartnerDetails();
				this.fetchTemplates();
			} else {
				this.resetState();
			}
		},

		documentObject: {
			deep: true,
			handler(newVal) {
				if (this.value && newVal?.id) {
					this.fetchTemplates();
				}
			},
		},

		selectedTemplateId(newVal) {
			if (this.isIframeReady && this.value && newVal) {
				const templateData = this.selectedTemplateObject?.template;
				if (templateData) {
					const translatedTemplate = this.translateStaticLabels(templateData);
					this.postMessageToIframe({
						type: "LOAD_TEMPLATE",
						data: translatedTemplate,
						translationObject: this.translation,
					});
				}
			}
		},

		async selectedLanguage(newVal, oldVal) {
			if (newVal !== oldVal) {
				await this.loadTranslations();
				if (this.isIframeReady && this.selectedTemplateId) {
					const templateData = this.selectedTemplateObject?.template;
					if (templateData) {
						const translatedTemplate = this.translateStaticLabels(templateData);
						this.postMessageToIframe({
							type: "LOAD_TEMPLATE",
							data: translatedTemplate,
							translationObject: this.translation,
						});
					}
				}
			}
		},
	},
	mounted() {
		this.loadTranslations();
		window.addEventListener("message", this.handleMessage);
		this.sliderInterval = setInterval(() => {
			this.nextText();
		}, 5000);
	},
	beforeUnmount() {
		window.removeEventListener("message", this.handleMessage);
		clearInterval(this.sliderInterval);
	},
	methods: {
		resetState() {
			this.templates = [];
			this.selectedTemplateId = null;
			this.loadingTemplates = false;
			this.isSavePdfDialogVisible = false;
			this.showDesigner = false;
			this.pendingAttachToDoc = false;
			this.pendingSaveDocument = false;
			this.pendingDocumentFilename = null;
			this.pendingAttachmentFilename = null;
			this.isOverrideDocument = false;
			this.overrideDocumentId = null;
			this.isIframeReady = false;
		},
		onDesignerClosed(selectedId) {
			if (selectedId) {
				this.selectedTemplateId = selectedId;
			}
		},
		handleDesignerChange() {
			this.$emit("change");
			this.fetchTemplates();
		},
		nextText() {
			this.currentIndex = (this.currentIndex + 1) % this.sliderMessages.length;
		},
		prevText() {
			this.currentIndex = (this.currentIndex - 1 + this.sliderMessages.length) % this.sliderMessages.length;
		},
		getPartnerDetails() {
			const partner =
				this.documentType === "shipment"
					? this.documentObject.receiver.company
					: this.documentType === "proforma"
					? this.documentObject.receiver
					: null;
			const corporationId = this.corporation.id;
			if (partner.isAbstract) {
				remote.partners.find(corporationId, partner.referenceId, {
					onSuccess: (partnerDetails) => {
						this.documentObject.receiver.companyAddress = partnerDetails.address;
					},
					onFail: (error) => {
						console.error("Error fetching partner details:", error);
					},
				});
			} else {
				remote.partners.findByCorporationId(corporationId, partner.referenceId, {
					onSuccess: (partnerDetails) => {
						this.documentObject.receiver.companyAddress = partnerDetails.address;
					},
					onFail: (error) => {
						console.error("Error fetching partner details:", error);
					},
				});
			}
		},
		getShipmentMethod(shipmentMethod) {
			return this.getTranslation(`$shipment.$method.${shipmentMethod}`);
		},
		async loadTranslations() {
			try {
				const messages = await import(`../../../../plugins/i18n/${this.selectedLanguage}.js`);
				this.translations = messages.default;
			} catch (error) {
				console.error(`Failed to load translations for ${this.selectedLanguage}:`, error);
				const messages = await import(`../../../../plugins/i18n/en.js`);
				this.translations = messages.default;
			}
		},

		getTranslation(key) {
			if (!this.translations) return key;
			const segments = key.split(".");
			let result = this.translations;
			for (const segment of segments) {
				if (result && segment in result) {
					result = result[segment];
				} else {
					return key;
				}
			}
			return result || key;
		},
		fillFieldContent(currentArray) {
			const currentItem = this.documentObject;
			const fieldMappings = {
				companyName:
					this.documentType === "proforma"
						? currentItem.sender.name
						: this.documentType === "shipment"
						? currentItem.sender.company?.name
						: this.getTranslation("company_name"),
				companyAddress:
					[this.company.address, this.company.phoneNumber, this.company.email, this.company.website]
						.filter((part) => part)
						.join(" - ") || this.getTranslation("company_address"),
				customer_company_name:
					this.documentType === "proforma"
						? currentItem.receiver.name
						: this.documentType === "shipment"
						? currentItem.receiver.company?.name
						: this.getTranslation("customer_company_name"),
				customer_company_address:
					currentItem.receiver.companyAddress || this.getTranslation("customer_company_address"),
				date: new Date(currentItem.date).toLocaleDateString(this.selectedLanguage) || this.getTranslation("date"),
				invoiceNo:
					this.documentType === "shipment"
						? currentItem.bookingCode
						: this.documentType === "proforma"
						? currentItem.no
						: this.getTranslation("invoice_no"),
				shipmentMethod:
					this.documentType === "proforma"
						? this.getShipmentMethod(currentItem.shipmentMethod)
						: this.documentType === "shipment"
						? this.getShipmentMethod(currentItem.method)
						: this.getTranslation("shipment_method"),
				shippingTerm: currentItem.shippingTerm.name || this.getTranslation("shipping_term"),
				loading:
					`${currentItem.loadingPort !== undefined ? currentItem.loadingPort?.name : currentItem?.loadingPoint ?? ""}${
						currentItem.loadingCountry && currentItem.loadingCountry.name
							? `, ${this.getCountryName(currentItem.loadingCountry.name)}`
							: ""
					}` || this.getTranslation("loading_point"),
				unloading:
					`${
						currentItem.unloadingPort !== undefined
							? currentItem.unloadingPort?.name
							: currentItem?.unloadingPoint ?? ""
					}${
						currentItem.unloadingCountry && currentItem.unloadingCountry.name
							? `, ${this.getCountryName(currentItem.unloadingCountry.name)}`
							: ""
					}` || this.getTranslation("unloading_point"),
			};
			const staticLabels = {
				proformaTitle: this.documentType === "shipment" ? "invoice" : "proforma_invoice",
				packingListTitle: "packing_list_title",
				billedToLabel: "billed_to",
				shipmentMethod: "shipment_method",
				invoiceNo: "invoice_no",
				date: "date",
				shippingTerm: "shipping_term",
				loading: "loading_point",
				unloading: "unloading_point",
				terms: "terms_of_sale_and_other_comments",
				terms_paragraph: "terms_of_sale_and_other_comments",
				payment_info: "payment_info",
				total: "total",
			};
			currentArray.forEach((field) => {
				if (field.type === "text") {
					if (field.name in fieldMappings) {
						const value = fieldMappings[field.name];
						if (field.name in staticLabels) {
							const labelKey = staticLabels[field.name];
							const label = this.getTranslation(labelKey);
							field.content = `${label}: ${value}`;
						} else {
							field.content = `${value}`;
						}
					} else if (field.name === "total") {
						const existingValue = field.content.replace(/^.*?:\s*/, "");
						const label = this.getTranslation(staticLabels.total);
						field.content = `${label}: ${existingValue}`;
					} else if (field.name in staticLabels) {
						const labelKey = staticLabels[field.name];
						const label = this.getTranslation(labelKey);
						field.content = label;
					}
				}
				if (field.type === "table" && Array.isArray(field.head) && field.name.startsWith("orders")) {
					const defaultHeads = ["product", "quantity", "unit", "total"];
					const isStillDefault =
						field.head.length === defaultHeads.length &&
						field.head.every((val, i) => [defaultHeads[i], this.getTranslation(defaultHeads[i])].includes(val));
					if (isStillDefault) {
						field.head = defaultHeads.map((h) => this.getTranslation(h));
					}
				}
				if (field.name === "containers") {
					let label;
					if (this.documentType === "shipment") {
						label =
							this.documentObject.method === "land"
								? this.getTranslation("plate_numbers")
								: this.getTranslation("containers");
					} else {
						label =
							this.documentObject.shipmentMethod === "land"
								? this.getTranslation("plate_numbers")
								: this.getTranslation("containers");
					}
					if (this.documentObject.containers && this.documentObject.containers.length > 0) {
						const containerNumbers = this.documentObject.containers.map((container) => container.code).join(", ");
						field.content = `${label}: ${containerNumbers}`;
					} else {
						field.content = `${label}: `;
					}
				}

				if (field.type === "table" && field.name === "orders" && this.documentObject.origin !== "document") {
					let tableData = [];
					let total = 0;
					if (
						this.documentType === "shipment" &&
						this.selectedContainer &&
						Array.isArray(this.selectedContainer.products)
					) {
						tableData = this.selectedContainer.products.map((product) => {
							const productName = (product.name && product.name[this.selectedLanguage]) || product.name?._default;
							const qty = product.quantity;
							const amt = parseFloat(product.amount || 0);
							const formattedAmt = amt.toLocaleString(this.$lang, {
								style: "currency",
								currency: currentItem.currency.code,
							});
							const lineTotal = qty * amt;
							total += lineTotal;
							const formattedLineTotal = lineTotal.toLocaleString(this.$lang, {
								style: "currency",
								currency: currentItem.currency.code,
							});
							return [productName, `${qty}${product.unitCode || ""}`, formattedAmt, formattedLineTotal];
						});
					} else if (Array.isArray(currentItem.products)) {
						tableData = currentItem.products.map((product) => {
							const productName = (product.name && product.name[this.selectedLanguage]) || product.name?._default;
							const qty = product.quantity;
							const amt = parseFloat(product.amount || 0);
							const formattedAmt = amt.toLocaleString(this.$lang, {
								style: "currency",
								currency: currentItem.currency.code,
							});
							const lineTotal = qty * amt;
							total += lineTotal;
							const formattedLineTotal = lineTotal.toLocaleString(this.$lang, {
								style: "currency",
								currency: currentItem.currency.code,
							});
							return [productName, `${qty}${product.unitCode || ""}`, formattedAmt, formattedLineTotal];
						});
					} else {
						tableData = [];
					}
					field.content = JSON.stringify(tableData);
					currentArray.forEach((f) => {
						if (f.type === "text" && f.name === "total") {
							f.content = `${this.getTranslation("total")}: ${total.toLocaleString(this.$lang, {
								style: "currency",
								currency: currentItem.currency.code,
							})}`;
						}
					});
				}
				if (field.type === "svg" || field.type === "image" || field.type === "signature") {
					field.readOnly = true;
				}
			});
			return currentArray;
		},
		getCountryName(countryName) {
			return this.getTranslation(`$country.${countryName}`);
		},
		translateStaticLabels(template) {
			const translatedTemplate = JSON.parse(JSON.stringify(template));
			translatedTemplate.schemas.forEach((page, index) => {
				translatedTemplate.schemas[index] = this.fillFieldContent(page);
			});
			return translatedTemplate;
		},
		onDialogClosed() {
			this.$emit("input", false);
			this.resetState();
		},
		fetchTemplates() {
			if (!this.documentObject) return;
			this.loadingTemplates = true;
			this.showProgressBar();
			let allTemplates = [];
			const docTmpls = (this.documentObject.pdfTemplates || [])
				.slice()
				.reverse()
				.map((tmpl) => ({
					...tmpl,
					origin: "document",
				}));
			remote.corporations.getPdfTemplate(this.corporation.id, {
				onSuccess: (corpTemplates) => {
					const corpTmpls = corpTemplates
						.slice()
						.reverse()
						.map((tmpl) => ({ ...tmpl, origin: "corporation" }));
					remote.corporations.getPdfTemplates({
						onSuccess: (generalTemplates) => {
							const genTmpls = generalTemplates
								.slice()
								.reverse()
								.map((tmpl) => ({ ...tmpl, origin: "general" }));
							allTemplates = [...docTmpls, ...corpTmpls, ...genTmpls];
							this.templates = allTemplates.map((t) => ({
								...t,
								template: this.cleanTemplateData(t),
							}));
							this.selectedTemplateId = this.templates.length ? this.templates[0]._id : null;
							this.loadingTemplates = false;
							this.hideProgressBar();
						},
						onFail: (err) => {
							console.error("Error fetching general templates:", err);
							this.loadingTemplates = false;
							this.hideProgressBar();
						},
					});
				},
				onFail: (err) => {
					console.error("Error fetching corp templates:", err);
					this.loadingTemplates = false;
					this.hideProgressBar();
				},
			});
		},
		cleanTemplateData(template) {
			return JSON.parse(
				JSON.stringify({
					_id: template._id,
					name: template.name,
					schemas: template.schemas,
					basePdf: template.basePdf,
					origin: template.origin,
				})
			);
		},
		postMessageToIframe(message) {
			const iframe = this.$refs.reactIframe;
			if (iframe?.contentWindow) {
				try {
					JSON.parse(JSON.stringify(message));
					iframe.contentWindow.postMessage(message, ALLOWED_ORIGIN);
				} catch (error) {
					console.error("Failed to send message to iframe:", error);
				}
			}
		},
		handleMessage(event) {
			if (!this.allowedOrigins.includes(event.origin)) {
				console.warn("Unauthorized origin:", event.origin);
				return;
			}
			const { type, template, pdfBase64 } = event.data || {};
			switch (type) {
				case "READY":
					if (!this.isIframeReady) {
						this.isIframeReady = true;
						if (this.selectedTemplateId) {
							const templateData = this.selectedTemplateObject?.template;
							if (templateData) {
								const translatedTemplate = this.translateStaticLabels(templateData);
								this.postMessageToIframe({
									type: "LOAD_TEMPLATE",
									data: translatedTemplate,
									translationObject: this.translation,
								});
							}
						}
					}
					break;
				case "LOADED":
					break;
				case "TEMPLATE_DATA":
					this.handleTemplateData(template);
					break;
				case "PDF_DATA":
					if (pdfBase64) {
						this.handlePdfData(pdfBase64);
					}
					break;
				default:
					break;
			}
		},
		handleTemplateData(templatePayload) {
			if (this.isOverrideDocument) {
				const templateData = { schemas: templatePayload.schemas };
				if (this.documentType === "proforma") {
					remote.proformaInvoices.updatePdfTemplate(this.documentObject.id, this.overrideDocumentId, templateData, {
						onSuccess: () => {
							if (this.pendingAttachToDoc === false) {
								this.showSnackBar({ message: this.$t("document_updated") });
							} else {
								this.snackBarMessage = this.$t("document_updated");
							}
							this.$emit("change");
						},
						onFail: (err) => {
							console.error("Failed to override doc-based PDF template:", err);
						},
					});
				} else if (this.documentType === "shipment") {
					remote.shipments.updatePdfTemplate(this.documentObject.id, this.overrideDocumentId, templateData, {
						onSuccess: () => {
							if (this.pendingAttachToDoc === false) {
								this.showSnackBar({ message: this.$t("document_updated") });
							} else {
								this.snackBarMessage = this.$t("document_updated");
							}
							this.$emit("change");
						},
						onFail: (err) => {
							console.error("Failed to override doc-based PDF template:", err);
						},
					});
				}
				this.isOverrideDocument = false;
				this.overrideDocumentId = null;
			} else if (this.pendingSaveDocument) {
				const templateData = {
					name: this.pendingDocumentFilename,
					schemas: templatePayload.schemas,
					basePdf: templatePayload.basePdf,
				};
				this.addDocument(templateData, {
					onSuccess: () => {
						if (this.pendingAttachToDoc === false) {
							this.showSnackBar({ message: this.$t("document_added") });
						} else {
							this.snackBarMessage = this.$t("document_added");
						}
						this.$emit("change");
					},
					onFail: (err) => {
						console.error("Failed to update doc with new PDF template:", err);
					},
				});
				this.pendingSaveDocument = false;
			} else {
				console.warn("TEMPLATE_DATA with no known operation (override/save).");
			}
		},
		addDocument(docCopy, { onSuccess, onFail }) {
			if (this.documentType === "proforma") {
				remote.proformaInvoices.addPdfTemplate(this.documentObject.id, docCopy, { onSuccess, onFail });
			} else if (this.documentType === "shipment") {
				remote.shipments.addPdfTemplate(this.documentObject.id, docCopy, { onSuccess, onFail });
			}
		},
		handlePdfData(pdfBase64) {
			if (!this.pendingAttachToDoc) return;
			const byteString = window.atob(pdfBase64);
			const arrayBuffer = new ArrayBuffer(byteString.length);
			const uint8Array = new Uint8Array(arrayBuffer);
			for (let i = 0; i < byteString.length; i++) {
				uint8Array[i] = byteString.charCodeAt(i);
			}
			const blob = new Blob([arrayBuffer], { type: "application/pdf" });
			const attachmentName = this.pendingAttachmentFilename || "document";
			const file = new File([blob], `${attachmentName}.pdf`, { type: "application/pdf" });
			remote.files.send(file, this.corporation.id, {
				onSuccess: (uploadedFile) => {
					const newAttachment = {
						name: attachmentName,
						note: this.$t("auto_attached_pdf"),
						file: uploadedFile,
					};
					this.attachFileToDocument(newAttachment);
					this.showSnackBar({ message: `${this.snackBarMessage} ${this.$t("pdf_attached_message")}` });
					this.$emit("change", { action: "attach-pdf", attachment: newAttachment });
				},
				onFail: (err) => {
					console.error("File upload failed:", err);
				},
			});
			this.pendingAttachmentFilename = null;
			this.pendingAttachToDoc = false;
		},
		attachFileToDocument(newAttachment) {
			const docId = this.documentObject.id;
			if (this.documentType === "proforma") {
				remote.proformaInvoices.attachments.create(newAttachment, docId, {
					onSuccess: () => {
						this.$emit("change");
					},
					onFail: (err) => {
						console.error("Failed to create proforma attachment:", err);
					},
				});
			} else if (this.documentType === "shipment") {
				const modifiedAttachment = {
					...newAttachment,
					ownerId: this.corporation.id,
					accessModifier: AccessModifier.Public,
				};
				remote.shipments.attachments.create(modifiedAttachment, docId, {
					onSuccess: () => {
						this.$emit("change");
					},
					onFail: (err) => {
						console.error("Failed to create shipment attachment:", err);
					},
				});
			}
		},
		updateDocument(docCopy, { onSuccess, onFail }) {
			if (this.documentType === "shipment") {
				remote.shipments.update(docCopy, { onSuccess, onFail });
			} else if (this.documentType === "proforma") {
				remote.proformaInvoices.update(docCopy, { onSuccess, onFail });
			} else if (onSuccess) {
				onSuccess();
			}
		},
		onSavePdfDialogSaved({ action, docId, fileName, attach, pdfName }) {
			if (action === "save-as-new") {
				this.pendingDocumentFilename = fileName;
				this.pendingAttachmentFilename = attach ? pdfName : null;
			} else {
				this.pendingAttachmentFilename = attach ? pdfName : null;
			}
			this.pendingAttachToDoc = attach;
			const iframe = this.$refs.reactIframe;
			if (!iframe || !iframe.contentWindow) {
				console.error("Iframe not found");
				return;
			}
			if (action === "override") {
				this.isOverrideDocument = true;
				this.overrideDocumentId = docId;
				iframe.contentWindow.postMessage({ type: "SAVE_TEMPLATE" }, ALLOWED_ORIGIN);
				if (attach) {
					iframe.contentWindow.postMessage({ type: "REQUEST_PDF_DATA" }, ALLOWED_ORIGIN);
				}
			} else {
				this.pendingSaveDocument = true;
				iframe.contentWindow.postMessage({ type: "SAVE_TEMPLATE" }, ALLOWED_ORIGIN);
				if (attach) {
					iframe.contentWindow.postMessage({ type: "REQUEST_PDF_DATA" }, ALLOWED_ORIGIN);
				}
			}
		},
		deleteSelectedTemplate() {
			if (!this.selectedTemplateObject) {
				this.showSnackBar({
					message: "No template selected",
					color: "warning",
				});
				return;
			}
			if (this.selectedTemplateObject.origin !== "document") {
				this.showSnackBar({
					message: "Only document templates can be deleted.",
					color: "warning",
				});
				return;
			}
			const docId = this.documentObject.id;
			const templateId = this.selectedTemplateObject._id;
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					if (this.documentType === "proforma") {
						remote.proformaInvoices.deletePdfTemplate(docId, templateId, {
							onSuccess: () => {
								this.showSnackBar({ message: this.$t("document_deleted") });
								this.$emit("change", { action: "delete", templateId });
							},
							onFail: (err) => {
								console.error("Error deleting proforma template:", err);
							},
						});
					} else if (this.documentType === "shipment") {
						remote.shipments.deletePdfTemplate(docId, templateId, {
							onSuccess: () => {
								this.showSnackBar({ message: this.$t("document_deleted") });
								this.$emit("change", { action: "delete", templateId });
							},
							onFail: (err) => {
								console.error("Error deleting shipment template:", err);
								this.showSnackBar({
									message: "Failed to delete shipment template.",
									color: "error",
								});
							},
						});
					}
				},
			});
		},
	},
};
</script>

<style scoped>
.info-bg {
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #2196f3;
	color: #fff;
	border-radius: 4px;
	padding: 10px;
	box-sizing: border-box;
}
.full-iframe {
	width: 100%;
	height: 83vh;
	border: none;
	overflow: hidden;
}
</style>
