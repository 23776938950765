export default {
	$accessibility: {
		archived: "Archived",
		present: "Present",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Hidden From Receiver",
			hidden_from_sender: "Hidden From Sender",
			private: "Private",
			shared_with_custom: "Shared with Custom",
			shared_with_everyone: "Shared with Everyone",
			shared_with_receiver: "Shared with Receiver",
			shared_with_sender: "Shared with Sender",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB Storage",
		limitMBStorage: "{limit}MB Storage",
		limitUser: "{limit} User",
		limitUsers: "{limit} Users",
		renewAtPricePerUnit: "Will renew at {currency}{price} per {unit}",
		unlimitedModule: "Unlimited {module}",
		upToLimitModule: "Up to {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, TURKEY",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sen!2str!4v1701805110461!5m2!1sen!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan: "Accelerate your business with Antrego. Manage your shipment processes digitally, safely, and easily.",
	},
	$country: {
		afghanistan: "Afghanistan",
		aland: "Åland",
		albania: "Albania",
		algeria: "Algeria",
		american_samoa: "American Samoa",
		andorra: "Andorra",
		angola: "Angola",
		anguilla: "Anguilla",
		antarctica: "Antarctica",
		antigua_and_barbuda: "Antigua and Barbuda",
		argentina: "Argentina",
		armenia: "Armenia",
		aruba: "Aruba",
		australia: "Australia",
		austria: "Austria",
		azerbaijan: "Azerbaijan",
		bahamas: "Bahamas",
		bahrain: "Bahrain",
		bangladesh: "Bangladesh",
		barbados: "Barbados",
		belarus: "Belarus",
		belgium: "Belgium",
		belize: "Belize",
		benin: "Benin",
		bermuda: "Bermuda",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius, and Saba",
		bosnia_and_herzegovina: "Bosnia and Herzegovina",
		botswana: "Botswana",
		bouvet_island: "Bouvet Island",
		brazil: "Brazil",
		british_indian_ocean_territory: "British Indian Ocean Territory",
		british_virgin_islands: "British Virgin Islands",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		burkina_faso: "Burkina Faso",
		burundi: "Burundi",
		cabo_verde: "Cabo Verde",
		cambodia: "Cambodia",
		cameroon: "Cameroon",
		canada: "Canada",
		cayman_islands: "Cayman Islands",
		central_african_republic: "Central African Republic",
		chad: "Chad",
		chile: "Chile",
		china: "China",
		christmas_island: "Christmas Island",
		cocos_islands: "Cocos [Keeling] Islands",
		colombia: "Colombia",
		comoros: "Comoros",
		congo_republic: "Congo Republic",
		cook_islands: "Cook Islands",
		costa_rica: "Costa Rica",
		croatia: "Croatia",
		cuba: "Cuba",
		curacao: "Curaçao",
		cyprus: "Cyprus",
		czechia: "Czechia",
		denmark: "Denmark",
		djibouti: "Djibouti",
		dominica: "Dominica",
		dominican_republic: "Dominican Republic",
		dr_congo: "Democratic Republic of the Congo",
		ecuador: "Ecuador",
		egypt: "Egypt",
		el_salvador: "El Salvador",
		equatorial_guinea: "Equatorial Guinea",
		eritrea: "Eritrea",
		estonia: "Estonia",
		eswatini: "Eswatini",
		ethiopia: "Ethiopia",
		falkland_islands: "Falkland Islands",
		faroe_islands: "Faroe Islands",
		fiji: "Fiji",
		finland: "Finland",
		france: "France",
		french_guiana: "French Guiana",
		french_polynesia: "French Polynesia",
		french_southern_territories: "French Southern Territories",
		gabon: "Gabon",
		gambia: "Gambia",
		georgia: "Georgia",
		germany: "Germany",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greece: "Greece",
		greenland: "Greenland",
		grenada: "Grenada",
		guadeloupe: "Guadeloupe",
		guam: "Guam",
		guatemala: "Guatemala",
		guernsey: "Guernsey",
		guinea: "Guinea",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		haiti: "Haiti",
		heard_island_and_mcdonald_islands: "Heard Island and McDonald Islands",
		honduras: "Honduras",
		hong_kong: "Hong Kong",
		hungary: "Hungary",
		iceland: "Iceland",
		india: "India",
		indonesia: "Indonesia",
		iran: "Iran",
		iraq: "Iraq",
		ireland: "Ireland",
		isle_of_man: "Isle of Man",
		israel: "Israel",
		italy: "Italy",
		ivory_coast: "Ivory Coast",
		jamaica: "Jamaica",
		japan: "Japan",
		jersey: "Jersey",
		jordan: "Jordan",
		kazakhstan: "Kazakhstan",
		kenya: "Kenya",
		kiribati: "Kiribati",
		kosovo: "Kosovo",
		kuwait: "Kuwait",
		kyrgyzstan: "Kyrgyzstan",
		laos: "Laos",
		latvia: "Latvia",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		liechtenstein: "Liechtenstein",
		lithuania: "Lithuania",
		luxembourg: "Luxembourg",
		macao: "Macao",
		madagascar: "Madagascar",
		malawi: "Malawi",
		malaysia: "Malaysia",
		maldives: "Maldives",
		mali: "Mali",
		malta: "Malta",
		marshall_islands: "Marshall Islands",
		martinique: "Martinique",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mayotte: "Mayotte",
		mexico: "Mexico",
		micronesia: "Micronesia",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		montserrat: "Montserrat",
		morocco: "Morocco",
		mozambique: "Mozambique",
		myanmar: "Myanmar",
		namibia: "Namibia",
		nauru: "Nauru",
		nepal: "Nepal",
		netherlands: "Netherlands",
		new_caledonia: "New Caledonia",
		new_zealand: "New Zealand",
		nicaragua: "Nicaragua",
		niger: "Niger",
		nigeria: "Nigeria",
		niue: "Niue",
		norfolk_island: "Norfolk Island",
		north_korea: "North Korea",
		north_macedonia: "North Macedonia",
		northern_mariana_islands: "Northern Mariana Islands",
		norway: "Norway",
		oman: "Oman",
		pakistan: "Pakistan",
		palau: "Palau",
		palestine: "Palestine",
		panama: "Panama",
		papua_new_guinea: "Papua New Guinea",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Philippines",
		pitcairn_islands: "Pitcairn Islands",
		poland: "Poland",
		portugal: "Portugal",
		puerto_rico: "Puerto Rico",
		qatar: "Qatar",
		reunion: "Réunion",
		romania: "Romania",
		russia: "Russia",
		rwanda: "Rwanda",
		saint_barthelemy: "Saint Barthélemy",
		saint_helena: "Saint Helena",
		saint_lucia: "Saint Lucia",
		saint_martin: "Saint Martin",
		saint_pierre_and_miquelon: "Saint Pierre and Miquelon",
		samoa: "Samoa",
		san_marino: "San Marino",
		sao_tome_and_principe: "São Tomé and Príncipe",
		saudi_arabia: "Saudi Arabia",
		senegal: "Senegal",
		serbia: "Serbia",
		seychelles: "Seychelles",
		sierra_leone: "Sierra Leone",
		singapore: "Singapore",
		sint_maarten: "Sint Maarten",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		solomon_islands: "Solomon Islands",
		somalia: "Somalia",
		south_africa: "South Africa",
		south_georgia_and_south_sandwich_islands: "South Georgia and South Sandwich Islands",
		south_korea: "South Korea",
		south_sudan: "South Sudan",
		spain: "Spain",
		sri_lanka: "Sri Lanka",
		st_kitts_and_nevis: "St Kitts and Nevis",
		st_vincent_and_grenadines: "St Vincent and Grenadines",
		sudan: "Sudan",
		suriname: "Suriname",
		svalbard_and_jan_mayen: "Svalbard and Jan Mayen",
		sweden: "Sweden",
		switzerland: "Switzerland",
		syria: "Syria",
		taiwan: "Taiwan",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		timor_leste: "Timor-Leste",
		togo: "Togo",
		tokelau: "Tokelau",
		tonga: "Tonga",
		trinidad_and_tobago: "Trinidad and Tobago",
		tunisia: "Tunisia",
		turkiye: "Turkiye",
		turkmenistan: "Turkmenistan",
		turks_and_caicos_islands: "Turks and Caicos Islands",
		tuvalu: "Tuvalu",
		uganda: "Uganda",
		ukraine: "Ukraine",
		united_arab_emirates: "United Arab Emirates",
		united_kingdom: "United Kingdom",
		united_states: "United States",
		uruguay: "Uruguay",
		us_minor_outlying_islands: "U.S. Minor Outlying Islands",
		us_virgin_islands: "U.S. Virgin Islands",
		uzbekistan: "Uzbekistan",
		vanuatu: "Vanuatu",
		vatican_city: "Vatican City",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		wallis_and_futuna: "Wallis and Futuna",
		western_sahara: "Western Sahara",
		yemen: "Yemen",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		euro: "Euro",
		turkish_lira: "Turkish Lira",
		us_dollar: "U.S. Dollar",
	},
	$documentManager: {
		message_1: 'To edit the template, click the "Edit Template" button to access the editing screen.',
		message_2: 'You can change the number of rows in the list by increasing or decreasing the "Row Count."',
		message_3: 'Changes made in the content fields will instantly appear in the "Preview" section.',
		message_4: 'To generate a PDF, click the "Document Actions" button to save it as a PDF.',
		message_5: 'To access documents later, you can save them via the "Document Actions" button.',
		message_6: "The data entered in each cell of the table will be reflected exactly in the preview.",
		message_7: "The data table can be edited like an Excel sheet or an electronic spreadsheet.",
	},
	$employeeType: {
		admin: "Admin",
		default: "Default",
		exporter: "Exporter",
		importer: "Importer",
	},
	$format: {
		date: "MM/DD/YYYY",
		date_time: "MM/DD/YYYY HH:mm:ss",
		date_time_without_second: "MM/DD/YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Create your company here.",
			create_company_title: "Do you want to add your company?",
			description: "You have signed in successfully. Now you need to join a company or create a new one.",
			join_company_subtitle: "Enter your code and join the company here.",
			join_company_title: "Do you want to join an existing company?",
			title: "Welcome {name}",
		},
		$unauthorized: {
			description: "Keep your export-import processes under control by using Antrego",
			forget_password_subtitle: "Please enter your email address to reset your password.",
			sign_in_subtitle: "Sign in here",
			sign_in_title: "Do you already have an account?",
			sign_up_subtitle: "Sign up here",
			sign_up_title: "Do you want to create your account?",
			title: "Welcome to Antrego",
		},
	},
	$invitationType: {
		company_partner: "Commercial Cooperation Invitation",
		customs_partner: "Customs Partner Invitation",
		employee: "Employee Invitation",
	},
	$landing: {
		$benefits: {
			benefits_title: "Benefits",
			business_process: "Speeds up your business processes.",
			control_title: "Take control with Antrego.",
			highlight_word: "control",
			risk_of_error: "Reduces the risk of errors.",
			text: "Antrego is a shipment tracking program that helps shipping companies, their customers, and customs brokers to track shipment processes via a shared platform.",
			time_and_cost_savings: "Saves time and costs.",
		},
		$content: {
			$text: {
				0: "Track all your shipments in one place, and control your finances with dynamic reporting.",
				1: "Share your real-time updates with your customers to improve your service quality.",
				2: "View and share all documents related to your shipment in one system.",
				3: "Easily control your process by accessing Antrego from all devices and locations.",
				4: "Store all your data using SHA-256 encryption technology. Rest assured about security.",
				5: "Engage in joint tracking with all stakeholders in the business process. Manage the process more efficiently.",
			},
			$title: {
				0: "Shipment Management",
				1: "Customer Relations",
				2: "Document Management",
				3: "Easy Access",
				4: "Data Security",
				5: "Joint Tracking",
			},
		},
		$home: {
			text: "Choose us for seamless digital tracking and joint control.",
			title: "Easily Manage All Your Shipping Processes Digitally",
		},
		$lifecycle: {
			headline: "Process",
			info_button: "Get Information",
			lifecycle_texts: {
				0: "Upload additional files to create your proforma invoice and instantly share it with your customer.",
				1: "Enter your shipment details and add documents. Notify your customer and jointly manage all shipment processes through a single channel.",
				2: "Track your financial situation related to import and export in real-time.",
			},
			lifecycle_titles: {
				0: "Log In and Create Proforma Invoice",
				1: "Create Shipment",
				2: "Track Financial Activities",
			},
			productivity_title: "Increase productivity and reduce complexity and errors in 3 steps.",
			tracking_guide_title: "Easy Tracking Guide",
		},
		$logistics: {
			logistics_title: "Supported Logistics Types",
			solutions_title: "The right solution for every load",
			text: "With Antrego, your digital partner in all types of transport, we stand by your side for all your global movements.",
		},
		$pdfEditor: {
			description:
				"Our PDF editor is a powerful tool that makes creating and editing invoices more practical. Save time and present professional-looking documents to your business partners.",
			features: {
				design: "Invoice Design: Add your logo, adjust colors, and customize your invoice format.",
				edit: "Document Editing: Quickly edit PDFs, add or remove text.",
				shareSave: "Sharing and Saving: Download, share, or store your created documents directly on the platform.",
			},
			longTitle: "Fast and Personalized Invoices with the PDF Editor.",
			note: "This setup allows users to visually explore features and review advantages in writing.",
			title: "Creating Invoices is So Easy!",
		},
		$process: {
			digital_future: "Move away from traditional methods and step into the digital future",
			digital_shipping: "Digital Shipment Tracking with Antrego",
			digital_shipping_texts: {
				0: "Proforma invoices for incoming orders are created and tracked easily through a single channel.",
				1: "Proforma invoices are sent, notifications go to the customer instantly, and they are approved through Antrego.",
				2: "Document processes are managed through Antrego. They are not lost, no delays occur, and the potential for errors is minimized.",
				3: "Suppliers, customers, and customs brokers can instantly see shipment details and track them jointly.",
				4: "Financial reports are automatically generated monthly and annually.",
			},
			question_title: "How Will Antrego Change Your Life?",
			tracking_method: "Digitize your tracking methods and speed up your processes.",
			traditional_shipping: "Traditional Shipment Tracking",
			traditional_shipping_texts: {
				0: "Orders are collected via email, messages, or phone through different channels, causing internal tracking difficulties.",
				1: "The proforma invoice is created using tools like Excel, and the approval process is manually handled through email, WhatsApp, or phone.",
				2: "Manual document processes lead to errors and delays, causing time and financial losses.",
				3: "There is no common platform for suppliers, customers, and customs brokers to track shipment details.",
				4: "Financial data is checked individually after each shipment, making reporting difficult.",
			},
		},
		$question: {
			$text: {
				0: "<p>It is a shipment tracking program that helps companies and their customers with joint tracking, preventing losses due to delays in the tracking phase.</p>",
				1: "<p>- Share all your real-time updates with your customer. Improve your service quality.<br>\n      - View and share all documents related to your shipment in one system.<br>\n      - Track all your shipments from one place. Control your finances with dynamic reporting.</p>",
				2: "<p>- Sea<br>\n      - Air<br>\n      - Land<br>\n      - Train</p>",
				3: "<p>With its services, it is always at hand to make your shipment process easier.<br>\n      <strong>1. Easy Access</strong><br>\n      Accessible from all devices and locations via a web browser.<br>\n      <strong>2. Data Security</strong><br>\n      All data is stored using SHA-256 encryption technology.<br>\n      <strong>3. Joint Tracking</strong><br>\n      It provides tracking opportunities to all stakeholders in the process.</strong>",
				4: "<p>Yes, it is a paid application. By digitizing all your shipment processes, we want to make your work easier and continue making useful developments for you. To ensure the sustainability of these improvements and the server space you use, we charge a monthly fee.</p>",
				5: "<p>No. Your customers do not need to pay any fees to track the shipments you make.</p>",
			},
			$title: {
				0: "What is Antrego?",
				1: "What will Antrego contribute to my company during the shipment process?",
				2: "What types of logistics does Antrego support?",
				3: "Why should I use Antrego?",
				4: "Is Antrego a paid application?",
				5: "Do my customers need to pay for Antrego?",
			},
			save_percentage: "Save 16% Monthly",
			text: "Easily access all information with Antrego, and feel free to contact us for any other questions you may have.",
			title: "Quickly Learn Whatever You're Curious About",
		},
		cancel_anytime: "Cancel anytime",
		customize_package:
			"You can purchase additional storage and users and customize your package according to your needs",
		free_features_1: "10 Shipments",
		free_features_2: "100 MB Storage",
		free_features_3: "Unlimited Commercial Partners",
		free_features_4: "1 User",
		free_plan: "Free",
		free_price: "$0",
		free_regeneration: "",
		free_title: "Monthly",
		premium_annual_features_1: "20 Users",
		premium_annual_features_2: "100 Partners",
		premium_annual_features_3: "1000 Shipments",
		premium_annual_features_4: "1000 Invoices",
		premium_annual_price: "$83.25",
		premium_annual_regeneration: "Will renew annually at $999",
		premium_annual_title: "Annual",
		premium_monthly_features_1: "Unlimited Shipments",
		premium_monthly_features_2: "Unlimited Commercial Partners",
		premium_monthly_features_3: "2GB Storage",
		premium_monthly_features_4: "3 Users",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "Will renew monthly at $99",
		premium_monthly_title: "Monthly",
		premium_plan: "Premium Plan",
		premium_plus_annual_features_1: "Unlimited Shipments",
		premium_plus_annual_features_2: "Unlimited Commercial Partners",
		premium_plus_annual_features_3: "5GB Storage",
		premium_plus_annual_features_4: "5 Users",
		premium_plus_annual_price: "$83.25",
		premium_plus_annual_regeneration: "Will renew annually at $999",
		premium_plus_annual_title: "Annual",
		premium_plus_monthly_features_1: "Unlimited Shipments",
		premium_plus_monthly_features_2: "Unlimited Commercial Partners",
		premium_plus_monthly_features_3: "5GB Storage",
		premium_plus_monthly_features_4: "5 Users",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "Will renew monthly at $249",
		premium_plus_monthly_title: "Monthly",
		premium_plus_plan: "Premium Plus",
		pricing_text: "We can meet your needs in the best way without breaking your budget.",
		pricing_title: "Antrego guarantees to offer the best package and price for you.",
		save_percentage: "Save 16% Monthly",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Arabic",
		de: "German",
		en: "English",
		es: "Spanish",
		fr: "French",
		hi: "Hindi",
		ind: "Indonesian",
		it: "Italian",
		ja: "Japanese",
		ko: "Korean",
		pt: "Portuguese",
		ru: "Russian",
		tr: "Turkish",
		vi: "Vietnamese",
		zh: "Chinese",
	},
	$message: {
		cannot_delete_verified_partner: "You cannot delete a verified partner.",
		confirm: "Are you sure to {function}?",
		created: "{item} created.",
		deleted: "{item} deleted.",
		error_on_view_file: "An error occurred while viewing the file.",
		file_oversize: "File size must be maximum {size}.",
		invalid: "Invalid {item}",
		migrated: "{item} migrated. ({value})",
		process_has_been_started: "Your {item} has been started.",
		sent: "{item} sent.",
		sent_email: "E-mail sent. ({value})",
		sign_in_failed: "E-mail or password is incorrect",
		unsupported_file_type: "Unsupported file type",
		updated: "{item} updated.",
	},
	$pageTitles: {
		accept_invitation: "Accept Invitation",
		company_profile: "Company Profile",
		home: "Home",
		landing_page: "Seamless Digital Tracking, Shared Control",
		partners: "Partners",
		pdf_editor: "PDF Editor",
		product_groups: "Product Groups",
		products: "Products",
		proforma_invoices: "Proforma Invoices",
		shipment_details: "Shipment Details",
		shipments: "Shipments",
		sign_in: "Sign In",
		sign_up: "Sign Up",
		subscription: "Subscription",
		user_profile: "User Profile",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"Not registered partners, you created their profile on our platform, so you can view and edit their information.",
		createdHereTitle: "Created Here:",
		description: "You can track the status of your relation with your partners. Here are the differences:",
		verifiedPartnersDescription:
			"These partners have accepted your invitation and registered on the platform. Their information is view-only and cannot be edited.",
		verifiedPartnersTitle: "Verified Partners:",
	},
	$productGroup: {
		add_child: "Add Child",
		add_new_product_group: "Add New Product Group",
		add_to_root: "Add to Root",
		has_children_fail_message: "A product group with a child can not be deleted",
		initial_message: "Use this {0} button to create your first product group.",
		name: "Name",
		number_of_product: "Number of Product",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} approved.",
			create: "Created by {name}.",
			create_attachment: "{name} created an attachment.",
			delete_attachment: "{name} deleted an attachment.",
			demand_revise: "{name} demanded revise.",
			mark_as_processed: "{name} marked as processed.",
			reject: "{name} rejected.",
			revoke: "{name} revoked.",
			send: "{name} sent to receiver for assessment.",
			update: "Updated by {name}.",
			update_attachment: "{name} updated an attachment.",
		},
		$actions: {
			approve: "Approve",
			demand_revise: "Demand Revise",
			mark_as_approved: "Approved",
			mark_as_processed: "Processed",
			mark_as_rejected: "Rejected",
			mark_as_revise_demanded: "Revise Demanded",
			mark_as_revoked: "Revoked",
			mark_as_sent: "Sent",
			reject: "Reject",
			revoke: "Revoke",
			send: "Send",
		},
		$status: {
			approved: "Approved",
			pending_approval: "Pending Approval",
			pending_revise: "Pending Revise",
			processed: "Processed",
			rejected: "Rejected",
			revoked: "Revoked",
			template: "Template",
		},
		approve_confirm_message: "Are you sure to approve the proforma invoice?",
		approve_success_message: "The proforma invoice has been approved.",
		demand_revise_description_message: "Please give some detail about your revision expectation:",
		demand_revise_success_message: "The proforma invoice has been sent back to the sender for revise.",
		mark_as_processed_confirm_message: "Do you confirm to mark the proforma invoice as processed?",
		mark_as_processed_success_message: "The proforma invoice has been marked as processed.",
		no_attachment_text: "No attachment added yet.",
		reject_confirm_message: "Are you sure to reject the proforma invoice?",
		reject_success_message: "The proforma invoice has been rejected.",
		revoke_confirm_message: "Are you sure to revoke the proforma invoice?",
		revoke_success_message: "The proforma invoice has been revoked.",
		send_confirm_message: "Do you confirm to send the proforma invoice to the receiver?",
		send_success_message: "The proforma invoice has been sent.",
	},
	$serviceMessage: {
		already_subs_module: "There's already a subscription for this module.",
		bad_request: "Bad Request",
		code_already_used: "Code has already been used.",
		container_status_not_available: "Container status is not available.",
		existing_email_warning: "E-mail already exist.",
		existing_employee_error: "This user is already added to your company.",
		existing_employee_for_email_warning: "Invitation code has already been sent to this e-mail",
		existing_invitation_warning: "Invitation code has already been sent to this e-mail.",
		existing_token_already_have_time: "Existing token already have enough time to be used.",
		existing_user: "User already exist.",
		five_min_reset_password_mail: "You must wait 5 minutes at least after you request a password reset e-mail once.",
		forbidden: "Forbidden",
		have_no_corporation: "You have no corporation",
		have_no_custom_agency: "You have no customs agency",
		have_no_employee: "You have no employee",
		instance_can_no_delete_existing_booking_code: "The instance can no longer be deleted due to existing booking code.",
		instance_cannot_delete: "The instance can no longer be deleted.",
		instance_not_found: "Instance not found.",
		internal_server_error: "Internal Server Error",
		invalid_current_password: "Invalid current password.",
		invalid_identifier_or_password: "Invalid identifier or password",
		invalid_invitation_code: "The invite code is incorrect.",
		invalid_moving_request: "Invalid moving request",
		invalid_operation: "Invalid operation",
		invalid_refresh_token: "Invalid refresh token.",
		invalid_unit: "Invalid unit",
		invitation_accepting_is_due_to_the_admin: "Invitation accepting is due to the admin.",
		invitation_already_discarded: "The invitation has already been discarded.",
		invitation_already_used: "The invitation has already been used.",
		invitation_has_been_used: "The invitation has been used.",
		invitation_not_exist_email: "Invitation does not exist email address.",
		invitation_not_for_company: "This invitation is not for a company.",
		invitation_not_for_custom_agency: "This invitation is not for a custom agency.",
		invitation_not_found: "The invitation not found.",
		invitation_not_valid: "The invitation is not valid anymore.",
		link_has_been_expired: "Link has been expired.",
		link_not_be_used_anymore: "This link may not be used anymore",
		missing_required_fields: "Missing required fields.",
		no_employee_found: "No employee found",
		no_proper_partner_found: "No proper partner found.",
		not_acceptable: "Not Acceptable",
		not_allowed_action: "You are not allowed for this action.",
		not_allowed_use_api: "You are not allowed to use this api.",
		not_found: "Not Found",
		not_have_purchase_subscription:
			"Your subscription plan has reached the maximum limit for definitions. To add more, please consider upgrading your subscription plan.",
		not_permitted_use_api: "You are not permitted to use this api.",
		own_invitation_cannot_accept: "You can not accept your own invitation.",
		parent_group_not_found: "Parent Group not found",
		partnerId_required: "Partner ID is required.",
		partnership_already_exists: "The partner has already been added.",
		password_cant_involve_space_char: "Password can not involve space char.",
		password_min_chars_least: "Password must be 6 chars at least.",
		payment_required: "Please subscribe for this process.",
		paytr_integration_error:
			"There was a problem with the payment infrastructure. It will be fixed as soon as possible.",
		ports_are_required: "Ports are required.",
		product_group_tree_not_found: "Product Group Tree not found",
		proforma_invoice_action_not_available: "The status of proforma invoice is not available for this action.",
		proforma_invoice_template_update: "Proforma invoice must be template to be updated.",
		shipment_email_not_exist: "Please enter valid e-mail.",
		shipment_method_is_required: "Shipping method is required.",
		shipping_term_is_required: "Shipping term is required.",
		someone_else_uses_this_email: "Someone else uses this email address.",
		someone_else_uses_this_username: "Someone else uses this username.",
		something_went_wrong: "Something went wrong",
		type_can_be_abstract_company: '"type" property can just be "abstract_company"',
		unauthorized: "Unauthorized",
		undefined_result: "Undefined result",
		unexpected_error_occurred: "Unexpected error occurred",
		unit_already_added: "Unit has already been added.",
		user_already_employee: "The user is already is an employee.",
		user_already_exists: "User already exists in a company.",
		user_does_not_exist: "User does not exist.",
		user_not_found: "User not found.",
		username_min_chars_error: '"username" must be 6 chars at least.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} added a vehicle.",
				delete_container: "{name} deleted a vehicle.",
				update_container: "{name} updated a vehicle.",
			},
			create: "Created by {name}.",
			create_attachment: "{name} added an attachment.",
			create_container: "{name} added a container.",
			delete_attachment: "{name} deleted an attachment.",
			delete_container: "{name} deleted a container.",
			update: "Updated by {name}.",
			update_attachment: "{name} updated an attachment.",
			update_container: "{name} updated a container.",
		},
		$containerStatus: {
			arrived: "Arrived",
			off_load: "Off Load",
			on_load: "On Load",
			on_way: "On The Way",
			reserved: "Reserved",
		},
		$method: {
			aerial: "Aerial",
			land: "Land",
			railway: "Railway",
			sea: "Sea",
		},
		$vehicle: {
			license_number: "License Plate",
		},
		archiving_success_message: "Shipment has been archived.",
		leave_message: "Leave your message or comment",
		no_booking_info: "No booking info has been set yet. Use {0} button to add.",
		no_transportation_info: "No detail info has been set yet. Use {0} button to set.",
		un_archiving_success_message: "Shipment has been un-archived.",
	},
	$shortening: {
		number: "No",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "Your subscription could not be renewed. Please check your payment method.",
			fail: "Unexpected error occurred. Please try again later. If the problem persists, please report it by sending an e-mail to info@antrego.net.",
			ok: "The purchasing process has been initiated. The result of the process will be notified by e-mail. Your subscription will be started when the payment is completed successfully.",
			payment_process_is_ongoing:
				"Your payment process is ongoing. The result of the process will be notified by e-mail. Your subscription will be started when the payment is completed successfully.",
			success: "Your subscription has been successfully created. You can start using Antrego easily.",
			try_to_get_paid_again: "Your subscription has expired. Click here to renew.",
			unsubscribed: "Unsubscribed",
			you_dont_have_any_subscription:
				"You do not have any subscriptions. Please choose a package on the subscription page and start using it easily.",
			you_dont_have_any_subscription_text:
				"Choose the membership plan best for you and take full advantage of the opportunities.",
			you_dont_have_any_subscription_title: "Do More with our Plans",
		},
	},
	$unit: {
		acres: "Acres",
		boxes: "Boxes",
		cubic_meter: "Cubic Meter",
		dozen: "Dozen",
		feet: "Feet",
		fluid_ounces: "Fluid Ounces",
		gallons: "Gallons",
		gram: "Gram",
		hectare: "Hectare",
		inches: "Inches",
		kilogram: "Kilogram",
		kilometer: "Kilometer",
		liter: "Liter",
		meter: "Meter",
		miles: "Miles",
		milligram: "Milligram",
		milliliter: "Milliliter",
		millimeter: "Millimeter",
		ounces: "Ounces",
		pallets: "Pallets",
		pieces: "Pieces",
		pounds: "Pounds",
		quarts: "Quarts",
		square_feet: "Square Feet",
		square_inches: "Square Inches",
		square_kilometer: "Square Kilometer",
		square_meter: "Square Meter",
		square_miles: "Square Miles",
		square_yards: "Square Yards",
		ton: "Tons",
		yards: "Yards",
	},
	$validation: {
		email: "Please enter valid e-mail",
		invalid: "Invalid {item}",
		max_length: "{item} must be less than {length} characters",
		min_length: "{item} must be more than {length} characters",
		must_length: "{item} must be {length} characters",
		only_number: "{item} should be a number",
		password_repeat_warning: "Password repeat is not same as new password.",
		required: "{item} is required",
		required_for_default_language: "{item} is required for the company's default language.",
		you_must_approve_distance_sales_agreement: "You must approve the Distance Sales Contract",
	},
	about: "About",
	accept_invitation: "Accept Invitation",
	accept_invitation_success_message: "You've used the invitation successfully.",
	accessibility: "accessibility",
	actions: "Actions",
	active: "Active",
	activities: "Activities",
	add: "Add",
	add_item: "Add {item}",
	add_new_employee: "Add New Employee",
	add_new_product: "Add New Product",
	add_template: "Add Template",
	address: "Address",
	again: "Again",
	alert: "Alert",
	already_have_employee_message: "You are already an employee of a company.",
	amount: "Amount",
	approve: "Approve",
	archive: "Archive",
	attachment: "Attachment",
	attachments: "Attachments",
	auto_attached_pdf: "Automatically Attached PDF",
	bank: "Bank",
	benefits: "Benefits",
	billed_to: "Billed to",
	booking: "Booking",
	booking_code: "Booking Code",
	booking_details: "Booking Details",
	business_partner: "Business Partner",
	business_partners: "Business Partners",
	cancel: "Cancel",
	change_password: "Change Password",
	checking_invitation_code_message: "Checking invitation code",
	choose_image: "Choose image",
	choose_your_plan: "Choose Your Plan",
	clear: "Clear",
	click_to_play_the_video: "Click to play the video",
	close: "Close",
	code: "Code",
	commercial: "Commercial",
	commercial_partner: "Commercial Partner",
	commercial_partners: "Commercial Partners",
	companies: "Companies",
	company: "Company",
	company_address: "Company Address",
	company_name: "Company Name",
	company_profile: "Company Profile",
	configuration: "Configuration",
	confirm: "Confirm",
	confirm_distance_selling_agreement: "Confirm the Distance Sales Contract.",
	consult_us: "Consult Us",
	contact: "Contact",
	contact_info: "Contact Information",
	contact_text: "Rest assured, we will reach out to you as soon as possible.",
	contact_title: "Consult Us, Simplify Your Processes",
	contact_to_us: "Contact Us",
	container: "Container",
	container_no: "Container No",
	containers: "Containers",
	content: "Content",
	contract: "Contract",
	copy_link: "Copy Link",
	corporation: "Company",
	countries: "Countries",
	country: "Country",
	create: "Create",
	create_a_new_account: "Create a New Account",
	create_company: "Create Company",
	create_document: "Create Document",
	created_at: "Created At",
	created_here: "Created Here",
	credit_card_info: "Credit Card Info",
	credit_card_no: "Credit Card No.",
	currencies: "Currencies",
	currency: "Currency",
	current_password: "Current Password",
	current_plan: "Current Plan",
	customer_company_address: "Customer Company Address",
	customer_company_name: "Customer Company Name",
	customs: "Customs",
	customs_agencies: "Customs Agencies",
	customs_agency: "Customs Agency",
	cvv: "CVV",
	daily: "Daily",
	dashboard: "Dashboard",
	date: "Date",
	day: "Day",
	default: "Default",
	default_language: "Default Language",
	delete: "Delete",
	delete_account: "Delete Account",
	delete_confirm_message: "Do you confirm the delete?",
	delete_document: "Delete Document",
	delete_template: "Delete Template",
	description: "Description",
	detail: "Detail",
	discard: "Discard",
	distance_sales_agreement: "The Distance Sales Contract",
	document: "Document",
	document_actions: "Document Actions",
	document_added: "Document Added",
	document_manager: "Document Manager",
	document_name: "Document Name",
	document_number: "Document No",
	document_overwrite: "Save edits as new document",
	document_save_info: "This document will only be saved inside this proforma invoice.",
	document_updated: "Document Updated",
	download: "Download",
	download_pdf: "Download PDF",
	edit: "Edit",
	edit_document: "Edit Document",
	edit_item: "Edit {item}",
	edit_product: "Edit Product",
	edit_profile: "Edit Profile",
	edit_template: "Edit template",
	edit_your_profile_picture: "Edit your profile picture",
	email: "E-mail",
	employee: "Employee",
	employee_type: "Employee Type",
	employees: "Employees",
	empty_data_warning: "There is no data in this area yet. You can start exploring the platform with sample data.",
	estimated_arrival_day: "Estimated Arrival Day",
	expense: "Expense",
	expenses: "Expenses",
	expires_at: "Expires At",
	exported_containers: "Exported Containers",
	fail: "Fail",
	failed: "Failed",
	faq: "F.A.Q.",
	feedback: "Feedback",
	file: "File",
	file_name: "File Name",
	file_preview: "File Preview",
	file_view: "View File",
	filter: "Filter",
	first_name: "First Name",
	first_name_last_name: "First Name Last Name",
	forget_password: "Forget Password",
	forget_password_message: "Did you forget your password?",
	free: "Free",
	from: "From",
	general: "Default",
	get_started_now: "Get Started Now",
	give_us_feedback: "Give Us Feedback",
	go_to_panel: "Go To Panel",
	hide: "Hide",
	home_page: "Home Page",
	i_have_invitation_code: "I have an invitation code",
	imported_containers: "Imported Containers",
	in_progress: "In Progress",
	incoming: "Incoming",
	incoming_shipments: "Incoming Shipments",
	initial: "Initial",
	inputs: "Inputs",
	invitation: "Invitation",
	invitation_code: "Invitation Code",
	invitation_mail_sent: "Invitation email sent",
	invitations: "Invitations",
	invoice: "Invoice",
	invoice_no: "Invoice Number",
	join: "Join",
	join_company: "Join Company",
	language: "Language",
	language_options: "Language Options",
	last_name: "Last Name",
	last_one_year: "Last 1 Year",
	latitude: "Latitude",
	left_a_comment: "Left a Comment",
	link_copied: "Link copied",
	link_copy_failed: "Link copy failed",
	loading_country: "Loading Country",
	loading_point: "Loading Point",
	loading_port: "Loading Port",
	log_in: "Log In",
	logo: "Logo",
	longitude: "Longitude",
	mail_sent: "E-mail sent",
	manage_profile: "Manage Your Antrego Profile",
	message: "Message",
	message_company_create: "Company created.",
	message_company_join: "Please enter the invitation code.",
	message_company_joined: "You joined to company",
	message_employee_delete: "Employee deleted.",
	message_employee_save: "Employee saved.",
	message_join_company: "Enter your code for join existing company",
	method: "Method",
	migrate: "Migrate",
	migrate_partner: "Migrate Company",
	mobile_number: "Mobile Number",
	month: "Month",
	month_lowercase: "month",
	monthly: "Monthly",
	my_profile: "My Profile",
	my_role: "My Role",
	name: "Name",
	new: "New",
	new_company_package: "Free",
	new_password: "New Password",
	new_shipment: "New Shipment",
	next: "Next",
	no: "No",
	no_content: "No Content",
	no_entity: "No {entityName} yet",
	no_entity_message: "Created {entityName} and its details will be listed here",
	no_text: "No Text",
	not_found_page_message: "Sorry, I could not find this page :(",
	note: "Note",
	notes: "Notes",
	number_of_rows: "Number of Rows",
	ok: "OK",
	operation_fail: "Operation Fail",
	outgoing: "Outgoing",
	outgoing_shipments: "Outgoing Shipments",
	packages: "Packages",
	packing_list_title: "Packing List",
	parent_group: "Parent Group",
	partner: "Partner",
	partners: "Partners",
	partnership_status: "Partnership Status",
	passive: "Passive",
	password: "Password",
	pay: "Pay",
	payback: "Payback",
	payback_confirm_message: "Do you confirm the payback?",
	payback_warning_message: "If you continue, your subscription will be terminated.",
	payment: "Payment",
	payment_amount: "Payment Amount",
	payment_awaited: "Payment Awaited",
	payment_history: "Payment History",
	payment_info: "Payment Info",
	payment_information: "Payment Information",
	payment_success: "Payment Success",
	payment_summary: "Payment Summary",
	payment_will_be_made_3d_secure: "*Your payment will be made with 3d Secure.",
	pdf_attached_message: "PDF Successfully Attached",
	pdf_editor: "PDF Editor",
	pdf_name: "PDF name",
	pending_invitations: "Pending Invitations",
	per_month: "per month",
	phone_number: "Phone Number",
	plate_numbers: "Plate Numbers",
	premium: "Premium",
	premium_plus: "Premium Plus",
	preview: "Preview",
	previous: "Previous",
	pricing: "Pricing",
	process: "Process",
	product: "Product",
	product_group: "Product Group",
	product_group_name: "Product Group Name",
	product_groups: "Product Groups",
	product_name: "Product Name",
	products: "Products",
	profile: "Profile",
	proforma_invoice: "Proforma Invoice",
	proforma_invoices: "Proforma Invoices",
	proforma_number: "Proforma No",
	proforma_title: "Proforma Invoice",
	purchase: "Purchase",
	quantity: "Quantity",
	receiver: "Receiver",
	receiver_company: "Receiver Company",
	receiver_customs_agency: "Receiver Customs Agency",
	redirection_to_home_link: "Home",
	redirection_to_home_message: "You can also go to ",
	redirection_to_sign_in_message: "Do you have an account?",
	redirection_to_sign_up_message: "Don't you have an account yet?",
	reload: "Reload",
	renewal: "Renewal",
	repaid: "Repaid",
	reset_password: "Reset Password",
	rows_per_page: "Rows per page",
	sales: "Sales",
	save: "Save",
	save_as_document: "Save as Document",
	save_as_new_document: "Save as New Document",
	save_as_new_template: "Save as New Template",
	save_as_template: "Save as Template",
	save_changes: "Save Changes",
	save_pdf_to_attachments: "Save PDF to Attachments",
	saved_card_info: "Saved Card Info",
	search: "Search",
	select: "Select",
	select_document: "Select the Document",
	select_document_to_overwrite: "Select Document to Overwrite",
	select_file: "Select File",
	select_file_place_holder: "Please select an image",
	select_language: "Select language",
	select_template: "Select the Template",
	select_template_to_overwrite: "Select Template to Overwrite",
	selected: "Selected",
	selected_partner: "Selected Partner",
	send: "Send",
	send_again: "Send Again",
	send_email: "Send E-mail",
	send_feedback: "Send Feedback",
	send_invitation: "Send Invitation",
	sender: "Sender",
	sender_company: "Sender Company",
	sender_customs_agency: "Sender Customs Agency",
	settings: "Settings",
	shipment: "Shipment",
	shipment_method: "Shipment Method",
	shipment_type: "Shipment Type",
	shipments: "Shipments",
	shipments_filter: "Shipments Filter",
	shipping_term: "Shipping Term",
	shipping_terms: "Shipping Terms",
	show: "Show",
	sign_in: "Sign In",
	sign_out: "Sign Out",
	sign_out_confirm_message: "Do you confirm signing out?",
	sign_out_success_message: "Good Bye.",
	sign_up: "Sign Up",
	social_media: "Social Media",
	social_media_account: "Social Media Account",
	start_time: "Start Time",
	status: "Status",
	subscribe: "Subscribe",
	subscription: "Subscription",
	subscription_info_message: "You can benefit from all services unlimitedly during the subscription period.",
	subscription_warning_message:
		"**Your card information will be securely stored and your subscription will be renewed regularly unless you cancel it.",
	success: "Success",
	super: "Super",
	supported_files_img: "Supported file types: JPG, PNG files under 20 mb",
	switch_language: "Switch Language",
	switch_theme: "Switch Theme",
	table_inputs: "Table Inputs",
	target: "Target",
	target_partner: "Target Partner",
	tax_number: "Tax Number",
	template: "Template",
	template_actions: "Template Actions",
	template_added: "Template Added",
	template_name: "Template Name",
	template_overwrite: "Save edits as new template",
	template_save_info: "Saved templates will later be available for use in proforma invoices and shipments.",
	template_updated: "Template Updated",
	terms_of_sale_and_other_comments: "Terms of Sale - Comments",
	theme: "Theme",
	this_month: "This Month",
	this_year: "This Year",
	title: "Title",
	to: "To",
	toDelete: "Delete",
	total: "Total",
	total_amount: "Total Amount",
	transaction_no: "Transaction No",
	transactions: "Transactions",
	try_for_free: "Try for Free",
	type: "Type",
	types: "Types",
	un_archive: "Un-archive",
	unit: "Unit",
	units: "Units",
	unloading_country: "Unloading Country",
	unloading_point: "Unloading Point",
	unloading_port: "Unloading Port",
	unsubscribe: "Unsubscribe",
	unsubscribe_confirm_message:
		"If you unsubscribe, you will not be able to use the system features effectively. Are you sure you want to unsubscribe?",
	upload_new_image: "Upload New Image",
	use_code: "Use Code",
	use_invitation_code: "Use Invitation Code",
	use_invitation_code_description: "Type your invitation code that sent your mailbox.",
	use_invitation_code_success_message: "Invitation code has been used successfully.",
	user_profile: "My Profile",
	vehicle: "Vehicle",
	vehicles: "Vehicles",
	verified_partner: "Verified Partner",
	verify_password: "Verify Password",
	visibility: "Visibility",
	warehouse: "Warehouse",
	warehouses: "Warehouses",
	website: "Website",
	week: "Week",
	welcome: "Welcome",
	what_we_are_doing: "What We Are Doing",
	year: "Year",
	year_lowercase: "year",
	yearly: "Yearly",
	yes: "Yes",
	you_dont_have_any_active_subscription: "You don't have any active subscription",
};
