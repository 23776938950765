export default {
	$accessibility: {
		archived: "Archiviato",
		present: "Presente",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Nascosto al destinatario",
			hidden_from_sender: "Nascosto al mittente",
			private: "Privato",
			shared_with_custom: "Condiviso con personalizzati",
			shared_with_everyone: "Condiviso con tutti",
			shared_with_receiver: "Condiviso con il destinatario",
			shared_with_sender: "Condiviso con il mittente",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB di archiviazione",
		limitMBStorage: "{limit}MB di archiviazione",
		limitUser: "{limit} Utente",
		limitUsers: "{limit} Utenti",
		renewAtPricePerUnit: "Si rinnoverà a {currency}{price} per {unit}",
		unlimitedModule: "{module} illimitato",
		upToLimitModule: "Fino a {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, TURCHIA",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sit!2str!4v1701805110461!5m2!1sit!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan:
			"Accelera la tua attività con Antrego. Gestisci i tuoi processi di spedizione digitalmente, in modo sicuro e semplice.",
	},
	$country: {
		afghanistan: "Afghanistan",
		aland: "Åland",
		albania: "Albania",
		algeria: "Algeria",
		american_samoa: "Samoa Americane",
		andorra: "Andorra",
		angola: "Angola",
		anguilla: "Anguilla",
		antarctica: "Antartide",
		antigua_and_barbuda: "Antigua e Barbuda",
		argentina: "Argentina",
		armenia: "Armenia",
		aruba: "Aruba",
		australia: "Australia",
		austria: "Austria",
		azerbaijan: "Azerbaijan",
		bahamas: "Bahamas",
		bahrain: "Bahrein",
		bangladesh: "Bangladesh",
		barbados: "Barbados",
		belarus: "Bielorussia",
		belgium: "Belgio",
		belize: "Belize",
		benin: "Benin",
		bermuda: "Bermuda",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius e Saba",
		bosnia_and_herzegovina: "Bosnia ed Erzegovina",
		botswana: "Botswana",
		bouvet_island: "Isola Bouvet",
		brazil: "Brasile",
		british_indian_ocean_territory: "Territorio Britannico dell'Oceano Indiano",
		british_virgin_islands: "Isole Vergini Britanniche",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		burkina_faso: "Burkina Faso",
		burundi: "Burundi",
		cabo_verde: "Capo Verde",
		cambodia: "Cambogia",
		cameroon: "Camerun",
		canada: "Canada",
		cayman_islands: "Isole Cayman",
		central_african_republic: "Repubblica Centrafricana",
		chad: "Ciad",
		chile: "Cile",
		china: "Cina",
		christmas_island: "Isola di Natale",
		cocos_islands: "Isole Cocos [Keeling]",
		colombia: "Colombia",
		comoros: "Comore",
		congo_republic: "Repubblica del Congo",
		cook_islands: "Isole Cook",
		costa_rica: "Costa Rica",
		croatia: "Croazia",
		cuba: "Cuba",
		curacao: "Curaçao",
		cyprus: "Cipro",
		czechia: "Cechia",
		denmark: "Danimarca",
		djibouti: "Gibuti",
		dominica: "Dominica",
		dominican_republic: "Repubblica Dominicana",
		dr_congo: "Repubblica Democratica del Congo",
		ecuador: "Ecuador",
		egypt: "Egitto",
		el_salvador: "El Salvador",
		equatorial_guinea: "Guinea Equatoriale",
		eritrea: "Eritrea",
		estonia: "Estonia",
		eswatini: "Eswatini",
		ethiopia: "Etiopia",
		falkland_islands: "Isole Falkland",
		faroe_islands: "Isole Faroe",
		fiji: "Figi",
		finland: "Finlandia",
		france: "Francia",
		french_guiana: "Guyana Francese",
		french_polynesia: "Polinesia Francese",
		french_southern_territories: "Territori Francesi del Sud",
		gabon: "Gabon",
		gambia: "Gambia",
		georgia: "Georgia",
		germany: "Germania",
		ghana: "Ghana",
		gibraltar: "Gibilterra",
		greece: "Grecia",
		greenland: "Groenlandia",
		grenada: "Grenada",
		guadeloupe: "Guadalupa",
		guam: "Guam",
		guatemala: "Guatemala",
		guernsey: "Guernsey",
		guinea: "Guinea",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		haiti: "Haiti",
		heard_island_and_mcdonald_islands: "Isole Heard e McDonald",
		honduras: "Honduras",
		hong_kong: "Hong Kong",
		hungary: "Ungheria",
		iceland: "Islanda",
		india: "India",
		indonesia: "Indonesia",
		iran: "Iran",
		iraq: "Iraq",
		ireland: "Irlanda",
		isle_of_man: "Isola di Man",
		israel: "Israele",
		italy: "Italia",
		ivory_coast: "Costa d'Avorio",
		jamaica: "Giamaica",
		japan: "Giappone",
		jersey: "Jersey",
		jordan: "Giordania",
		kazakhstan: "Kazakistan",
		kenya: "Kenya",
		kiribati: "Kiribati",
		kosovo: "Kosovo",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonia",
		lebanon: "Libano",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libia",
		liechtenstein: "Liechtenstein",
		lithuania: "Lituania",
		luxembourg: "Lussemburgo",
		macao: "Macao",
		madagascar: "Madagascar",
		malawi: "Malawi",
		malaysia: "Malesia",
		maldives: "Maldive",
		mali: "Mali",
		malta: "Malta",
		marshall_islands: "Isole Marshall",
		martinique: "Martinica",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mayotte: "Mayotte",
		mexico: "Messico",
		micronesia: "Micronesia",
		moldova: "Moldavia",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		montserrat: "Montserrat",
		morocco: "Marocco",
		mozambique: "Mozambico",
		myanmar: "Myanmar",
		namibia: "Namibia",
		nauru: "Nauru",
		nepal: "Nepal",
		netherlands: "Paesi Bassi",
		new_caledonia: "Nuova Caledonia",
		new_zealand: "Nuova Zelanda",
		nicaragua: "Nicaragua",
		niger: "Niger",
		nigeria: "Nigeria",
		niue: "Niue",
		norfolk_island: "Isola Norfolk",
		north_korea: "Corea del Nord",
		north_macedonia: "Macedonia del Nord",
		northern_mariana_islands: "Isole Marianne Settentrionali",
		norway: "Norvegia",
		oman: "Oman",
		pakistan: "Pakistan",
		palau: "Palau",
		palestine: "Palestina",
		panama: "Panama",
		papua_new_guinea: "Papua Nuova Guinea",
		paraguay: "Paraguay",
		peru: "Perù",
		philippines: "Filippine",
		pitcairn_islands: "Isole Pitcairn",
		poland: "Polonia",
		portugal: "Portogallo",
		puerto_rico: "Porto Rico",
		qatar: "Qatar",
		reunion: "Riunione",
		romania: "Romania",
		russia: "Russia",
		rwanda: "Ruanda",
		saint_barthelemy: "Saint Barthélemy",
		saint_helena: "Sant'Elena",
		saint_lucia: "Santa Lucia",
		saint_martin: "Saint Martin",
		saint_pierre_and_miquelon: "Saint Pierre e Miquelon",
		samoa: "Samoa",
		san_marino: "San Marino",
		sao_tome_and_principe: "São Tomé e Príncipe",
		saudi_arabia: "Arabia Saudita",
		senegal: "Senegal",
		serbia: "Serbia",
		seychelles: "Seychelles",
		sierra_leone: "Sierra Leone",
		singapore: "Singapore",
		sint_maarten: "Sint Maarten",
		slovakia: "Slovacchia",
		slovenia: "Slovenia",
		solomon_islands: "Isole Salomone",
		somalia: "Somalia",
		south_africa: "Sudafrica",
		south_georgia_and_south_sandwich_islands: "Georgia del Sud e Isole Sandwich Australi",
		south_korea: "Corea del Sud",
		south_sudan: "Sudan del Sud",
		spain: "Spagna",
		sri_lanka: "Sri Lanka",
		st_kitts_and_nevis: "Saint Kitts e Nevis",
		st_vincent_and_grenadines: "Saint Vincent e Grenadine",
		sudan: "Sudan",
		suriname: "Suriname",
		svalbard_and_jan_mayen: "Svalbard e Jan Mayen",
		sweden: "Svezia",
		switzerland: "Svizzera",
		syria: "Siria",
		taiwan: "Taiwan",
		tajikistan: "Tagikistan",
		tanzania: "Tanzania",
		thailand: "Thailandia",
		timor_leste: "Timor Est",
		togo: "Togo",
		tokelau: "Tokelau",
		tonga: "Tonga",
		trinidad_and_tobago: "Trinidad e Tobago",
		tunisia: "Tunisia",
		turkiye: "Turchia",
		turkmenistan: "Turkmenistan",
		turks_and_caicos_islands: "Isole Turks e Caicos",
		tuvalu: "Tuvalu",
		uganda: "Uganda",
		ukraine: "Ucraina",
		united_arab_emirates: "Emirati Arabi Uniti",
		united_kingdom: "Regno Unito",
		united_states: "Stati Uniti",
		uruguay: "Uruguay",
		us_minor_outlying_islands: "Isole Minori Esterne degli Stati Uniti",
		us_virgin_islands: "Isole Vergini Americane",
		uzbekistan: "Uzbekistan",
		vanuatu: "Vanuatu",
		vatican_city: "Città del Vaticano",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		wallis_and_futuna: "Wallis e Futuna",
		western_sahara: "Sahara Occidentale",
		yemen: "Yemen",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		euro: "Euro",
		turkish_lira: "Lira Turca",
		us_dollar: "Dollaro USA",
	},
	$documentManager: {
		message_1:
			'Per modificare il modello, fai clic sul pulsante "Modifica modello" per accedere alla schermata di modifica.',
		message_2: 'Puoi modificare il numero di righe nell\'elenco aumentando o diminuendo il "Numero di righe".',
		message_3:
			'Le modifiche apportate ai campi di contenuto verranno visualizzate immediatamente nella sezione "Anteprima".',
		message_4: 'Per generare un PDF, fai clic sul pulsante "Azioni documento" per salvarlo come PDF.',
		message_5: 'Per accedere ai documenti in seguito, puoi salvarli tramite il pulsante "Azioni documento".',
		message_6: "I dati inseriti in ogni cella della tabella verranno riflessi esattamente nell'anteprima.",
		message_7: "La tabella dati può essere modificata come un foglio Excel o un foglio elettronico.",
	},
	$employeeType: {
		admin: "Amministratore",
		default: "Predefinito",
		exporter: "Esportatore",
		importer: "Importatore",
	},
	$format: {
		date: "DD/MM/YYYY",
		date_time: "DD/MM/YYYY HH:mm:ss",
		date_time_without_second: "DD/MM/YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Crea la tua azienda qui.",
			create_company_title: "Vuoi aggiungere la tua azienda?",
			description: "Hai effettuato l'accesso con successo. Ora devi unirti a un'azienda o crearne una nuova.",
			join_company_subtitle: "Inserisci il tuo codice e unisciti all'azienda qui.",
			join_company_title: "Vuoi unirti a un'azienda esistente?",
			title: "Benvenuto {name}",
		},
		$unauthorized: {
			description: "Mantieni sotto controllo i tuoi processi di import-export con Antrego",
			forget_password_subtitle: "Inserisci la tua email per reimpostare la password.",
			sign_in_subtitle: "Accedi qui",
			sign_in_title: "Hai già un account?",
			sign_up_subtitle: "Registrati qui",
			sign_up_title: "Vuoi creare un account?",
			title: "Benvenuto in Antrego",
		},
	},
	$invitationType: {
		company_partner: "Invito per cooperazione commerciale",
		customs_partner: "Invito per partner doganale",
		employee: "Invito per dipendente",
	},
	$landing: {
		$benefits: {
			benefits_title: "Vantaggi",
			business_process: "Accelera i tuoi processi aziendali.",
			control_title: "Prendi il controllo con Antrego.",
			highlight_word: "controllo",
			risk_of_error: "Riduce il rischio di errori.",
			text: "Antrego è un programma di tracciamento delle spedizioni che aiuta le compagnie di spedizioni, i loro clienti e i broker doganali a monitorare i processi di spedizione tramite una piattaforma condivisa.",
			time_and_cost_savings: "Risparmia tempo e denaro.",
		},
		$content: {
			$text: {
				0: "Monitora tutte le tue spedizioni in un unico posto e controlla le tue finanze con report dinamici.",
				1: "Condividi i tuoi aggiornamenti in tempo reale con i tuoi clienti per migliorare la qualità del servizio.",
				2: "Visualizza e condividi tutti i documenti relativi alla tua spedizione in un unico sistema.",
				3: "Controlla facilmente il tuo processo accedendo ad Antrego da tutti i dispositivi e luoghi.",
				4: "Memorizza tutti i tuoi dati utilizzando la tecnologia di crittografia SHA-256. Resta sicuro riguardo alla sicurezza.",
				5: "Partecipa al monitoraggio congiunto con tutte le parti coinvolte nel processo aziendale. Gestisci il processo in modo più efficiente.",
			},
			$title: {
				0: "Gestione delle spedizioni",
				1: "Relazioni con i clienti",
				2: "Gestione documenti",
				3: "Facile accesso",
				4: "Sicurezza dei dati",
				5: "Monitoraggio congiunto",
			},
		},
		$home: {
			text: "Sceglici per un tracciamento digitale senza interruzioni e controllo condiviso.",
			title: "Gestisci facilmente tutti i tuoi processi di spedizione digitalmente",
		},
		$lifecycle: {
			headline: "Processo",
			info_button: "Ottieni informazioni",
			lifecycle_texts: {
				0: "Carica file aggiuntivi per creare la tua fattura proforma e condividila immediatamente con il tuo cliente.",
				1: "Inserisci i dettagli della spedizione e aggiungi documenti. Notifica al tuo cliente e gestisci congiuntamente tutti i processi di spedizione attraverso un unico canale.",
				2: "Monitora la tua situazione finanziaria relativa all'importazione ed esportazione in tempo reale.",
			},
			lifecycle_titles: {
				0: "Accedi e crea la fattura proforma",
				1: "Crea spedizione",
				2: "Monitora le attività finanziarie",
			},
			productivity_title: "Aumenta la produttività e riduci la complessità e gli errori in 3 passaggi.",
			tracking_guide_title: "Guida al tracciamento facile",
		},
		$logistics: {
			logistics_title: "Tipi di logistica supportati",
			solutions_title: "La soluzione giusta per ogni carico",
			text: "Con Antrego, il tuo partner digitale in tutti i tipi di trasporto, siamo al tuo fianco per tutti i tuoi movimenti globali.",
		},
		$pdfEditor: {
			description:
				"Il nostro editor PDF è uno strumento potente che rende la creazione e modifica delle fatture più pratica. Risparmia tempo e presenta documenti dall'aspetto professionale ai tuoi partner commerciali.",
			features: {
				design:
					"Progettazione delle fatture: Aggiungi il tuo logo, regola i colori e personalizza il formato della fattura.",
				edit: "Modifica documenti: Modifica rapidamente i PDF, aggiungi o rimuovi testo.",
				shareSave:
					"Condivisione e salvataggio: Scarica, condividi o archivia i documenti creati direttamente sulla piattaforma.",
			},
			longTitle: "Fatture rapide e personalizzate con l'editor PDF.",
			note: "Questa configurazione consente agli utenti di esplorare visivamente le funzionalità e di esaminare i vantaggi per iscritto.",
			title: "Creare fatture è così facile!",
		},
		$process: {
			digital_future: "Allontanati dai metodi tradizionali e fai un passo nel futuro digitale",
			digital_shipping: "Monitoraggio digitale delle spedizioni con Antrego",
			digital_shipping_texts: {
				0: "Le fatture proforma per gli ordini in arrivo vengono create e monitorate facilmente attraverso un unico canale.",
				1: "Le fatture proforma vengono inviate, le notifiche vengono inviate immediatamente al cliente e vengono approvate tramite Antrego.",
				2: "I processi documentali sono gestiti tramite Antrego. Non vengono persi, non ci sono ritardi e il potenziale di errore è minimizzato.",
				3: "Fornitori, clienti e broker doganali possono visualizzare immediatamente i dettagli della spedizione e monitorarli congiuntamente.",
				4: "I report finanziari vengono generati automaticamente su base mensile e annuale.",
			},
			question_title: "Come Antrego cambierà la tua vita?",
			tracking_method: "Digitalizza i tuoi metodi di tracciamento e accelera i tuoi processi.",
			traditional_shipping: "Monitoraggio delle spedizioni tradizionale",
			traditional_shipping_texts: {
				0: "Gli ordini vengono raccolti tramite e-mail, messaggi o telefono attraverso diversi canali, causando difficoltà nel monitoraggio interno.",
				1: "La fattura proforma viene creata utilizzando strumenti come Excel e il processo di approvazione viene gestito manualmente tramite e-mail, WhatsApp o telefono.",
				2: "I processi manuali dei documenti portano a errori e ritardi, causando perdite di tempo e denaro.",
				3: "Non esiste una piattaforma comune per fornitori, clienti e broker doganali per monitorare i dettagli della spedizione.",
				4: "I dati finanziari vengono verificati individualmente dopo ogni spedizione, rendendo difficoltosa la creazione di report.",
			},
		},
		$question: {
			$text: {
				0: "<p>È un programma di tracciamento delle spedizioni che aiuta le aziende e i loro clienti con il monitoraggio condiviso, prevenendo perdite dovute a ritardi nella fase di tracciamento.</p>",
				1: "<p>- Condividi tutti gli aggiornamenti in tempo reale con il tuo cliente. Migliora la qualità del tuo servizio.<br>\n      - Visualizza e condividi tutti i documenti relativi alla tua spedizione in un unico sistema.<br>\n      - Monitora tutte le tue spedizioni da un unico posto. Controlla le tue finanze con report dinamici.</p>",
				2: "<p>- Mare<br>\n      - Aria<br>\n      - Terra<br>\n      - Treno</p>",
				3: "<p>Con i suoi servizi, è sempre a disposizione per rendere più facile il tuo processo di spedizione.<br>\n      <strong>1. Facile accesso</strong><br>\n      Accessibile da tutti i dispositivi e luoghi tramite un browser web.<br>\n      <strong>2. Sicurezza dei dati</strong><br>\n      Tutti i dati sono memorizzati utilizzando la tecnologia di crittografia SHA-256.<br>\n      <strong>3. Monitoraggio congiunto</strong><br>\n      Offre opportunità di monitoraggio a tutte le parti coinvolte nel processo.</strong>",
				4: "<p>Sì, è un'applicazione a pagamento. Digitalizzando tutti i tuoi processi di spedizione, vogliamo rendere il tuo lavoro più facile e continuare a sviluppare soluzioni utili per te. Per garantire la sostenibilità di questi miglioramenti e dello spazio server che utilizzi, addebitiamo una tariffa mensile.</p>",
				5: "<p>No. I tuoi clienti non devono pagare alcuna tariffa per monitorare le spedizioni che effettui.</p>",
			},
			$title: {
				0: "Cos'è Antrego?",
				1: "Cosa apporterà Antrego alla mia azienda durante il processo di spedizione?",
				2: "Quali tipi di logistica supporta Antrego?",
				3: "Perché dovrei usare Antrego?",
				4: "Antrego è un'applicazione a pagamento?",
				5: "I miei clienti devono pagare per Antrego?",
			},
			save_percentage: "Risparmia il 16% al mese",
			text: "Accedi facilmente a tutte le informazioni con Antrego e contattaci per qualsiasi altra domanda.",
			title: "Impara rapidamente tutto ciò che ti incuriosisce",
		},
		cancel_anytime: "Annulla in qualsiasi momento",
		customize_package:
			"Puoi acquistare spazio di archiviazione e utenti aggiuntivi e personalizzare il tuo pacchetto in base alle tue esigenze",
		free_features_1: "10 Spedizioni",
		free_features_2: "100 MB di archiviazione",
		free_features_3: "Partner commerciali illimitati",
		free_features_4: "1 Utente",
		free_plan: "Gratuito",
		free_price: "€0",
		free_regeneration: "",
		free_title: "Mensile",
		premium_annual_features_1: "20 Utenti",
		premium_annual_features_2: "100 Partner",
		premium_annual_features_3: "1000 Spedizioni",
		premium_annual_features_4: "1000 Fatture",
		premium_annual_price: "€83.25",
		premium_annual_regeneration: "Si rinnoverà annualmente a €999",
		premium_annual_title: "Annuale",
		premium_monthly_features_1: "Spedizioni illimitate",
		premium_monthly_features_2: "Partner commerciali illimitati",
		premium_monthly_features_3: "2GB di archiviazione",
		premium_monthly_features_4: "3 Utenti",
		premium_monthly_price: "€99",
		premium_monthly_regeneration: "Si rinnoverà mensilmente a €99",
		premium_monthly_title: "Mensile",
		premium_plan: "Piano Premium",
		premium_plus_annual_features_1: "Spedizioni illimitate",
		premium_plus_annual_features_2: "Partner commerciali illimitati",
		premium_plus_annual_features_3: "5GB di archiviazione",
		premium_plus_annual_features_4: "5 Utenti",
		premium_plus_annual_price: "€83.25",
		premium_plus_annual_regeneration: "Si rinnoverà annualmente a €999",
		premium_plus_annual_title: "Annuale",
		premium_plus_monthly_features_1: "Spedizioni illimitate",
		premium_plus_monthly_features_2: "Partner commerciali illimitati",
		premium_plus_monthly_features_3: "5GB di archiviazione",
		premium_plus_monthly_features_4: "5 Utenti",
		premium_plus_monthly_price: "€249",
		premium_plus_monthly_regeneration: "Si rinnoverà mensilmente a €249",
		premium_plus_monthly_title: "Mensile",
		premium_plus_plan: "Premium Plus",
		pricing_text: "Possiamo soddisfare le tue esigenze nel modo migliore senza superare il tuo budget.",
		pricing_title: "Antrego garantisce di offrire il miglior pacchetto e prezzo per te.",
		save_percentage: "Risparmia il 16% al mese",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Arabo",
		de: "Tedesco",
		en: "Inglese",
		es: "Spagnolo",
		fr: "Francese",
		hi: "Hindi",
		ind: "Indonesiano",
		it: "Italiano",
		ja: "Giapponese",
		ko: "Coreano",
		pt: "Portoghese",
		ru: "Russo",
		tr: "Turco",
		vi: "Vietnamita",
		zh: "Cinese",
	},
	$message: {
		cannot_delete_verified_partner: "Non puoi eliminare un partner verificato.",
		confirm: "Sei sicuro di {function}?",
		created: "{item} creato.",
		deleted: "{item} eliminato.",
		error_on_view_file: "Si è verificato un errore durante la visualizzazione del file.",
		file_oversize: "La dimensione del file deve essere massimo {size}.",
		invalid: "{item} non valido",
		migrated: "{item} migrato. ({value})",
		process_has_been_started: "Il tuo {item} è stato avviato.",
		sent: "{item} inviato.",
		sent_email: "E-mail inviata. ({value})",
		sign_in_failed: "E-mail o password non corretta",
		unsupported_file_type: "Tipo di file non supportato",
		updated: "{item} aggiornato.",
	},
	$pageTitles: {
		accept_invitation: "Accetta invito",
		company_profile: "Profilo aziendale",
		home: "Home",
		landing_page: "Tracciamento digitale senza interruzioni, controllo condiviso",
		partners: "Partner",
		pdf_editor: "Editor PDF",
		product_groups: "Gruppi di prodotti",
		products: "Prodotti",
		proforma_invoices: "Fatture proforma",
		shipment_details: "Dettagli della spedizione",
		shipments: "Spedizioni",
		sign_in: "Accedi",
		sign_up: "Registrati",
		subscription: "Abbonamento",
		user_profile: "Profilo utente",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"Partner non registrati, hai creato il loro profilo sulla nostra piattaforma, quindi puoi visualizzare e modificare le loro informazioni.",
		createdHereTitle: "Creato qui:",
		description: "Puoi monitorare lo stato della tua relazione con i tuoi partner. Ecco le differenze:",
		verifiedPartnersDescription:
			"Questi partner hanno accettato il tuo invito e si sono registrati sulla piattaforma. Le loro informazioni sono solo visualizzabili e non possono essere modificate.",
		verifiedPartnersTitle: "Partner verificati:",
	},
	$productGroup: {
		add_child: "Aggiungi figlio",
		add_new_product_group: "Aggiungi nuovo gruppo di prodotti",
		add_to_root: "Aggiungi alla radice",
		has_children_fail_message: "Un gruppo di prodotti con un figlio non può essere eliminato",
		initial_message: "Usa questo {0} pulsante per creare il tuo primo gruppo di prodotti.",
		name: "Nome",
		number_of_product: "Numero di prodotti",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} approvato.",
			create: "Creato da {name}.",
			create_attachment: "{name} ha creato un allegato.",
			delete_attachment: "{name} ha eliminato un allegato.",
			demand_revise: "{name} ha richiesto una revisione.",
			mark_as_processed: "{name} contrassegnato come elaborato.",
			reject: "{name} respinto.",
			revoke: "{name} revocato.",
			send: "{name} inviato al destinatario per la valutazione.",
			update: "Aggiornato da {name}.",
			update_attachment: "{name} ha aggiornato un allegato.",
		},
		$actions: {
			approve: "Approva",
			demand_revise: "Richiedi revisione",
			mark_as_approved: "Approvato",
			mark_as_processed: "Elaborato",
			mark_as_rejected: "Respinto",
			mark_as_revise_demanded: "Revisione richiesta",
			mark_as_revoked: "Revocato",
			mark_as_sent: "Inviato",
			reject: "Respingi",
			revoke: "Revoca",
			send: "Invia",
		},
		$status: {
			approved: "Approvato",
			pending_approval: "In attesa di approvazione",
			pending_revise: "In attesa di revisione",
			processed: "Elaborato",
			rejected: "Respinto",
			revoked: "Revocato",
			template: "Modello",
		},
		approve_confirm_message: "Sei sicuro di approvare la fattura proforma?",
		approve_success_message: "La fattura proforma è stata approvata.",
		demand_revise_description_message: "Per favore, fornisci qualche dettaglio sulla tua aspettativa di revisione:",
		demand_revise_success_message: "La fattura proforma è stata inviata al mittente per la revisione.",
		mark_as_processed_confirm_message: "Confermi di contrassegnare la fattura proforma come elaborata?",
		mark_as_processed_success_message: "La fattura proforma è stata contrassegnata come elaborata.",
		no_attachment_text: "Nessun allegato aggiunto ancora.",
		reject_confirm_message: "Sei sicuro di respingere la fattura proforma?",
		reject_success_message: "La fattura proforma è stata respinta.",
		revoke_confirm_message: "Sei sicuro di revocare la fattura proforma?",
		revoke_success_message: "La fattura proforma è stata revocata.",
		send_confirm_message: "Confermi di inviare la fattura proforma al destinatario?",
		send_success_message: "La fattura proforma è stata inviata.",
	},
	$serviceMessage: {
		already_subs_module: "C'è già un abbonamento per questo modulo.",
		bad_request: "Richiesta errata",
		code_already_used: "Codice già utilizzato.",
		container_status_not_available: "Stato del container non disponibile.",
		existing_email_warning: "E-mail già esistente.",
		existing_employee_error: "Questo utente è già stato aggiunto alla tua azienda.",
		existing_employee_for_email_warning: "Il codice di invito è già stato inviato a questa e-mail",
		existing_invitation_warning: "Il codice di invito è già stato inviato a questa e-mail.",
		existing_token_already_have_time: "Il token esistente ha già tempo sufficiente per essere utilizzato.",
		existing_user: "L'utente esiste già.",
		five_min_reset_password_mail:
			"Devi attendere almeno 5 minuti dopo aver richiesto un'e-mail di reimpostazione della password una volta.",
		forbidden: "Proibito",
		have_no_corporation: "Non hai una società",
		have_no_custom_agency: "Non hai un'agenzia doganale",
		have_no_employee: "Non hai dipendenti",
		instance_can_no_delete_existing_booking_code:
			"L'istanza non può più essere eliminata a causa del codice di prenotazione esistente.",
		instance_cannot_delete: "L'istanza non può più essere eliminata.",
		instance_not_found: "Istanza non trovata.",
		internal_server_error: "Errore interno del server",
		invalid_current_password: "Password attuale non valida.",
		invalid_identifier_or_password: "Identificatore o password non validi",
		invalid_invitation_code: "Il codice di invito è errato.",
		invalid_moving_request: "Richiesta di spostamento non valida",
		invalid_operation: "Operazione non valida",
		invalid_refresh_token: "Token di aggiornamento non valido.",
		invalid_unit: "Unità non valida",
		invitation_accepting_is_due_to_the_admin: "L'accettazione dell'invito è dovuta all'amministratore.",
		invitation_already_discarded: "L'invito è già stato scartato.",
		invitation_already_used: "L'invito è già stato utilizzato.",
		invitation_has_been_used: "L'invito è stato utilizzato.",
		invitation_not_exist_email: "L'invito non esiste indirizzo e-mail.",
		invitation_not_for_company: "Questo invito non è per un'azienda.",
		invitation_not_for_custom_agency: "Questo invito non è per un'agenzia doganale.",
		invitation_not_found: "L'invito non è stato trovato.",
		invitation_not_valid: "L'invito non è più valido.",
		link_has_been_expired: "Il link è scaduto.",
		link_not_be_used_anymore: "Questo link non può più essere utilizzato",
		missing_required_fields: "Campi obbligatori mancanti.",
		no_employee_found: "Nessun dipendente trovato",
		no_proper_partner_found: "Nessun partner appropriato trovato.",
		not_acceptable: "Non accettabile",
		not_allowed_action: "Non ti è permesso compiere questa azione.",
		not_allowed_use_api: "Non sei autorizzato a utilizzare questa API.",
		not_found: "Non trovato",
		not_have_purchase_subscription:
			"Il tuo piano di abbonamento ha raggiunto il limite massimo di definizioni. Per aggiungere altre, considera l'upgrade del tuo piano di abbonamento.",
		not_permitted_use_api: "Non sei autorizzato a utilizzare questa API.",
		own_invitation_cannot_accept: "Non puoi accettare il tuo stesso invito.",
		parent_group_not_found: "Gruppo padre non trovato",
		partnerId_required: "ID partner richiesto.",
		partnership_already_exists: "Il partner è già stato aggiunto.",
		password_cant_involve_space_char: "La password non può contenere spazi.",
		password_min_chars_least: "La password deve contenere almeno 6 caratteri.",
		payment_required: "Abbonati per questo processo.",
		paytr_integration_error: "Errore nell'integrazione PayTR",
		ports_are_required: "Sono richiesti i porti.",
		product_group_tree_not_found: "Albero del gruppo di prodotti non trovato",
		proforma_invoice_action_not_available: "Lo stato della fattura proforma non è disponibile per questa azione.",
		proforma_invoice_template_update: "La fattura proforma deve essere un modello per essere aggiornata.",
		shipment_email_not_exist: "Inserisci un'e-mail valida.",
		shipment_method_is_required: "È richiesto il metodo di spedizione.",
		shipping_term_is_required: "È richiesto il termine di spedizione.",
		someone_else_uses_this_email: "Qualcun altro utilizza questo indirizzo e-mail.",
		someone_else_uses_this_username: "Qualcun altro utilizza questo nome utente.",
		something_went_wrong: "Qualcosa è andato storto",
		type_can_be_abstract_company: '"tipo" può essere solo "società astratta"',
		unauthorized: "Non autorizzato",
		undefined_result: "Risultato non definito",
		unexpected_error_occurred: "Si è verificato un errore imprevisto",
		unit_already_added: "L'unità è già stata aggiunta.",
		user_already_employee: "L'utente è già un dipendente.",
		user_already_exists: "L'utente esiste già in un'azienda.",
		user_does_not_exist: "L'utente non esiste.",
		user_not_found: "Utente non trovato.",
		username_min_chars_error: '"username" deve contenere almeno 6 caratteri.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} ha aggiunto un veicolo.",
				delete_container: "{name} ha eliminato un veicolo.",
				update_container: "{name} ha aggiornato un veicolo.",
			},
			create: "Creato da {name}.",
			create_attachment: "{name} ha aggiunto un allegato.",
			create_container: "{name} ha aggiunto un container.",
			delete_attachment: "{name} ha eliminato un allegato.",
			delete_container: "{name} ha eliminato un container.",
			update: "Aggiornato da {name}.",
			update_attachment: "{name} ha aggiornato un allegato.",
			update_container: "{name} ha aggiornato un container.",
		},
		$containerStatus: {
			arrived: "Arrivato",
			off_load: "Scaricamento",
			on_load: "Caricamento",
			on_way: "In transito",
			reserved: "Riservato",
		},
		$method: {
			aerial: "Aereo",
			land: "Terra",
			railway: "Ferrovia",
			sea: "Mare",
		},
		$vehicle: {
			license_number: "Targa",
		},
		archiving_success_message: "La spedizione è stata archiviata.",
		leave_message: "Lascia il tuo messaggio o commento",
		no_booking_info:
			"Nessuna informazione di prenotazione è stata ancora impostata. Usa il pulsante {0} per aggiungere.",
		no_transportation_info:
			"Nessuna informazione dettagliata è stata ancora impostata. Usa il pulsante {0} per impostare.",
		un_archiving_success_message: "La spedizione è stata disarchiviata.",
	},
	$shortening: {
		number: "N°",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "Il tuo abbonamento non è stato rinnovato. Verifica il tuo metodo di pagamento.",
			fail: "Si è verificato un errore imprevisto. Riprova più tardi. Se il problema persiste, segnalalo inviando un'e-mail a info@antrego.net.",
			ok: "Il processo di acquisto è stato avviato. Il risultato del processo verrà notificato via e-mail. Il tuo abbonamento inizierà quando il pagamento sarà completato con successo.",
			payment_process_is_ongoing:
				"Il tuo processo di pagamento è in corso. Il risultato del processo verrà notificato via e-mail. Il tuo abbonamento inizierà quando il pagamento sarà completato con successo.",
			success: "Il tuo abbonamento è stato creato con successo. Puoi iniziare a utilizzare Antrego facilmente.",
			unsubscribed: "Annullato l'abbonamento",
			you_dont_have_any_subscription:
				"Non hai alcun abbonamento. Scegli un pacchetto nella pagina dell'abbonamento e inizia a usarlo facilmente.",
			you_dont_have_any_subscription_text: "Scegli un piano per continuare.",
			you_dont_have_any_subscription_title: "Non hai nessun abbonamento attivo.",
		},
	},
	$unit: {
		acres: "Acri",
		boxes: "Scatole",
		cubic_meter: "Metro cubo",
		dozen: "Dozzine",
		feet: "Piedi",
		fluid_ounces: "Once fluide",
		gallons: "Galloni",
		gram: "Gramma",
		hectare: "Ettaro",
		inches: "Pollici",
		kilogram: "Chilogrammo",
		kilometer: "Chilometro",
		liter: "Litro",
		meter: "Metro",
		miles: "Miglia",
		milligram: "Milligrammo",
		milliliter: "Millilitro",
		millimeter: "Millimetro",
		ounces: "Once",
		pallets: "Pallet",
		pieces: "Pezzi",
		pounds: "Libbre",
		quarts: "Quarti",
		square_feet: "Piedi quadrati",
		square_inches: "Pollici quadrati",
		square_kilometer: "Chilometro quadrato",
		square_meter: "Metro quadrato",
		square_miles: "Miglia quadrate",
		square_yards: "Iarde quadrate",
		ton: "Tonnellate",
		yards: "Iarde",
	},
	$validation: {
		email: "Per favore inserisci un'e-mail valida",
		invalid: "{item} non valido",
		max_length: "{item} deve essere inferiore a {length} caratteri",
		min_length: "{item} deve essere superiore a {length} caratteri",
		must_length: "{item} deve essere {length} caratteri",
		only_number: "{item} dovrebbe essere un numero",
		password_repeat_warning: "La ripetizione della password non è uguale alla nuova password.",
		required: "{item} è richiesto",
		required_for_default_language: "{item} è richiesto per la lingua predefinita dell'azienda.",
		you_must_approve_distance_sales_agreement: "Devi approvare il Contratto di Vendita a Distanza",
	},
	about: "Informazioni",
	accept_invitation: "Accetta invito",
	accept_invitation_success_message: "Hai utilizzato l'invito con successo.",
	accessibility: "Accessibilità",
	actions: "Azioni",
	active: "Attivo",
	activities: "Attività",
	add: "Aggiungi",
	add_item: "Aggiungi {item}",
	add_new_employee: "Aggiungi nuovo dipendente",
	add_new_product: "Aggiungi nuovo prodotto",
	add_template: "Aggiungi Modello",
	address: "Indirizzo",
	again: "Di nuovo",
	alert: "Avviso",
	already_have_employee_message: "Sei già un dipendente di un'azienda.",
	amount: "Importo",
	approve: "Approva",
	archive: "Archivia",
	attachment: "Allegato",
	attachments: "Allegati",
	auto_attached_pdf: "PDF aggiunto automaticamente",
	bank: "Banca",
	benefits: "Vantaggi",
	billed_to: "Fatturato a",
	booking: "Prenotazione",
	booking_code: "Codice di prenotazione",
	booking_details: "Dettagli della prenotazione",
	business_partner: "Partner commerciale",
	business_partners: "Partner commerciali",
	cancel: "Annulla",
	change_password: "Cambia password",
	checking_invitation_code_message: "Verifica del codice di invito",
	choose_image: "Scegli immagine",
	choose_your_plan: "Scegli il tuo piano",
	clear: "Pulisci",
	click_to_play_the_video: "Clicca per riprodurre il video",
	close: "Chiudi",
	code: "Codice",
	commercial: "Commerciale",
	commercial_partner: "Partner commerciale",
	commercial_partners: "Partner commerciali",
	companies: "Aziende",
	company: "Azienda",
	company_address: "Indirizzo della società",
	company_name: "Nome della società",
	company_profile: "Profilo aziendale",
	configuration: "Configurazione",
	confirm: "Conferma",
	confirm_distance_selling_agreement: "Conferma il Contratto di Vendita a Distanza.",
	consult_us: "Consultaci",
	contact: "Contatto",
	contact_info: "Informazioni di contatto",
	contact_text: "Stai certo, ti contatteremo il prima possibile.",
	contact_title: "Consultaci, semplifica i tuoi processi",
	contact_to_us: "Contattaci",
	container: "Container",
	container_no: "N. Contenitore",
	containers: "Container",
	content: "Contenuto",
	contract: "Contratto",
	copy_link: "Copia link",
	corporation: "Azienda",
	countries: "Paesi",
	country: "Paese",
	create: "Crea",
	create_a_new_account: "Crea un nuovo account",
	create_company: "Crea azienda",
	create_document: "Crea Documento",
	created_at: "Creato il",
	created_here: "Creato qui",
	credit_card_info: "Informazioni sulla carta di credito",
	credit_card_no: "Numero della carta di credito",
	currencies: "Valute",
	currency: "Valuta",
	current_password: "Password attuale",
	current_plan: "Piano attuale",
	customer_company_address: "Indirizzo della società cliente",
	customer_company_name: "Nome della società cliente",
	customs: "Dogana",
	customs_agencies: "Agenzie doganali",
	customs_agency: "Agenzia doganale",
	cvv: "CVV",
	daily: "Giornaliero",
	dashboard: "Dashboard",
	date: "Data",
	day: "Giorno",
	default: "Predefinito",
	default_language: "Lingua predefinita",
	delete: "Elimina",
	delete_account: "Elimina account",
	delete_confirm_message: "Confermi l'eliminazione?",
	delete_document: "Elimina documento",
	delete_template: "Elimina modello",
	description: "Descrizione",
	detail: "Dettaglio",
	discard: "Scarta",
	distance_sales_agreement: "Contratto di Vendita a Distanza",
	document: "Documento",
	document_actions: "Azioni documento",
	document_added: "Documento aggiunto",
	document_manager: "Gestore documenti",
	document_name: "Nome del documento",
	document_number: "Numero documento",
	document_overwrite: "Salva modifiche come nuovo documento",
	document_save_info: "Questo documento sarà salvato solo all'interno di questa fattura proforma.",
	document_updated: "Documento aggiornato",
	download: "Scarica",
	download_pdf: "Scarica PDF",
	edit: "Modifica",
	edit_document: "Modifica documento",
	edit_item: "Modifica {item}",
	edit_product: "Modifica prodotto",
	edit_profile: "Modifica profilo",
	edit_template: "Modifica modello",
	edit_your_profile_picture: "Modifica la tua immagine del profilo",
	email: "E-mail",
	employee: "Dipendente",
	employee_type: "Tipo di dipendente",
	employees: "Dipendenti",
	empty_data_warning:
		"In quest'area non ci sono ancora dati. Puoi iniziare a esplorare la piattaforma con dati di esempio.",
	estimated_arrival_day: "Giorno di arrivo stimato",
	expense: "Spesa",
	expenses: "Spese",
	expires_at: "Scade il",
	exported_containers: "Container esportati",
	fail: "Fallito",
	failed: "Fallito",
	faq: "FAQ",
	feedback: "Feedback",
	file: "File",
	file_name: "Nome del File",
	file_preview: "Anteprima file",
	file_view: "Visualizza file",
	filter: "Filtro",
	first_name: "Nome",
	first_name_last_name: "Nome Cognome",
	forget_password: "Hai dimenticato la password",
	forget_password_message: "Hai dimenticato la tua password?",
	forget_password_subtitle: "Inserisci il tuo indirizzo email per reimpostare la password.",
	free: "Gratuito",
	from: "Da",
	general: "Predefinito",
	get_started_now: "Inizia ora",
	give_us_feedback: "Dacci un feedback",
	go_to_panel: "Vai al pannello",
	hide: "Nascondi",
	home_page: "Home Page",
	i_have_invitation_code: "Ho un codice di invito",
	imported_containers: "Container importati",
	in_progress: "In corso",
	incoming: "In arrivo",
	incoming_shipments: "Spedizioni in arrivo",
	initial: "Iniziale",
	inputs: "Contenuto",
	invitation: "Invito",
	invitation_code: "Codice invito",
	invitation_mail_sent: "E-mail di invito inviata",
	invitations: "Inviti",
	invoice: "Fattura",
	invoice_no: "Numero della fattura",
	join: "Unisci",
	join_company: "Unisciti all'azienda",
	language: "Lingua",
	language_options: "Opzioni di lingua",
	last_name: "Cognome",
	last_one_year: "Ultimo anno",
	latitude: "Latitudine",
	left_a_comment: "Ha lasciato un commento",
	link_copied: "Link copiato",
	link_copy_failed: " Copia del link fallita",
	loading_country: "Paese di caricamento",
	loading_point: "Punto di caricamento",
	loading_port: "Porto di caricamento",
	log_in: "accedi",
	logo: "Logo",
	longitude: "Longitudine",
	mail_sent: "E-mail inviata",
	manage_profile: "Gestisci profilo",
	message: "Messaggio",
	message_company_create: "Azienda creata.",
	message_company_join: "Inserisci il codice di invito.",
	message_company_joined: "Ti sei unito all'azienda",
	message_employee_delete: "Dipendente eliminato.",
	message_employee_save: "Dipendente salvato.",
	message_join_company: "Inserisci il tuo codice per unirti a un'azienda esistente",
	method: "Metodo",
	migrate: "Migra",
	migrate_partner: "Migra azienda",
	mobile_number: "Numero di cellulare",
	month: "Mese",
	month_lowercase: "mese",
	monthly: "Mensile",
	my_profile: "il mio profilo",
	my_role: "Il mio ruolo",
	name: "Nome",
	new: "Nuovo",
	new_company_package: "gratis",
	new_password: "Nuova password",
	new_shipment: "Nuova spedizione",
	next: "Prossimo",
	no: "No",
	no_content: "Nessun contenuto",
	no_entity: "Nessuna entità",
	no_entity_message: "Nessuna entità trovata.",
	no_text: "Nessun testo",
	not_found_page_message: "Scusa, non ho trovato questa pagina :(",
	note: "Nota",
	notes: "Note",
	number_of_rows: "Numero di righe",
	ok: "OK",
	operation_fail: "Operazione fallita",
	outgoing: "In uscita",
	outgoing_shipments: "Spedizioni in uscita",
	packages: "Pacchetti",
	packing_list_title: "Lista di imballaggio",
	parent_group: "Gruppo padre",
	partner: "Partner",
	partners: "Partner",
	partnership_status: "Stato della partnership",
	passive: "Passivo",
	password: "Password",
	pay: "Paga",
	payback: "Rimborso",
	payback_confirm_message: "Confermi il rimborso?",
	payback_warning_message: "Se continui, il tuo abbonamento sarà terminato.",
	payment: "Pagamento",
	payment_amount: "Importo del pagamento",
	payment_awaited: "Pagamento in attesa",
	payment_history: "Storico dei pagamenti",
	payment_info: "Informazioni di pagamento",
	payment_information: "Informazioni di pagamento",
	payment_success: "Pagamento effettuato con successo",
	payment_summary: "Riepilogo del pagamento",
	payment_will_be_made_3d_secure: "*Il pagamento verrà effettuato con 3D Secure.",
	pdf_attached_message: "PDF aggiunto con successo",
	pdf_editor: "Editor PDF",
	pdf_name: "Nome PDF",
	pending_invitations: "Inviti in sospeso",
	per_month: "Al mese",
	phone_number: "Numero di telefono",
	plate_numbers: "Numeri di targa",
	premium: "Premium",
	premium_plus: "Premium Plus",
	preview: "Anteprima",
	previous: "Precedente",
	pricing: "Prezzi",
	process: "Processo",
	product: "Prodotto",
	product_group: "Gruppo di prodotti",
	product_group_name: "Nome del gruppo di prodotti",
	product_groups: "Gruppi di prodotti",
	product_name: "Nome del prodotto",
	products: "Prodotti",
	profile: "Profilo",
	proforma_invoice: "Fattura proforma",
	proforma_invoices: "Fatture proforma",
	proforma_number: "Numero proforma",
	proforma_title: "Fattura proforma",
	purchase: "Acquisto",
	quantity: "Quantità",
	receiver: "Destinatario",
	receiver_company: "Azienda destinataria",
	receiver_customs_agency: "Agenzia doganale destinataria",
	redirection_to_home_link: "Home",
	redirection_to_home_message: "Puoi anche andare a ",
	redirection_to_sign_in_message: "Hai già un account?",
	redirection_to_sign_up_message: "Non hai ancora un account?",
	reload: "Ricarica",
	renewal: "Rinnovo",
	repaid: "Rimborsato",
	reset_password: "Reimposta password",
	rows_per_page: "Righe per pagina",
	sales: "Vendite",
	save: "Salva",
	save_as_document: "Salva come Documento",
	save_as_new_document: "Salva come Nuovo Documento",
	save_as_new_template: "Salva come Nuovo Modello",
	save_as_template: "Salva come Modello",
	save_changes: "Salva modifiche",
	save_pdf_to_attachments: "Salva PDF negli Allegati",
	saved_card_info: "Informazioni carta salvate",
	search: "Cerca",
	select: "Seleziona",
	select_document: "Seleziona il documento",
	select_document_to_overwrite: "Seleziona Documento da Sovrascrivere",
	select_file: "Seleziona file",
	select_file_place_holder: "Seleziona un'immagine",
	select_language: "Seleziona lingua",
	select_template: "Seleziona il modello",
	select_template_to_overwrite: "Seleziona Modello da Sovrascrivere",
	selected_partner: "Partner selezionato",
	send: "Invia",
	send_again: "Invia di nuovo",
	send_email: "Invia e-mail",
	send_feedback: "Invia feedback",
	send_invitation: "Invia invito",
	sender: "Mittente",
	sender_company: "Azienda mittente",
	sender_customs_agency: "Agenzia doganale mittente",
	settings: "Impostazioni",
	shipment: "Spedizione",
	shipment_method: "Metodo di spedizione",
	shipment_type: "Tipo di spedizione",
	shipments: "Spedizioni",
	shipments_filter: "Filtro spedizioni",
	shipping_term: "Termine di spedizione",
	shipping_terms: "Termini di spedizione",
	show: "Mostra",
	sign_in: "Accedi",
	sign_out: "Esci",
	sign_out_confirm_message: "Confermi l'uscita?",
	sign_out_success_message: "Arrivederci.",
	sign_up: "Registrati",
	social_media: "Social Media",
	social_media_account: "Account social media",
	start_time: "Ora di inizio",
	status: "Stato",
	subscribe: "Abbonati",
	subscription: "Abbonamento",
	subscription_info_message: "Puoi usufruire di tutti i servizi in modo illimitato durante il periodo di abbonamento.",
	subscription_warning_message:
		"**Le informazioni sulla tua carta saranno memorizzate in modo sicuro e il tuo abbonamento verrà rinnovato regolarmente a meno che non lo annulli.",
	success: "Successo",
	super: "Super",
	supported_files_img: "File supportati: JPG, PNG.",
	switch_language: "Cambia lingua",
	switch_theme: "Cambia tema",
	table_inputs: "Contenuto della tabella",
	target: "Obiettivo",
	target_partner: "Partner obiettivo",
	tax_number: "Numero fiscale",
	template: "Modello",
	template_actions: "Azioni modello",
	template_added: "Modello aggiunto",
	template_name: "Nome del Modello",
	template_overwrite: "Salva modifiche come nuovo modello",
	template_save_info: "I modelli salvati saranno disponibili in seguito per l'uso in fatture proforma e spedizioni.",
	template_updated: "Modello aggiornato",
	terms_of_sale_and_other_comments: "Termini di vendita - Commenti",
	theme: "Tema",
	this_month: "Questo mese",
	this_year: "Quest'anno",
	title: "Titolo",
	to: "A",
	toDelete: "Elimina",
	total: "Totale",
	total_amount: "Importo totale",
	transaction_no: "Numero transazione",
	transactions: "Transazioni",
	try_for_free: "prova gratis",
	type: "Tipo",
	types: "Tipi",
	un_archive: "Disarchivia",
	unit: "Unità",
	units: "Unità",
	unloading_country: "Paese di scarico",
	unloading_point: "Punto di scarico",
	unloading_port: "Porto di scarico",
	unsubscribe: "Annulla l'abbonamento",
	unsubscribe_confirm_message:
		"Se annulli l'abbonamento, non sarai in grado di utilizzare efficacemente le funzionalità del sistema. Sei sicuro di voler annullare l'abbonamento?",
	upload_new_image: "Carica nuova immagine",
	use_code: "Usa codice",
	use_invitation_code: "Usa codice invito",
	use_invitation_code_description: "Digita il tuo codice di invito inviato alla tua casella di posta.",
	use_invitation_code_success_message: "Il codice di invito è stato utilizzato con successo.",
	user_profile: "Il mio profilo",
	vehicle: "Veicolo",
	vehicles: "Veicoli",
	verified_partner: "Partner verificato",
	verify_password: "Verifica password",
	visibility: "Visibilità",
	warehouse: "Magazzino",
	warehouses: "Magazzini",
	website: "Sito web",
	week: "Settimana",
	welcome: "Benvenuto",
	what_we_are_doing: "Cosa stiamo facendo",
	year: "Anno",
	year_lowercase: "anno",
	yearly: "Annuale",
	yes: "Sì",
	you_dont_have_any_active_subscription: "Non hai alcun abbonamento attivo.",
};
