export default {
	$accessibility: {
		archived: "الأرشيف",
		present: "الحاضر",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "مخفي عن المستلم",
			hidden_from_sender: "مخفي عن المرسل",
			private: "خاص",
			shared_with_custom: "مشارك مع مخصص",
			shared_with_everyone: "مشارك مع الجميع",
			shared_with_receiver: "مشارك مع المستلم",
			shared_with_sender: "مشارك مع المرسل",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit} جيجابايت تخزين",
		limitMBStorage: "{limit} ميجابايت تخزين",
		limitUser: "{limit} مستخدم",
		limitUsers: "{limit} مستخدمين",
		renewAtPricePerUnit: "سيتم التجديد بـ {currency}{price} لكل {unit}",
		unlimitedModule: "غير محدود {module}",
		upToLimitModule: "حتى {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, تركيا",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sar!2str!4v1701805110461!5m2!1sar!2str",
		name: "شركة سلوش سوفتوير",
		phoneNumber: "+90 (555) 444-33-22",
		slogan: "عزز عملك مع Antrego. إدارة عمليات الشحن الخاصة بك رقمياً، بأمان، وبسهولة.",
	},
	$country: {
		afghanistan: "أفغانستان",
		aland: "آلاند",
		albania: "ألبانيا",
		algeria: "الجزائر",
		american_samoa: "ساموا الأمريكية",
		andorra: "أندورا",
		angola: "أنغولا",
		anguilla: "أنغيلا",
		antarctica: "أنتاركتيكا",
		antigua_and_barbuda: "أنتيغوا وباربودا",
		argentina: "الأرجنتين",
		armenia: "أرمينيا",
		aruba: "أروبا",
		australia: "أستراليا",
		austria: "النمسا",
		azerbaijan: "أذربيجان",
		bahamas: "الباهاما",
		bahrain: "البحرين",
		bangladesh: "بنغلاديش",
		barbados: "بربادوس",
		belarus: "بيلاروس",
		belgium: "بلجيكا",
		belize: "بليز",
		benin: "بنين",
		bermuda: "برمودا",
		bhutan: "بوتان",
		bolivia: "بوليفيا",
		bonaire_sint_eustatius_and_saba: "بونير، سينت أوستاتيوس، وسابا",
		bosnia_and_herzegovina: "البوسنة والهرسك",
		botswana: "بوتسوانا",
		bouvet_island: "جزيرة بوفيت",
		brazil: "البرازيل",
		british_indian_ocean_territory: "إقليم المحيط الهندي البريطاني",
		british_virgin_islands: "جزر العذراء البريطانية",
		brunei: "بروناي",
		bulgaria: "بلغاريا",
		burkina_faso: "بوركينا فاسو",
		burundi: "بوروندي",
		cabo_verde: "كابو فيردي",
		cambodia: "كمبوديا",
		cameroon: "الكاميرون",
		canada: "كندا",
		cayman_islands: "جزر كايمان",
		central_african_republic: "جمهورية أفريقيا الوسطى",
		chad: "تشاد",
		chile: "تشيلي",
		china: "الصين",
		christmas_island: "جزيرة كريسماس",
		cocos_islands: "جزر كوكوس [كيلينغ]",
		colombia: "كولومبيا",
		comoros: "جزر القمر",
		congo_republic: "جمهورية الكونغو",
		cook_islands: "جزر كوك",
		costa_rica: "كوستاريكا",
		croatia: "كرواتيا",
		cuba: "كوبا",
		curacao: "كوراساو",
		cyprus: "قبرص",
		czechia: "التشيك",
		denmark: "الدنمارك",
		djibouti: "جيبوتي",
		dominica: "دومينيكا",
		dominican_republic: "جمهورية الدومينيكان",
		dr_congo: "جمهورية الكونغو الديمقراطية",
		ecuador: "الإكوادور",
		egypt: "مصر",
		el_salvador: "السلفادور",
		equatorial_guinea: "غينيا الاستوائية",
		eritrea: "إريتريا",
		estonia: "إستونيا",
		eswatini: "إيسواتيني",
		ethiopia: "إثيوبيا",
		falkland_islands: "جزر فوكلاند",
		faroe_islands: "جزر فارو",
		fiji: "فيجي",
		finland: "فنلندا",
		france: "فرنسا",
		french_guiana: "غويانا الفرنسية",
		french_polynesia: "بولينيزيا الفرنسية",
		french_southern_territories: "الأقاليم الجنوبية الفرنسية",
		gabon: "الغابون",
		gambia: "غامبيا",
		georgia: "جورجيا",
		germany: "ألمانيا",
		ghana: "غانا",
		gibraltar: "جبل طارق",
		greece: "اليونان",
		greenland: "غرينلاند",
		grenada: "غرينادا",
		guadeloupe: "غوادلوب",
		guam: "غوام",
		guatemala: "غواتيمالا",
		guernsey: "غيرنزي",
		guinea: "غينيا",
		guinea_bissau: "غينيا بيساو",
		guyana: "غيانا",
		haiti: "هايتي",
		heard_island_and_mcdonald_islands: "جزيرة هيرد وجزر ماكدونالد",
		honduras: "هندوراس",
		hong_kong: "هونغ كونغ",
		hungary: "هنغاريا",
		iceland: "آيسلندا",
		india: "الهند",
		indonesia: "إندونيسيا",
		iran: "إيران",
		iraq: "العراق",
		ireland: "أيرلندا",
		isle_of_man: "جزيرة مان",
		israel: "إسرائيل",
		italy: "إيطاليا",
		ivory_coast: "ساحل العاج",
		jamaica: "جامايكا",
		japan: "اليابان",
		jersey: "جيرزي",
		jordan: "الأردن",
		kazakhstan: "كازاخستان",
		kenya: "كينيا",
		kiribati: "كيريباس",
		kosovo: "كوسوفو",
		kuwait: "الكويت",
		kyrgyzstan: "قرغيزستان",
		laos: "لاوس",
		latvia: "لاتفيا",
		lebanon: "لبنان",
		lesotho: "ليسوتو",
		liberia: "ليبيريا",
		libya: "ليبيا",
		liechtenstein: "ليختنشتاين",
		lithuania: "ليتوانيا",
		luxembourg: "لوكسمبورغ",
		macao: "ماكاو",
		madagascar: "مدغشقر",
		malawi: "مالاوي",
		malaysia: "ماليزيا",
		maldives: "المالديف",
		mali: "مالي",
		malta: "مالطا",
		marshall_islands: "جزر مارشال",
		martinique: "مارتينيك",
		mauritania: "موريتانيا",
		mauritius: "موريشيوس",
		mayotte: "مايوت",
		mexico: "المكسيك",
		micronesia: "ميكرونيزيا",
		moldova: "مولدوفا",
		monaco: "موناكو",
		mongolia: "منغوليا",
		montenegro: "الجبل الأسود",
		montserrat: "مونتسيرات",
		morocco: "المغرب",
		mozambique: "موزمبيق",
		myanmar: "ميانمار",
		namibia: "ناميبيا",
		nauru: "ناورو",
		nepal: "نيبال",
		netherlands: "هولندا",
		new_caledonia: "كاليدونيا الجديدة",
		new_zealand: "نيوزيلندا",
		nicaragua: "نيكاراغوا",
		niger: "النيجر",
		nigeria: "نيجيريا",
		niue: "نيوي",
		norfolk_island: "جزيرة نورفولك",
		north_korea: "كوريا الشمالية",
		north_macedonia: "شمال مقدونيا",
		northern_mariana_islands: "جزر ماريانا الشمالية",
		norway: "النرويج",
		oman: "عمان",
		pakistan: "باكستان",
		palau: "بالاو",
		palestine: "فلسطين",
		panama: "بنما",
		papua_new_guinea: "بابوا غينيا الجديدة",
		paraguay: "باراغواي",
		peru: "بيرو",
		philippines: "الفلبين",
		pitcairn_islands: "جزر بيتكيرن",
		poland: "بولندا",
		portugal: "البرتغال",
		puerto_rico: "بورتوريكو",
		qatar: "قطر",
		reunion: "ريونيون",
		romania: "رومانيا",
		russia: "روسيا",
		rwanda: "رواندا",
		saint_barthelemy: "سانت بارتيليمي",
		saint_helena: "سانت هيلينا",
		saint_lucia: "سانت لوسيا",
		saint_martin: "سانت مارتن",
		saint_pierre_and_miquelon: "سان بيير وميكلون",
		samoa: "ساموا",
		san_marino: "سان مارينو",
		sao_tome_and_principe: "ساو تومي وبرينسيبي",
		saudi_arabia: "السعودية",
		senegal: "السنغال",
		serbia: "صربيا",
		seychelles: "سيشل",
		sierra_leone: "سيراليون",
		singapore: "سنغافورة",
		sint_maarten: "سانت مارتن",
		slovakia: "سلوفاكيا",
		slovenia: "سلوفينيا",
		solomon_islands: "جزر سليمان",
		somalia: "الصومال",
		south_africa: "جنوب أفريقيا",
		south_georgia_and_south_sandwich_islands: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
		south_korea: "كوريا الجنوبية",
		south_sudan: "جنوب السودان",
		spain: "إسبانيا",
		sri_lanka: "سريلانكا",
		st_kitts_and_nevis: "سانت كيتس ونيفيس",
		st_vincent_and_grenadines: "سانت فنسنت وجزر غرينادين",
		sudan: "السودان",
		suriname: "سورينام",
		svalbard_and_jan_mayen: "سفالبارد ويان ماين",
		sweden: "السويد",
		switzerland: "سويسرا",
		syria: "سوريا",
		taiwan: "تايوان",
		tajikistan: "طاجيكستان",
		tanzania: "تنزانيا",
		thailand: "تايلاند",
		timor_leste: "تيمور الشرقية",
		togo: "توغو",
		tokelau: "توكيلاو",
		tonga: "تونغا",
		trinidad_and_tobago: "ترينيداد وتوباغو",
		tunisia: "تونس",
		turkiye: "تركيا",
		turkmenistan: "تركمانستان",
		turks_and_caicos_islands: "جزر تركس وكايكوس",
		tuvalu: "توفالو",
		uganda: "أوغندا",
		ukraine: "أوكرانيا",
		united_arab_emirates: "الإمارات العربية المتحدة",
		united_kingdom: "المملكة المتحدة",
		united_states: "الولايات المتحدة",
		uruguay: "أوروغواي",
		us_minor_outlying_islands: "جزر الولايات المتحدة البعيدة الصغيرة",
		us_virgin_islands: "جزر العذراء الأمريكية",
		uzbekistan: "أوزبكستان",
		vanuatu: "فانواتو",
		vatican_city: "الفاتيكان",
		venezuela: "فنزويلا",
		vietnam: "فيتنام",
		wallis_and_futuna: "واليس وفوتونا",
		western_sahara: "الصحراء الغربية",
		yemen: "اليمن",
		zambia: "زامبيا",
		zimbabwe: "زيمبابوي",
	},
	$currency: {
		euro: "اليورو",
		turkish_lira: "الليرة التركية",
		us_dollar: "الدولار الأمريكي",
	},
	$documentManager: {
		message_1: 'لتعديل القالب، انقر فوق زر "تعديل القالب" للانتقال إلى شاشة التحرير.',
		message_2: 'يمكنك تغيير عدد الصفوف في القائمة عن طريق زيادة أو تقليل "عدد الصفوف".',
		message_3: 'سيتم عرض التغييرات التي أجريتها في حقول المحتوى على الفور في قسم "المعاينة".',
		message_4: 'لإنشاء ملف PDF، انقر فوق زر "إجراءات المستند" لحفظه كملف PDF.',
		message_5: 'للوصول إلى المستندات لاحقًا، يمكنك حفظها من خلال زر "إجراءات المستند".',
		message_6: "سيتم عرض البيانات المدخلة في كل خلية من الجدول في المعاينة كما هي.",
		message_7: "يمكن تحرير الجدول البياني كما لو كان ملف Excel أو جدول بيانات إلكتروني.",
	},
	$employeeType: {
		admin: "مدير",
		default: "افتراضي",
		exporter: "مُصدّر",
		importer: "مُستورد",
	},
	$format: {
		date: "DD/MM/YYYY",
		date_time: "DD/MM/YYYY HH:mm:ss",
		date_time_without_second: "DD/MM/YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "أنشئ شركتك هنا.",
			create_company_title: "هل ترغب في إضافة شركتك؟",
			description: "لقد سجلت الدخول بنجاح. الآن تحتاج إلى الانضمام إلى شركة أو إنشاء شركة جديدة.",
			join_company_subtitle: "أدخل رمزك وانضم إلى الشركة هنا.",
			join_company_title: "هل ترغب في الانضمام إلى شركة موجودة؟",
			title: "مرحبًا {name}",
		},
		$unauthorized: {
			description: "تحكم في عمليات الاستيراد والتصدير باستخدام Antrego",
			forget_password_subtitle: "يرجى إدخال بريدك الإلكتروني لإعادة تعيين كلمة المرور.",
			sign_in_subtitle: "سجل الدخول هنا",
			sign_in_title: "هل لديك حساب؟",
			sign_up_subtitle: "اشترك هنا",
			sign_up_title: "هل ترغب في إنشاء حساب؟",
			title: "مرحبًا بك في Antrego",
		},
	},
	$invitationType: {
		company_partner: "دعوة التعاون التجاري",
		customs_partner: "دعوة شريك الجمارك",
		employee: "دعوة الموظف",
	},
	$landing: {
		$benefits: {
			benefits_title: "الفوائد",
			business_process: "يسرع عمليات العمل الخاصة بك.",
			control_title: "تحكم مع Antrego.",
			highlight_word: "تحكم",
			risk_of_error: "يقلل من خطر الأخطاء.",
			text: "Antrego هو برنامج لتتبع الشحن يساعد شركات الشحن وعملائهم ووكلاء الجمارك على تتبع عمليات الشحن عبر منصة مشتركة.",
			time_and_cost_savings: "يوفر الوقت والتكاليف.",
		},
		$content: {
			$text: {
				0: "تتبع جميع شحناتك في مكان واحد، وتحكم في أموالك من خلال تقارير ديناميكية.",
				1: "شارك تحديثاتك في الوقت الفعلي مع عملائك لتحسين جودة خدماتك.",
				2: "عرض ومشاركة جميع الوثائق المتعلقة بشحنتك في نظام واحد.",
				3: "تحكم في عمليتك بسهولة من خلال الوصول إلى Antrego من جميع الأجهزة والمواقع.",
				4: "قم بتخزين جميع بياناتك باستخدام تقنية التشفير SHA-256. اطمئن من حيث الأمان.",
				5: "قم بالتتبع المشترك مع جميع أصحاب المصلحة في عملية العمل. إدارة العملية بشكل أكثر فعالية.",
			},
			$title: {
				0: "إدارة الشحنات",
				1: "علاقات العملاء",
				2: "إدارة المستندات",
				3: "سهولة الوصول",
				4: "أمان البيانات",
				5: "التتبع المشترك",
			},
		},
		$home: {
			text: "اخترنا لتتبع رقمي سلس والتحكم المشترك.",
			title: "إدارة جميع عمليات الشحن الخاصة بك بسهولة وبشكل رقمي",
		},
		$lifecycle: {
			headline: "العملية",
			info_button: "الحصول على المعلومات",
			lifecycle_texts: {
				0: "قم بتحميل ملفات إضافية لإنشاء فاتورتك المبدئية وشاركها على الفور مع عميلك.",
				1: "أدخل تفاصيل شحنتك وأضف المستندات. قم بإخطار عميلك وادير جميع عمليات الشحن المشتركة من خلال قناة واحدة.",
				2: "تتبع وضعك المالي المتعلق بالاستيراد والتصدير في الوقت الفعلي.",
			},
			lifecycle_titles: {
				0: "تسجيل الدخول وإنشاء الفاتورة المبدئية",
				1: "إنشاء الشحنة",
				2: "تتبع الأنشطة المالية",
			},
			productivity_title: "زيادة الإنتاجية وتقليل التعقيد والأخطاء في 3 خطوات.",
			tracking_guide_title: "دليل التتبع السهل",
		},
		$logistics: {
			logistics_title: "أنواع الخدمات اللوجستية المدعومة",
			solutions_title: "الحل المناسب لكل حمولة",
			text: "مع Antrego، شريكك الرقمي في جميع أنواع النقل، نقف بجانبك في جميع حركاتك العالمية.",
		},
		$pdfEditor: {
			description:
				"محرر PDF الخاص بنا هو أداة قوية تجعل إنشاء وتعديل الفواتير أكثر سهولة. وفر الوقت وقدم مستندات بمظهر احترافي لشركائك التجاريين.",
			features: {
				design: "تصميم الفاتورة: أضف شعارك، عدّل الألوان، وخصص شكل الفاتورة.",
				edit: "تحرير المستندات: قم بسرعة بتعديل ملفات PDF، أضف أو أزل النصوص.",
				shareSave: "المشاركة والحفظ: قم بتنزيل المستندات التي أنشأتها، شاركها أو خزّنها مباشرة على المنصة.",
			},
			longTitle: "فواتير سريعة ومخصصة باستخدام محرر PDF.",
			note: "يسمح هذا الإعداد للمستخدمين باستكشاف الميزات بصرياً ومراجعة المزايا كتابياً.",
			title: "إعداد الفواتير أصبح سهلاً للغاية!",
		},
		$process: {
			digital_future: "ابتعد عن الأساليب التقليدية وادخل إلى المستقبل الرقمي",
			digital_shipping: "تتبع الشحنات الرقمية مع Antrego",
			digital_shipping_texts: {
				0: "يتم إنشاء الفواتير المبدئية للطلبات الواردة وتتبعها بسهولة عبر قناة واحدة.",
				1: "يتم إرسال الفواتير المبدئية، ويتم إرسال الإشعارات إلى العميل فورًا، ويتم الموافقة عليها من خلال Antrego.",
				2: "تتم إدارة عمليات المستندات من خلال Antrego. لا تضيع، ولا تحدث تأخيرات، ويتم تقليل احتمالية حدوث أخطاء.",
				3: "يمكن للموردين والعملاء ووكلاء الجمارك رؤية تفاصيل الشحنة وتتبعها بشكل مشترك على الفور.",
				4: "يتم إنشاء التقارير المالية تلقائيًا شهريًا وسنويًا.",
			},
			question_title: "كيف سيغير Antrego حياتك؟",
			tracking_method: "قم برقمنة طرق التتبع الخاصة بك وسرّع عملياتك.",
			traditional_shipping: "تتبع الشحنات التقليدي",
			traditional_shipping_texts: {
				0: "يتم جمع الطلبات عبر البريد الإلكتروني أو الرسائل أو الهاتف من خلال قنوات مختلفة، مما يسبب صعوبات في التتبع الداخلي.",
				1: "يتم إنشاء الفاتورة المبدئية باستخدام أدوات مثل Excel، وتتم عملية الموافقة يدويًا عبر البريد الإلكتروني أو WhatsApp أو الهاتف.",
				2: "تؤدي العمليات اليدوية للمستندات إلى حدوث أخطاء وتأخيرات، مما يتسبب في خسائر في الوقت والمال.",
				3: "لا توجد منصة مشتركة للموردين والعملاء ووكلاء الجمارك لتتبع تفاصيل الشحنة.",
				4: "يتم فحص البيانات المالية بشكل فردي بعد كل شحنة، مما يجعل إعداد التقارير صعبًا.",
			},
		},
		$question: {
			$text: {
				0: "<p>إنه برنامج لتتبع الشحنات يساعد الشركات وعملائها على التتبع المشترك، مما يمنع الخسائر الناتجة عن التأخيرات في مرحلة التتبع.</p>",
				1: "<p>- شارك جميع تحديثاتك في الوقت الفعلي مع عميلك. حسن جودة خدماتك.<br>\n      - عرض ومشاركة جميع الوثائق المتعلقة بشحنتك في نظام واحد.<br>\n      - تتبع جميع شحناتك من مكان واحد. تحكم في أموالك من خلال تقارير ديناميكية.</p>",
				2: "<p>- البحر<br>\n      - الجو<br>\n      - البر<br>\n      - القطار</p>",
				3: "<p>من خلال خدماتها، فهي دائمًا في متناول اليد لتسهيل عملية الشحن الخاصة بك.<br>\n      <strong>1. سهولة الوصول</strong><br>\n      الوصول من جميع الأجهزة والمواقع عبر متصفح الويب.<br>\n      <strong>2. أمان البيانات</strong><br>\n      يتم تخزين جميع البيانات باستخدام تقنية التشفير SHA-256.<br>\n      <strong>3. التتبع المشترك</strong><br>\n      يوفر فرص التتبع لجميع أصحاب المصلحة في العملية.</strong>",
				4: "<p>نعم، إنه تطبيق مدفوع. من خلال رقمنة جميع عمليات الشحن الخاصة بك، نريد تسهيل عملك ومواصلة تقديم التطورات المفيدة لك. لضمان استدامة هذه التحسينات ومساحة الخادم التي تستخدمها، نفرض رسومًا شهرية.</p>",
				5: "<p>لا. لا يحتاج عملاؤك إلى دفع أي رسوم لتتبع الشحنات التي تقوم بها.</p>",
			},
			$title: {
				0: "ما هو Antrego؟",
				1: "ماذا سيقدم Antrego لشركتي خلال عملية الشحن؟",
				2: "ما أنواع الخدمات اللوجستية التي يدعمها Antrego؟",
				3: "لماذا يجب علي استخدام Antrego؟",
				4: "هل Antrego تطبيق مدفوع؟",
				5: "هل يحتاج عملائي إلى دفع مقابل Antrego؟",
			},
			save_percentage: "وفر 16٪ شهريًا",
			text: "يمكنك الوصول إلى جميع المعلومات بسهولة باستخدام Antrego، ولا تتردد في الاتصال بنا لأي استفسارات أخرى.",
			title: "تعلم بسرعة كل ما يثير فضولك",
		},
		cancel_anytime: "إلغاء في أي وقت",
		customize_package: "يمكنك شراء مساحة تخزين إضافية ومستخدمين وتخصيص حزمة الخدمة وفقًا لاحتياجاتك",
		free_features_1: "10 شحنات",
		free_features_2: "100 ميجابايت تخزين",
		free_features_3: "شركاء تجاريين غير محدودين",
		free_features_4: "1 مستخدم",
		free_plan: "مجاني",
		free_price: "0$",
		free_regeneration: "",
		free_title: "شهريًا",
		premium_annual_features_1: "20 مستخدمين",
		premium_annual_features_2: "100 شريك",
		premium_annual_features_3: "1000 شحنات",
		premium_annual_features_4: "1000 فواتير",
		premium_annual_price: "83.25$",
		premium_annual_regeneration: "سيتم التجديد سنويًا مقابل 999$",
		premium_annual_title: "سنويًا",
		premium_monthly_features_1: "شحنات غير محدودة",
		premium_monthly_features_2: "شركاء تجاريين غير محدودين",
		premium_monthly_features_3: "2 جيجابايت تخزين",
		premium_monthly_features_4: "3 مستخدمين",
		premium_monthly_price: "99$",
		premium_monthly_regeneration: "سيتم التجديد شهريًا مقابل 99$",
		premium_monthly_title: "شهريًا",
		premium_plan: "خطة بريميوم",
		premium_plus_annual_features_1: "شحنات غير محدودة",
		premium_plus_annual_features_2: "شركاء تجاريين غير محدودين",
		premium_plus_annual_features_3: "5 جيجابايت تخزين",
		premium_plus_annual_features_4: "5 مستخدمين",
		premium_plus_annual_price: "83.25$",
		premium_plus_annual_regeneration: "سيتم التجديد سنويًا مقابل 999$",
		premium_plus_annual_title: "سنويًا",
		premium_plus_monthly_features_1: "شحنات غير محدودة",
		premium_plus_monthly_features_2: "شركاء تجاريين غير محدودين",
		premium_plus_monthly_features_3: "5 جيجابايت تخزين",
		premium_plus_monthly_features_4: "5 مستخدمين",
		premium_plus_monthly_price: "249$",
		premium_plus_monthly_regeneration: "سيتم التجديد شهريًا مقابل 249$",
		premium_plus_monthly_title: "شهريًا",
		premium_plus_plan: "بريميوم بلس",
		pricing_text: "يمكننا تلبية احتياجاتك بأفضل طريقة دون تجاوز ميزانيتك.",
		pricing_title: "تضمن Antrego تقديم أفضل حزمة وسعر لك.",
		save_percentage: "وفر 16٪ شهريًا",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "العربية",
		de: "الألمانية",
		en: "الإنجليزية",
		es: "الإسبانية",
		fr: "الفرنسية",
		hi: "الهندية",
		ind: "الإندونيسية",
		it: "الإيطالية",
		ja: "اليابانية",
		ko: "الكورية",
		pt: "البرتغالية",
		ru: "الروسية",
		tr: "التركية",
		vi: "الفيتنامية",
		zh: "الصينية",
	},
	$message: {
		cannot_delete_verified_partner: "لا يمكنك حذف شريك معتمد.",
		confirm: "هل أنت متأكد من {function}؟",
		created: "تم إنشاء {item}.",
		deleted: "تم حذف {item}.",
		error_on_view_file: "حدث خطأ أثناء عرض الملف.",
		file_oversize: "يجب أن يكون حجم الملف كحد أقصى {size}.",
		invalid: "{item} غير صالح",
		migrated: "تم نقل {item}. ({value})",
		process_has_been_started: "تم بدء {item}.",
		sent: "تم إرسال {item}.",
		sent_email: "تم إرسال البريد الإلكتروني. ({value})",
		sign_in_failed: "البريد الإلكتروني أو كلمة المرور غير صحيحة",
		unsupported_file_type: "نوع الملف غير مدعوم",
		updated: "تم تحديث {item}.",
	},
	$pageTitles: {
		accept_invitation: "قبول الدعوة",
		company_profile: "ملف الشركة",
		home: "الرئيسية",
		landing_page: " تتبع رقمي سلس، تحكم مشتر",
		partners: "الشركاء",
		pdf_editor: "محرر PDF",
		product_groups: "مجموعات المنتجات",
		products: "المنتجات",
		proforma_invoices: "الفواتير المبدئية",
		shipment_details: "تفاصيل الشحنة",
		shipments: "الشحنات",
		sign_in: "تسجيل الدخول",
		sign_up: "التسجيل",
		subscription: "الاشتراك",
		user_profile: "ملف المستخدم",
	},
	$partnershipStatusTooltip: {
		createdHereDescription: "ليسوا شركاء مسجلين، لقد أنشأت ملفهم الشخصي على منصتنا، لذلك يمكنك عرض وتعديل معلوماتهم.",
		createdHereTitle: "تم إنشاؤه هنا:",
		description: "يمكنك متابعة حالة علاقتك مع شركائك. إليك الفروقات:",
		verifiedPartnersDescription: "هؤلاء الشركاء قبلوا دعوتك وسجلوا في المنصة. معلوماتهم للعرض فقط ولا يمكن تعديلها.",
		verifiedPartnersTitle: "الشركاء المعتمدون:",
	},
	$productGroup: {
		add_child: "إضافة فرع",
		add_new_product_group: "إضافة مجموعة منتجات جديدة",
		add_to_root: "إضافة إلى الجذر",
		has_children_fail_message: "لا يمكن حذف مجموعة تحتوي على فروع",
		initial_message: "استخدم هذا {0} الزر لإنشاء أول مجموعة منتجات.",
		name: "الاسم",
		number_of_product: "عدد المنتجات",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "تمت الموافقة بواسطة {name}.",
			create: "تم الإنشاء بواسطة {name}.",
			create_attachment: "تم إنشاء مرفق بواسطة {name}.",
			delete_attachment: "تم حذف المرفق بواسطة {name}.",
			demand_revise: "طلب المراجعة بواسطة {name}.",
			mark_as_processed: "تم تحديده على أنه تمت معالجته بواسطة {name}.",
			reject: "تم الرفض بواسطة {name}.",
			revoke: "تم الإلغاء بواسطة {name}.",
			send: "تم الإرسال بواسطة {name} إلى المستلم للتقييم.",
			update: "تم التحديث بواسطة {name}.",
			update_attachment: "تم تحديث المرفق بواسطة {name}.",
		},
		$actions: {
			approve: "الموافقة",
			demand_revise: "طلب المراجعة",
			mark_as_approved: "تمت الموافقة",
			mark_as_processed: "تمت المعالجة",
			mark_as_rejected: "مرفوض",
			mark_as_revise_demanded: "مراجعة مطلوبة",
			mark_as_revoked: "تم الإلغاء",
			mark_as_sent: "تم الإرسال",
			reject: "الرفض",
			revoke: "الإلغاء",
			send: "الإرسال",
		},
		$status: {
			approved: "تمت الموافقة",
			pending_approval: "قيد الموافقة",
			pending_revise: "قيد المراجعة",
			processed: "تمت المعالجة",
			rejected: "مرفوض",
			revoked: "تم الإلغاء",
			template: "نموذج",
		},
		approve_confirm_message: "هل أنت متأكد من الموافقة على الفاتورة المبدئية؟",
		approve_success_message: "تمت الموافقة على الفاتورة المبدئية.",
		demand_revise_description_message: "يرجى تقديم بعض التفاصيل حول توقعاتك للمراجعة:",
		demand_revise_success_message: "تم إعادة الفاتورة المبدئية إلى المرسل للمراجعة.",
		mark_as_processed_confirm_message: "هل تؤكد تحديد الفاتورة المبدئية كمعالجة؟",
		mark_as_processed_success_message: "تم تحديد الفاتورة المبدئية كمعالجة.",
		no_attachment_text: "لم تتم إضافة أي مرفق بعد.",
		reject_confirm_message: "هل أنت متأكد من رفض الفاتورة المبدئية؟",
		reject_success_message: "تم رفض الفاتورة المبدئية.",
		revoke_confirm_message: "هل أنت متأكد من إلغاء الفاتورة المبدئية؟",
		revoke_success_message: "تم إلغاء الفاتورة المبدئية.",
		send_confirm_message: "هل تؤكد إرسال الفاتورة المبدئية إلى المستلم؟",
		send_success_message: "تم إرسال الفاتورة المبدئية.",
	},
	$serviceMessage: {
		already_subs_module: "يوجد بالفعل اشتراك لهذه الوحدة.",
		bad_request: "طلب غير صالح",
		code_already_used: "تم استخدام الكود بالفعل.",
		container_status_not_available: "حالة الحاوية غير متاحة.",
		existing_email_warning: "البريد الإلكتروني موجود بالفعل.",
		existing_employee_error: "تمت إضافة هذا المستخدم بالفعل إلى شركتك.",
		existing_employee_for_email_warning: "تم إرسال كود الدعوة إلى هذا البريد الإلكتروني بالفعل",
		existing_invitation_warning: "تم إرسال كود الدعوة إلى هذا البريد الإلكتروني بالفعل.",
		existing_token_already_have_time: "الرمز الحالي يحتوي بالفعل على وقت كافٍ للاستخدام.",
		existing_user: "المستخدم موجود بالفعل.",
		five_min_reset_password_mail: "يجب عليك الانتظار لمدة 5 دقائق على الأقل بعد طلب إعادة تعيين كلمة المرور.",
		forbidden: "ممنوع",
		have_no_corporation: "ليس لديك مؤسسة",
		have_no_custom_agency: "ليس لديك وكالة جمارك",
		have_no_employee: "ليس لديك موظف",
		instance_can_no_delete_existing_booking_code: "لا يمكن حذف الكيان بسبب وجود كود حجز.",
		instance_cannot_delete: "لا يمكن حذف الكيان.",
		instance_not_found: "لم يتم العثور على الكيان.",
		internal_server_error: "خطأ في الخادم الداخلي",
		invalid_current_password: "كلمة المرور الحالية غير صالحة.",
		invalid_identifier_or_password: "معرف أو كلمة مرور غير صالحة",
		invalid_invitation_code: "كود الدعوة غير صحيح.",
		invalid_moving_request: "طلب التنقل غير صالح",
		invalid_operation: "عملية غير صالحة",
		invalid_refresh_token: "رمز التحديث غير صالح.",
		invalid_unit: "وحدة غير صالحة",
		invitation_accepting_is_due_to_the_admin: "قبول الدعوة يعود إلى المسؤول.",
		invitation_already_discarded: "تم تجاهل الدعوة بالفعل.",
		invitation_already_used: "تم استخدام الدعوة بالفعل.",
		invitation_has_been_used: "تم استخدام الدعوة.",
		invitation_not_exist_email: "الدعوة لا تحتوي على البريد الإلكتروني.",
		invitation_not_for_company: "هذه الدعوة ليست لشركة.",
		invitation_not_for_custom_agency: "هذه الدعوة ليست لوكالة الجمارك.",
		invitation_not_found: "لم يتم العثور على الدعوة.",
		invitation_not_valid: "الدعوة لم تعد صالحة.",
		link_has_been_expired: "انتهت صلاحية الرابط.",
		link_not_be_used_anymore: "قد لا يمكن استخدام هذا الرابط بعد الآن",
		missing_required_fields: "الحقول المطلوبة مفقودة.",
		no_employee_found: "لم يتم العثور على موظف",
		no_proper_partner_found: "لم يتم العثور على شريك مناسب.",
		not_acceptable: "غير مقبول",
		not_allowed_action: "غير مسموح لك بتنفيذ هذا الإجراء.",
		not_allowed_use_api: "غير مسموح لك باستخدام هذه الواجهة.",
		not_found: "غير موجود",
		not_have_purchase_subscription:
			"لقد وصل اشتراكك إلى الحد الأقصى من التعريفات. للتمكن من إضافة المزيد، يرجى ترقية خطة اشتراكك",
		not_permitted_use_api: "غير مسموح لك باستخدام هذه الواجهة.",
		own_invitation_cannot_accept: "لا يمكنك قبول دعوتك الخاصة.",
		parent_group_not_found: "لم يتم العثور على المجموعة الأصلية",
		partnerId_required: "مطلوب معرف الشريك.",
		partnership_already_exists: "تمت إضافة الشريك بالفعل.",
		password_cant_involve_space_char: "لا يمكن أن تحتوي كلمة المرور على مسافة.",
		password_min_chars_least: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل.",
		payment_required: "يرجى الاشتراك في هذه العملية.",
		paytr_integration_error: "حدثت مشكلة في بنية الدفع. سيتم حلها في أقرب وقت ممكن.",
		ports_are_required: "الموانئ مطلوبة.",
		product_group_tree_not_found: "لم يتم العثور على شجرة مجموعة المنتجات",
		proforma_invoice_action_not_available: "حالة الفاتورة المبدئية غير متاحة لهذا الإجراء.",
		proforma_invoice_template_update: "يجب أن تكون الفاتورة المبدئية نموذجًا ليتم تحديثها.",
		shipment_email_not_exist: "يرجى إدخال بريد إلكتروني صالح.",
		shipment_method_is_required: "طريقة الشحن مطلوبة.",
		shipping_term_is_required: "شروط الشحن مطلوبة.",
		someone_else_uses_this_email: "شخص آخر يستخدم هذا البريد الإلكتروني.",
		someone_else_uses_this_username: "شخص آخر يستخدم هذا الاسم المستخدم.",
		something_went_wrong: "حدث خطأ ما",
		type_can_be_abstract_company: '"type" يمكن أن يكون فقط "abstract_company"',
		unauthorized: "غير مصرح",
		undefined_result: "نتيجة غير معرفة",
		unexpected_error_occurred: "حدث خطأ غير متوقع",
		unit_already_added: "تمت إضافة الوحدة بالفعل.",
		user_already_employee: "المستخدم هو بالفعل موظف.",
		user_already_exists: "المستخدم موجود بالفعل في شركة.",
		user_does_not_exist: "المستخدم غير موجود.",
		user_not_found: "لم يتم العثور على المستخدم.",
		username_min_chars_error: '"username" يجب أن يكون 6 أحرف على الأقل.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "تمت إضافة مركبة بواسطة {name}.",
				delete_container: "تم حذف مركبة بواسطة {name}.",
				update_container: "تم تحديث مركبة بواسطة {name}.",
			},
			create: "تم الإنشاء بواسطة {name}.",
			create_attachment: "تمت إضافة مرفق بواسطة {name}.",
			create_container: "تمت إضافة حاوية بواسطة {name}.",
			delete_attachment: "تم حذف مرفق بواسطة {name}.",
			delete_container: "تم حذف حاوية بواسطة {name}.",
			update: "تم التحديث بواسطة {name}.",
			update_attachment: "تم تحديث مرفق بواسطة {name}.",
			update_container: "تم تحديث حاوية بواسطة {name}.",
		},
		$containerStatus: {
			arrived: "وصلت",
			off_load: "تم التفريغ",
			on_load: "محملة",
			on_way: "في الطريق",
			reserved: "محجوزة",
		},
		$method: {
			aerial: "جوي",
			land: "بري",
			railway: "سكة حديدية",
			sea: "بحري",
		},
		$vehicle: {
			license_number: "رقم اللوحة",
		},
		archiving_success_message: "تم أرشفة الشحنة.",
		leave_message: "اترك رسالتك أو تعليقك",
		no_booking_info: "لم يتم تعيين معلومات الحجز حتى الآن. استخدم {0} الزر لإضافة.",
		no_transportation_info: "لم يتم تعيين معلومات التفاصيل حتى الآن. استخدم {0} الزر للتعيين.",
		un_archiving_success_message: "تم إلغاء أرشفة الشحنة.",
	},
	$shortening: {
		number: "رقم",
	},
	$socialMedia: {
		facebook: "فيسبوك",
		instagram: "انستغرام",
		linked_in: "لينكدإن",
		twitter: "تويتر",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "لم يتم تجديد اشتراكك. يرجى التحقق من طريقة الدفع الخاصة بك.",
			fail: "حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى لاحقًا. إذا استمرت المشكلة، يرجى الإبلاغ عن ذلك عبر إرسال بريد إلكتروني إلى info@antrego.net.",
			ok: "تم بدء عملية الشراء. سيتم إخطارك بنتيجة العملية عبر البريد الإلكتروني. سيتم بدء اشتراكك عند اكتمال الدفع بنجاح.",
			payment_process_is_ongoing:
				"عملية الدفع جارية. سيتم إخطارك بنتيجة العملية عبر البريد الإلكتروني. سيتم بدء اشتراكك عند اكتمال الدفع بنجاح.",
			success: "تم إنشاء اشتراكك بنجاح. يمكنك البدء في استخدام Antrego بسهولة.",
			unsubscribed: "تم إلغاء الاشتراك",
			you_dont_have_any_subscription:
				"ليس لديك أي اشتراكات. يرجى اختيار حزمة من صفحة الاشتراك والبدء في استخدامها بسهولة.",
			you_dont_have_any_subscription_text: "اختر خطة الاشتراك الأنسب لك واستفد من جميع الفرص المتاحة.",
			you_dont_have_any_subscription_title: "حقق المزيد مع خططنا",
		},
	},
	$unit: {
		acres: "فدان",
		boxes: "صناديق",
		cubic_meter: "متر مكعب",
		dozen: "دزينة",
		feet: "قدم",
		fluid_ounces: "أوقية سائلة",
		gallons: "جالونات",
		gram: "غرام",
		hectare: "هكتار",
		inches: "بوصة",
		kilogram: "كيلوغرام",
		kilometer: "كيلومتر",
		liter: "لتر",
		meter: "متر",
		miles: "أميال",
		milligram: "ملليغرام",
		milliliter: "ملليلتر",
		millimeter: "ملليمتر",
		ounces: "أوقية",
		pallets: "منصات",
		pieces: "قطع",
		pounds: "رطل",
		quarts: "كوارتر",
		square_feet: "قدم مربع",
		square_inches: "بوصة مربعة",
		square_kilometer: "كيلومتر مربع",
		square_meter: "متر مربع",
		square_miles: "أميال مربعة",
		square_yards: "ياردة مربعة",
		ton: "طن",
		yards: "ياردات",
	},
	$validation: {
		email: "يرجى إدخال بريد إلكتروني صالح",
		invalid: "{item} غير صالح",
		max_length: "{item} يجب أن يكون أقل من {length} حرفًا",
		min_length: "{item} يجب أن يكون أطول من {length} حرفًا",
		must_length: "{item} يجب أن يكون {length} حرفًا",
		only_number: "{item} يجب أن يكون رقمًا",
		password_repeat_warning: "إعادة كلمة المرور ليست مثل كلمة المرور الجديدة.",
		required: "{item} مطلوب",
		required_for_default_language: "{item} مطلوب للغة الافتراضية للشركة.",
		you_must_approve_distance_sales_agreement: "يجب الموافقة على عقد المبيعات عن بُعد",
	},
	about: "حول",
	accept_invitation: "قبول الدعوة",
	accept_invitation_success_message: "تم استخدام الدعوة بنجاح.",
	accessibility: "الوصول",
	actions: "الإجراءات",
	active: "نشط",
	activities: "الأنشطة",
	add: "إضافة",
	add_item: "إضافة {item}",
	add_new_employee: "إضافة موظف جديد",
	add_new_product: "إضافة منتج جديد",
	add_template: "إضافة قالب",
	address: "العنوان",
	again: "مرة أخرى",
	alert: "تنبيه",
	already_have_employee_message: "أنت بالفعل موظف في شركة.",
	amount: "المبلغ",
	approve: "الموافقة",
	archive: "أرشفة",
	attachment: "المرفق",
	attachments: "المرفقات",
	auto_attached_pdf: "ملف PDF المرفق تلقائيًا",
	bank: "البنك",
	benefits: "الفوائد",
	billed_to: "فاتورة إلى",
	booking: "الحجز",
	booking_code: "رمز الحجز",
	booking_details: "تفاصيل الحجز",
	business_partner: "شريك تجاري",
	business_partners: "الشركاء التجاريون",
	cancel: "إلغاء",
	change_password: "تغيير كلمة المرور",
	checking_invitation_code_message: "جارٍ التحقق من كود الدعوة",
	choose_image: "اختر صورة",
	choose_your_plan: "اختر خطتك",
	clear: "مسح",
	click_to_play_the_video: "انقر لتشغيل الفيديو",
	close: "إغلاق",
	code: "الكود",
	commercial: "تجاري",
	commercial_partner: "شريك تجاري",
	commercial_partners: "الشركاء التجاريون",
	companies: "الشركات",
	company: "الشركة",
	company_address: "عنوان الشركة",
	company_name: "اسم الشركة",
	company_profile: "ملف الشركة",
	configuration: "التكوين",
	confirm: "تأكيد",
	confirm_distance_selling_agreement: "تأكيد عقد المبيعات عن بُعد.",
	consult_us: "استشرنا",
	contact: "اتصل",
	contact_info: "معلومات الاتصال",
	contact_text: "اطمئن، سوف نتواصل معك في أقرب وقت ممكن.",
	contact_title: "استشرنا، وسهل عملياتك",
	contact_to_us: "اتصل بنا",
	container: "الحاوية",
	container_no: "رقم الحاوية",
	containers: "الحاويات",
	content: "المحتوى",
	contract: "العقد",
	copy_link: "نسخ الرابط",
	corporation: "شركة",
	countries: "البلدان",
	country: "البلد",
	create: "إنشاء",
	create_a_new_account: "إنشاء حساب جديد",
	create_company: "إنشاء شركة",
	create_document: "إنشاء مستند",
	created_at: "تم الإنشاء في",
	created_here: "تم إنشاؤه هنا",
	credit_card_info: "معلومات بطاقة الائتمان",
	credit_card_no: "رقم بطاقة الائتمان",
	currencies: "العملات",
	currency: "العملة",
	current_password: "كلمة المرور الحالية",
	current_plan: "الخطة الحالية",
	customer_company_address: "عنوان شركة العميل",
	customer_company_name: "اسم شركة العميل",
	customs: "الجمارك",
	customs_agencies: "وكالات الجمارك",
	customs_agency: "وكالة الجمارك",
	cvv: "CVV",
	daily: "يومي",
	dashboard: "لوحة التحكم",
	date: "التاريخ",
	day: "يوم",
	default: "افتراضي",
	default_language: "اللغة الافتراضية",
	delete: "حذف",
	delete_account: "حذف الحساب",
	delete_confirm_message: "هل تؤكد الحذف؟",
	delete_document: "حذف المستند",
	delete_template: "حذف القالب",
	description: "الوصف",
	detail: "التفاصيل",
	discard: "تجاهل",
	distance_sales_agreement: "عقد المبيعات عن بُعد",
	document: "مستند",
	document_actions: "إجراءات المستند",
	document_added: "تمت إضافة الوثيقة",
	document_manager: "مدير المستندات",
	document_name: "اسم المستند",
	document_number: "رقم المستند",
	document_overwrite: "احفظ التعديلات كمستند جديد",
	document_save_info: "سيتم حفظ هذا المستند داخل هذه الفاتورة الأولية فقط.",
	document_updated: "تم تحديث الوثيقة",
	download: "تنزيل",
	download_pdf: "تحميل PDF",
	edit: "تعديل",
	edit_document: "تحديث المستند",
	edit_item: "تعديل {item}",
	edit_product: "تعديل المنتج",
	edit_profile: "تعديل الملف الشخصي",
	edit_template: "تحديث القالب",
	edit_your_profile_picture: "عدل صورتك الشخصية",
	email: "البريد الإلكتروني",
	employee: "الموظف",
	employee_type: "نوع الموظف",
	employees: "الموظفون",
	empty_data_warning: "لا توجد بيانات في هذا المجال حتى الآن. يمكنك البدء في استكشاف المنصة باستخدام بيانات نموذجية.",
	estimated_arrival_day: "يوم الوصول المتوقع",
	expense: "النفقات",
	expenses: "النفقات",
	expires_at: "ينتهي في",
	exported_containers: "الحاويات المصدرة",
	fail: "فشل",
	failed: "فشل",
	faq: "الأسئلة الشائعة",
	feedback: "التغذية الراجعة",
	file: "الملف",
	file_name: "اسم الملف",
	file_preview: "معاينة الملف",
	file_view: "عرض الملف",
	filter: "التصفية",
	first_name: "الاسم الأول",
	first_name_last_name: "الاسم الأول واللقب",
	forget_password: "نسيت كلمة المرور",
	forget_password_message: "هل نسيت كلمة المرور الخاصة بك؟",
	forget_password_subtitle: "يرجى إدخال عنوان بريدك الإلكتروني لإعادة تعيين كلمة المرور.",
	free: "مجاني",
	from: "من",
	general: "افتراضي",
	get_started_now: "ابدأ الآن",
	give_us_feedback: "أرسل لنا ملاحظاتك",
	go_to_panel: "الذهاب إلى لوحة التحكم",
	hide: "إخفاء",
	home_page: "الصفحة الرئيسية",
	i_have_invitation_code: "لدي كود دعوة",
	imported_containers: "الحاويات المستوردة",
	in_progress: "جاري التنفيذ",
	incoming: "الوارد",
	incoming_shipments: "الشحنات الواردة",
	initial: "البدء",
	inputs: "المحتوى",
	invitation: "الدعوة",
	invitation_code: "كود الدعوة",
	invitation_mail_sent: "تم إرسال دعوة عبر البريد الإلكتروني",
	invitations: "الدعوات",
	invoice: "فاتورة",
	invoice_no: "رقم الفاتورة",
	join: "انضم",
	join_company: "انضم إلى الشركة",
	language: "اللغة",
	language_options: "خيارات اللغة",
	last_name: "اللقب",
	last_one_year: "آخر سنة",
	latitude: "خط العرض",
	left_a_comment: "ترك تعليقًا",
	link_copied: "تم نسخ الرابط",
	link_copy_failed: " فشل نسخ الرابط",
	loading_country: "بلد التحميل",
	loading_point: "نقطة التحميل",
	loading_port: "ميناء التحميل",
	log_in: "تسجيل الدخول",
	logo: "الشعار",
	longitude: "خط الطول",
	mail_sent: "تم إرسال البريد الإلكتروني",
	manage_profile: "إدارة ملفك في Antrego",
	message: "الرسالة",
	message_company_create: "تم إنشاء الشركة.",
	message_company_join: "يرجى إدخال رمز الدعوة.",
	message_company_joined: "لقد انضممت إلى الشركة",
	message_employee_delete: "تم حذف الموظف.",
	message_employee_save: "تم حفظ الموظف.",
	message_join_company: "أدخل رمزك للانضمام إلى شركة موجودة",
	method: "الطريقة",
	migrate: "نقل",
	migrate_partner: "نقل شركة",
	mobile_number: "رقم الهاتف المحمول",
	month: "الشهر",
	month_lowercase: "شهر",
	monthly: "شهريًا",
	my_profile: "ملفي الشخصي",
	my_role: "دوري",
	name: "الاسم",
	new: "جديد",
	new_company_package: "مجاني",
	new_password: "كلمة المرور الجديدة",
	new_shipment: "شحنة جديدة",
	next: "التالي",
	no: "لا",
	no_content: "لا يوجد محتوى",
	no_entity: "لا يوجد {entityName} حتى الآن",
	no_entity_message: "قم بإنشاء {entityName} وسيتم إدراج التفاصيل هنا",
	no_text: "لا يوجد نص",
	not_found_page_message: "عذرًا، لا يمكنني العثور على هذه الصفحة :(",
	note: "ملاحظة",
	notes: "ملاحظات",
	number_of_rows: "عدد الصفوف",
	ok: "موافق",
	operation_fail: "فشل العملية",
	outgoing: "الصادر",
	outgoing_shipments: "الشحنات الصادرة",
	packages: "الحزم",
	packing_list_title: "قائمة التعبئة",
	parent_group: "المجموعة الأم",
	partner: "الشريك",
	partners: "الشركاء",
	partnership_status: "حالة الشراكة",
	passive: "غير نشط",
	password: "كلمة المرور",
	pay: "الدفع",
	payback: "الاسترداد",
	payback_confirm_message: "هل تؤكد الاسترداد؟",
	payback_warning_message: "إذا تابعت، سيتم إنهاء اشتراكك.",
	payment: "الدفع",
	payment_amount: "مبلغ الدفع",
	payment_awaited: "في انتظار الدفع",
	payment_history: "سجل الدفع",
	payment_info: "معلومات الدفع",
	payment_information: "معلومات الدفع",
	payment_success: "تم الدفع بنجاح",
	payment_summary: "ملخص الدفع",
	payment_will_be_made_3d_secure: "*سيتم الدفع باستخدام 3D Secure.",
	pdf_attached_message: "تم إرفاق ملف PDF بنجاح",
	pdf_editor: "محرر PDF",
	pdf_name: "اسم PDF",
	pending_invitations: "الدعوات المعلقة",
	per_month: "شهريًا",
	phone_number: "رقم الهاتف",
	plate_numbers: "أرقام اللوحات",
	premium: "بريميوم",
	premium_plus: "بريميوم بلس",
	preview: "معاينة",
	previous: "السابق",
	pricing: "التسعير",
	process: "العملية",
	product: "المنتج",
	product_group: "مجموعة المنتجات",
	product_group_name: "اسم مجموعة المنتجات",
	product_groups: "مجموعات المنتجات",
	product_name: "اسم المنتج",
	products: "المنتجات",
	profile: "الملف الشخصي",
	proforma_invoice: "الفاتورة المبدئية",
	proforma_invoices: "الفواتير المبدئية",
	proforma_number: "رقم الفاتورة المبدئية",
	proforma_title: "فاتورة أولية",
	purchase: "الشراء",
	quantity: "الكمية",
	receiver: "المستلم",
	receiver_company: "شركة المستلم",
	receiver_customs_agency: "وكالة الجمارك المستلم",
	redirection_to_home_link: "الصفحة الرئيسية",
	redirection_to_home_message: "يمكنك أيضًا الانتقال إلى ",
	redirection_to_sign_in_message: "هل تملك حسابًا؟",
	redirection_to_sign_up_message: "هل لا تملك حسابًا حتى الآن؟",
	reload: "إعادة التحميل",
	renewal: "التجديد",
	repaid: "تم السداد",
	reset_password: "إعادة تعيين كلمة المرور",
	rows_per_page: "الصفوف لكل صفحة",
	sales: "المبيعات",
	save: "حفظ",
	save_as_document: "حفظ كمستند",
	save_as_new_document: "حفظ كمستند جديد",
	save_as_new_template: "حفظ كقالب جديد",
	save_as_template: "حفظ كقالب",
	save_changes: "حفظ التغييرات",
	save_pdf_to_attachments: "حفظ PDF في المرفقات",
	saved_card_info: "معلومات البطاقة المحفوظة",
	search: "بحث",
	select: "اختر",
	select_document: "اختر الوثيقة",
	select_document_to_overwrite: "حدد المستند للكتابة فوقه",
	select_file: "اختر ملف",
	select_file_place_holder: "يرجى اختيار صورة",
	select_language: "اختر اللغة",
	select_template: "اختر القالب",
	select_template_to_overwrite: "حدد القالب للكتابة فوقه",
	selected_partner: "الشريك المختار",
	send: "إرسال",
	send_again: "إعادة الإرسال",
	send_email: "إرسال بريد إلكتروني",
	send_feedback: "إرسال ملاحظات",
	send_invitation: "إرسال دعوة",
	sender: "المرسل",
	sender_company: "شركة المرسل",
	sender_customs_agency: "وكالة الجمارك المرسل",
	settings: "الإعدادات",
	shipment: "الشحنة",
	shipment_method: "طريقة الشحن",
	shipment_type: "نوع الشحن",
	shipments: "الشحنات",
	shipments_filter: "تصفية الشحنات",
	shipping_term: "شروط الشحن",
	shipping_terms: "شروط الشحن",
	show: "عرض",
	sign_in: "تسجيل الدخول",
	sign_out: "تسجيل الخروج",
	sign_out_confirm_message: "هل تؤكد تسجيل الخروج؟",
	sign_out_success_message: "وداعًا.",
	sign_up: "التسجيل",
	social_media: "وسائل التواصل الاجتماعي",
	social_media_account: "حساب وسائل التواصل الاجتماعي",
	start_time: "وقت البدء",
	status: "الحالة",
	subscribe: "اشترك",
	subscription: "الاشتراك",
	subscription_info_message: "يمكنك الاستفادة من جميع الخدمات بشكل غير محدود خلال فترة الاشتراك.",
	subscription_warning_message: "**سيتم تخزين معلومات بطاقتك بشكل آمن وسيتم تجديد اشتراكك بانتظام ما لم تقم بإلغائه.",
	success: "نجاح",
	super: "ممتاز",
	supported_files_img: "الملفات المدعومة: ملفات JPG و PNG أقل من 20 ميجابايت",
	switch_language: "تبديل اللغة",
	switch_theme: "تبديل السمة",
	table_inputs: "محتوى الجدول",
	target: "الهدف",
	target_partner: "الشريك المستهدف",
	tax_number: "رقم الضريبة",
	template: "قالب",
	template_actions: "إجراءات القالب",
	template_added: "تمت إضافة القالب",
	template_name: "اسم القالب",
	template_overwrite: "احفظ التعديلات كقالب جديد",
	template_save_info: "سيتم عرض القوالب المحفوظة لاحقًا لاستخدامها في الفواتير الأولية والشحنات.",
	template_updated: "تم تحديث القالب",
	terms_of_sale_and_other_comments: "شروط البيع - تعليقات",
	theme: "السمة",
	this_month: "هذا الشهر",
	this_year: "هذا العام",
	title: "العنوان",
	to: "إلى",
	toDelete: "حذف",
	total: "الإجمالي",
	total_amount: "المبلغ الإجمالي",
	transaction_no: "رقم المعاملة",
	transactions: "المعاملات",
	try_for_free: "جرّب مجانًا",
	type: "النوع",
	types: "الأنواع",
	un_archive: "إلغاء الأرشفة",
	unit: "الوحدة",
	units: "الوحدات",
	unloading_country: "بلد التفريغ",
	unloading_point: "نقطة التفريغ",
	unloading_port: "ميناء التفريغ",
	unsubscribe: "إلغاء الاشتراك",
	unsubscribe_confirm_message:
		"إذا قمت بإلغاء الاشتراك، فلن تتمكن من استخدام ميزات النظام بشكل فعال. هل أنت متأكد أنك تريد إلغاء الاشتراك؟",
	upload_new_image: "رفع صورة جديدة",
	use_code: "استخدام الكود",
	use_invitation_code: "استخدام كود الدعوة",
	use_invitation_code_description: "أدخل كود الدعوة الذي أرسل إلى بريدك.",
	use_invitation_code_success_message: "تم استخدام كود الدعوة بنجاح.",
	user_profile: "ملفي الشخصي",
	vehicle: "المركبة",
	vehicles: "المركبات",
	verified_partner: "شريك معتمد",
	verify_password: "تأكيد كلمة المرور",
	visibility: "الرؤية",
	warehouse: "المستودع",
	warehouses: "المستودعات",
	website: "الموقع الإلكتروني",
	week: "أسبوع",
	welcome: "مرحبًا",
	what_we_are_doing: "ما الذي نفعله",
	year: "عام",
	year_lowercase: "سنة",
	yearly: "سنوياً",
	yes: "نعم",
	you_dont_have_any_active_subscription: "ليس لديك أي اشتراك نشط",
};
