export default {
	$accessibility: {
		archived: "Archivé",
		present: "Présent",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "Caché du récepteur",
			hidden_from_sender: "Caché de l'expéditeur",
			private: "Privé",
			shared_with_custom: "Partagé avec personnalisé",
			shared_with_everyone: "Partagé avec tout le monde",
			shared_with_receiver: "Partagé avec le récepteur",
			shared_with_sender: "Partagé avec l'expéditeur",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}Go de stockage",
		limitMBStorage: "{limit}Mo de stockage",
		limitUser: "{limit} Utilisateur",
		limitUsers: "{limit} Utilisateurs",
		renewAtPricePerUnit: "Renouvellement à {currency}{price} par {unit}",
		unlimitedModule: "{module} Illimité",
		upToLimitModule: "Jusqu'à {limit} {module}",
	},
	$company: {
		address: "Kotekli Mah. Denizli Yolu Bul. Teknopark Sitesi B Blok No:4B Ic kapi No:14 MENTESE / MUGLA, TURQUIE",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sfr!2str!4v1701805110461!5m2!1sfr!2str",
		name: "Slush Software Inc.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan:
			"Accélérez votre entreprise avec Antrego. Gérez vos processus d'expédition numériquement, en toute sécurité et facilement.",
	},
	$country: {
		afghanistan: "Afghanistan",
		aland: "Åland",
		albania: "Albanie",
		algeria: "Algérie",
		american_samoa: "Samoa américaines",
		andorra: "Andorre",
		angola: "Angola",
		anguilla: "Anguilla",
		antarctica: "Antarctique",
		antigua_and_barbuda: "Antigua-et-Barbuda",
		argentina: "Argentine",
		armenia: "Arménie",
		aruba: "Aruba",
		australia: "Australie",
		austria: "Autriche",
		azerbaijan: "Azerbaïdjan",
		bahamas: "Bahamas",
		bahrain: "Bahreïn",
		bangladesh: "Bangladesh",
		barbados: "Barbade",
		belarus: "Biélorussie",
		belgium: "Belgique",
		belize: "Belize",
		benin: "Bénin",
		bermuda: "Bermudes",
		bhutan: "Bhoutan",
		bolivia: "Bolivie",
		bonaire_sint_eustatius_and_saba: "Bonaire, Saint-Eustache et Saba",
		bosnia_and_herzegovina: "Bosnie-Herzégovine",
		botswana: "Botswana",
		bouvet_island: "Île Bouvet",
		brazil: "Brésil",
		british_indian_ocean_territory: "Territoire britannique de l'océan Indien",
		british_virgin_islands: "Îles Vierges britanniques",
		brunei: "Brunei",
		bulgaria: "Bulgarie",
		burkina_faso: "Burkina Faso",
		burundi: "Burundi",
		cabo_verde: "Cap-Vert",
		cambodia: "Cambodge",
		cameroon: "Cameroun",
		canada: "Canada",
		cayman_islands: "Îles Caïmans",
		central_african_republic: "République Centrafricaine",
		chad: "Tchad",
		chile: "Chili",
		china: "Chine",
		christmas_island: "Île Christmas",
		cocos_islands: "Îles Cocos [Keeling]",
		colombia: "Colombie",
		comoros: "Comores",
		congo_republic: "République du Congo",
		cook_islands: "Îles Cook",
		costa_rica: "Costa Rica",
		croatia: "Croatie",
		cuba: "Cuba",
		curacao: "Curaçao",
		cyprus: "Chypre",
		czechia: "Tchéquie",
		denmark: "Danemark",
		djibouti: "Djibouti",
		dominica: "Dominique",
		dominican_republic: "République Dominicaine",
		dr_congo: "République Démocratique du Congo",
		ecuador: "Équateur",
		egypt: "Égypte",
		el_salvador: "El Salvador",
		equatorial_guinea: "Guinée équatoriale",
		eritrea: "Érythrée",
		estonia: "Estonie",
		eswatini: "Eswatini",
		ethiopia: "Éthiopie",
		falkland_islands: "Îles Falkland",
		faroe_islands: "Îles Féroé",
		fiji: "Fidji",
		finland: "Finlande",
		france: "France",
		french_guiana: "Guyane française",
		french_polynesia: "Polynésie française",
		french_southern_territories: "Terres Australes et Antarctiques Françaises",
		gabon: "Gabon",
		gambia: "Gambie",
		georgia: "Géorgie",
		germany: "Allemagne",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greece: "Grèce",
		greenland: "Groenland",
		grenada: "Grenade",
		guadeloupe: "Guadeloupe",
		guam: "Guam",
		guatemala: "Guatemala",
		guernsey: "Guernesey",
		guinea: "Guinée",
		guinea_bissau: "Guinée-Bissau",
		guyana: "Guyana",
		haiti: "Haïti",
		heard_island_and_mcdonald_islands: "Îles Heard-et-MacDonald",
		honduras: "Honduras",
		hong_kong: "Hong Kong",
		hungary: "Hongrie",
		iceland: "Islande",
		india: "Inde",
		indonesia: "Indonésie",
		iran: "Iran",
		iraq: "Irak",
		ireland: "Irlande",
		isle_of_man: "Île de Man",
		israel: "Israël",
		italy: "Italie",
		ivory_coast: "Côte d'Ivoire",
		jamaica: "Jamaïque",
		japan: "Japon",
		jersey: "Jersey",
		jordan: "Jordanie",
		kazakhstan: "Kazakhstan",
		kenya: "Kenya",
		kiribati: "Kiribati",
		kosovo: "Kosovo",
		kuwait: "Koweït",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonie",
		lebanon: "Liban",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Libye",
		liechtenstein: "Liechtenstein",
		lithuania: "Lituanie",
		luxembourg: "Luxembourg",
		macao: "Macao",
		madagascar: "Madagascar",
		malawi: "Malawi",
		malaysia: "Malaisie",
		maldives: "Maldives",
		mali: "Mali",
		malta: "Malte",
		marshall_islands: "Îles Marshall",
		martinique: "Martinique",
		mauritania: "Mauritanie",
		mauritius: "Île Maurice",
		mayotte: "Mayotte",
		mexico: "Mexique",
		micronesia: "Micronésie",
		moldova: "Moldavie",
		monaco: "Monaco",
		mongolia: "Mongolie",
		montenegro: "Monténégro",
		montserrat: "Montserrat",
		morocco: "Maroc",
		mozambique: "Mozambique",
		myanmar: "Birmanie",
		namibia: "Namibie",
		nauru: "Nauru",
		nepal: "Népal",
		netherlands: "Pays-Bas",
		new_caledonia: "Nouvelle-Calédonie",
		new_zealand: "Nouvelle-Zélande",
		nicaragua: "Nicaragua",
		niger: "Niger",
		nigeria: "Nigeria",
		niue: "Niue",
		norfolk_island: "Île Norfolk",
		north_korea: "Corée du Nord",
		north_macedonia: "Macédoine du Nord",
		northern_mariana_islands: "Îles Mariannes du Nord",
		norway: "Norvège",
		oman: "Oman",
		pakistan: "Pakistan",
		palau: "Palaos",
		palestine: "Palestine",
		panama: "Panama",
		papua_new_guinea: "Papouasie-Nouvelle-Guinée",
		paraguay: "Paraguay",
		peru: "Pérou",
		philippines: "Philippines",
		pitcairn_islands: "Îles Pitcairn",
		poland: "Pologne",
		portugal: "Portugal",
		puerto_rico: "Porto Rico",
		qatar: "Qatar",
		reunion: "La Réunion",
		romania: "Roumanie",
		russia: "Russie",
		rwanda: "Rwanda",
		saint_barthelemy: "Saint-Barthélemy",
		saint_helena: "Sainte-Hélène",
		saint_lucia: "Sainte-Lucie",
		saint_martin: "Saint-Martin",
		saint_pierre_and_miquelon: "Saint-Pierre-et-Miquelon",
		samoa: "Samoa",
		san_marino: "Saint-Marin",
		sao_tome_and_principe: "Sao Tomé-et-Principe",
		saudi_arabia: "Arabie Saoudite",
		senegal: "Sénégal",
		serbia: "Serbie",
		seychelles: "Seychelles",
		sierra_leone: "Sierra Leone",
		singapore: "Singapour",
		sint_maarten: "Saint-Martin",
		slovakia: "Slovaquie",
		slovenia: "Slovénie",
		solomon_islands: "Îles Salomon",
		somalia: "Somalie",
		south_africa: "Afrique du Sud",
		south_georgia_and_south_sandwich_islands: "Géorgie du Sud et Îles Sandwich du Sud",
		south_korea: "Corée du Sud",
		south_sudan: "Soudan du Sud",
		spain: "Espagne",
		sri_lanka: "Sri Lanka",
		st_kitts_and_nevis: "Saint-Kitts-et-Nevis",
		st_vincent_and_grenadines: "Saint-Vincent-et-les-Grenadines",
		sudan: "Soudan",
		suriname: "Suriname",
		svalbard_and_jan_mayen: "Svalbard et Jan Mayen",
		sweden: "Suède",
		switzerland: "Suisse",
		syria: "Syrie",
		taiwan: "Taïwan",
		tajikistan: "Tadjikistan",
		tanzania: "Tanzanie",
		thailand: "Thaïlande",
		timor_leste: "Timor-Leste",
		togo: "Togo",
		tokelau: "Tokelau",
		tonga: "Tonga",
		trinidad_and_tobago: "Trinité-et-Tobago",
		tunisia: "Tunisie",
		turkiye: "Turquie",
		turkmenistan: "Turkménistan",
		turks_and_caicos_islands: "Îles Turques-et-Caïques",
		tuvalu: "Tuvalu",
		uganda: "Ouganda",
		ukraine: "Ukraine",
		united_arab_emirates: "Émirats Arabes Unis",
		united_kingdom: "Royaume-Uni",
		united_states: "États-Unis",
		uruguay: "Uruguay",
		us_minor_outlying_islands: "Îles Mineures Éloignées des États-Unis",
		us_virgin_islands: "Îles Vierges américaines",
		uzbekistan: "Ouzbékistan",
		vanuatu: "Vanuatu",
		vatican_city: "Cité du Vatican",
		venezuela: "Venezuela",
		vietnam: "Viêt Nam",
		wallis_and_futuna: "Wallis-et-Futuna",
		western_sahara: "Sahara occidental",
		yemen: "Yémen",
		zambia: "Zambie",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		euro: "Euro",
		turkish_lira: "Livre turque",
		us_dollar: "Dollar américain",
	},
	$documentManager: {
		message_1:
			"Pour modifier le modèle, cliquez sur le bouton \"Modifier le modèle\" pour accéder à l'écran d'édition.",
		message_2:
			'Vous pouvez modifier le nombre de lignes dans la liste en augmentant ou en diminuant le "Nombre de lignes".',
		message_3:
			'Les modifications apportées aux champs de contenu apparaîtront instantanément dans la section "Aperçu".',
		message_4: 'Pour générer un PDF, cliquez sur le bouton "Actions du document" pour l\'enregistrer au format PDF.',
		message_5: 'Pour accéder aux documents plus tard, vous pouvez les enregistrer via le bouton "Actions du document".',
		message_6: "Les données saisies dans chaque cellule du tableau seront reflétées exactement dans l'aperçu.",
		message_7: "Le tableau de données peut être modifié comme une feuille Excel ou un tableur électronique.",
	},
	$employeeType: {
		admin: "Administrateur",
		default: "Défaut",
		exporter: "Exportateur",
		importer: "Importateur",
	},
	$format: {
		date: "DD/MM/YYYY",
		date_time: "DD/MM/YYYY HH:mm:ss",
		date_time_without_second: "DD/MM/YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "Créez votre entreprise ici.",
			create_company_title: "Voulez-vous ajouter votre entreprise ?",
			description:
				"Vous vous êtes connecté avec succès. Vous devez maintenant rejoindre une entreprise ou en créer une nouvelle.",
			join_company_subtitle: "Entrez votre code et rejoignez l'entreprise ici.",
			join_company_title: "Voulez-vous rejoindre une entreprise existante ?",
			title: "Bienvenue {name}",
		},
		$unauthorized: {
			description: "Gardez vos processus d'exportation-importation sous contrôle en utilisant Antrego",
			forget_password_subtitle: "Veuillez saisir votre email pour réinitialiser votre mot de passe.",
			sign_in_subtitle: "Connectez-vous ici",
			sign_in_title: "Vous avez déjà un compte ?",
			sign_up_subtitle: "Inscrivez-vous ici",
			sign_up_title: "Voulez-vous créer un compte ?",
			title: "Bienvenue chez Antrego",
		},
	},
	$invitationType: {
		company_partner: "Invitation de coopération commerciale",
		customs_partner: "Invitation de partenaire douanier",
		employee: "Invitation d'employé",
	},
	$landing: {
		$benefits: {
			benefits_title: "Avantages",
			business_process: "Accélère vos processus d'affaires.",
			control_title: "Prenez le contrôle avec Antrego.",
			highlight_word: "contrôle",
			risk_of_error: "Réduit les risques d'erreurs.",
			text: "Antrego est un programme de suivi des expéditions qui aide les entreprises de transport, leurs clients et les courtiers en douane à suivre les processus d'expédition via une plateforme partagée.",
			time_and_cost_savings: "Économise du temps et des coûts.",
		},
		$content: {
			$text: {
				0: "Suivez toutes vos expéditions en un seul endroit et contrôlez vos finances grâce à des rapports dynamiques.",
				1: "Partagez vos mises à jour en temps réel avec vos clients pour améliorer la qualité de votre service.",
				2: "Affichez et partagez tous les documents liés à votre expédition dans un seul système.",
				3: "Contrôlez facilement votre processus en accédant à Antrego depuis tous les appareils et lieux.",
				4: "Stockez toutes vos données à l'aide de la technologie de cryptage SHA-256. Soyez assuré quant à la sécurité.",
				5: "Participez à un suivi conjoint avec tous les intervenants dans le processus métier. Gérez le processus plus efficacement.",
			},
			$title: {
				0: "Gestion des expéditions",
				1: "Relations client",
				2: "Gestion des documents",
				3: "Accès facile",
				4: "Sécurité des données",
				5: "Suivi conjoint",
			},
		},
		$home: {
			text: "Choisissez-nous pour un suivi numérique fluide et un contrôle conjoint.",
			title: "Gérez facilement tous vos processus d'expédition numériquement",
		},
		$lifecycle: {
			headline: "Processus",
			info_button: "Obtenir des informations",
			lifecycle_texts: {
				0: "Téléchargez des fichiers supplémentaires pour créer votre facture proforma et partagez-la instantanément avec votre client.",
				1: "Saisissez les détails de votre expédition et ajoutez des documents. Informez votre client et gérez conjointement tous les processus d'expédition via un seul canal.",
				2: "Suivez votre situation financière liée à l'importation et à l'exportation en temps réel.",
			},
			lifecycle_titles: {
				0: "Connectez-vous et créez une facture proforma",
				1: "Créer une expédition",
				2: "Suivre les activités financières",
			},
			productivity_title: "Augmentez la productivité et réduisez la complexité et les erreurs en 3 étapes.",
			tracking_guide_title: "Guide de suivi facile",
		},
		$logistics: {
			logistics_title: "Types de logistique supportés",
			solutions_title: "La solution adaptée à chaque charge",
			text: "Avec Antrego, votre partenaire numérique dans tous types de transport, nous sommes à vos côtés pour tous vos mouvements mondiaux.",
		},
		$pdfEditor: {
			description:
				"Notre éditeur PDF est un outil puissant qui rend la création et la modification de factures plus pratiques. Gagnez du temps et présentez des documents d'aspect professionnel à vos partenaires commerciaux.",
			features: {
				design:
					"Conception de factures : ajoutez votre logo, ajustez les couleurs et personnalisez le format de votre facture.",
				edit: "Édition de documents : modifiez rapidement des PDF, ajoutez ou supprimez du texte.",
				shareSave:
					"Partage et enregistrement : téléchargez, partagez ou stockez vos documents créés directement sur la plateforme.",
			},
			longTitle: "Des factures rapides et personnalisées avec l'éditeur PDF.",
			note: "Cette configuration permet aux utilisateurs d'explorer visuellement les fonctionnalités et de passer en revue les avantages par écrit.",
			title: "Créer des factures est si facile !",
		},
		$process: {
			digital_future: "Abandonnez les méthodes traditionnelles et entrez dans l'avenir numérique",
			digital_shipping: "Suivi numérique des expéditions avec Antrego",
			digital_shipping_texts: {
				0: "Les factures proforma pour les commandes entrantes sont créées et suivies facilement via un seul canal.",
				1: "Les factures proforma sont envoyées, les notifications sont instantanément envoyées au client, et elles sont approuvées via Antrego.",
				2: "Les processus documentaires sont gérés via Antrego. Ils ne sont pas perdus, aucun retard ne se produit, et le potentiel d'erreurs est minimisé.",
				3: "Les fournisseurs, clients et courtiers en douane peuvent instantanément voir les détails de l'expédition et les suivre conjointement.",
				4: "Les rapports financiers sont générés automatiquement mensuellement et annuellement.",
			},
			question_title: "Comment Antrego va-t-il changer votre vie ?",
			tracking_method: "Numérisez vos méthodes de suivi et accélérez vos processus.",
			traditional_shipping: "Suivi traditionnel des expéditions",
			traditional_shipping_texts: {
				0: "Les commandes sont collectées par e-mail, messages ou téléphone via différents canaux, provoquant des difficultés de suivi interne.",
				1: "La facture proforma est créée à l'aide d'outils comme Excel, et le processus d'approbation est géré manuellement par e-mail, WhatsApp ou téléphone.",
				2: "Les processus manuels de documents entraînent des erreurs et des retards, entraînant des pertes de temps et financières.",
				3: "Il n'y a pas de plateforme commune pour que les fournisseurs, clients et courtiers en douane puissent suivre les détails de l'expédition.",
				4: "Les données financières sont vérifiées individuellement après chaque expédition, rendant le reporting difficile.",
			},
		},
		$question: {
			$text: {
				0: "<p>C'est un programme de suivi des expéditions qui aide les entreprises et leurs clients à suivre conjointement, empêchant les pertes dues à des retards dans la phase de suivi.</p>",
				1: "<p>- Partagez toutes vos mises à jour en temps réel avec votre client. Améliorez la qualité de votre service.<br>\n      - Affichez et partagez tous les documents liés à votre expédition dans un seul système.<br>\n      - Suivez toutes vos expéditions depuis un seul endroit. Contrôlez vos finances avec des rapports dynamiques.</p>",
				2: "<p>- Mer<br>\n      - Air<br>\n      - Terre<br>\n      - Train</p>",
				3: "<p>Grâce à ses services, il est toujours à portée de main pour faciliter votre processus d'expédition.<br>\n      <strong>1. Accès facile</strong><br>\n      Accessible depuis tous les appareils et lieux via un navigateur web.<br>\n      <strong>2. Sécurité des données</strong><br>\n      Toutes les données sont stockées à l'aide de la technologie de cryptage SHA-256.<br>\n      <strong>3. Suivi conjoint</strong><br>\n      Il offre des possibilités de suivi à tous les intervenants dans le processus.</strong>",
				4: "<p>Oui, c'est une application payante. En numérisant tous vos processus d'expédition, nous voulons vous faciliter la tâche et continuer à apporter des améliorations utiles pour vous. Afin d'assurer la durabilité de ces améliorations et de l'espace serveur que vous utilisez, nous facturons des frais mensuels.</p>",
				5: "<p>Non. Vos clients n'ont pas besoin de payer de frais pour suivre les expéditions que vous effectuez.</p>",
			},
			$title: {
				0: "Qu'est-ce qu'Antrego ?",
				1: "Qu'est-ce qu'Antrego apportera à mon entreprise pendant le processus d'expédition ?",
				2: "Quels types de logistique Antrego prend-il en charge ?",
				3: "Pourquoi devrais-je utiliser Antrego ?",
				4: "Antrego est-il une application payante ?",
				5: "Mes clients doivent-ils payer pour Antrego ?",
			},
			save_percentage: "Économisez 16 % par mois",
			text: "Accédez facilement à toutes les informations avec Antrego, et n'hésitez pas à nous contacter pour toute autre question que vous pourriez avoir.",
			title: "Apprenez rapidement tout ce qui vous intéresse",
		},
		cancel_anytime: "Annulez à tout moment",
		customize_package:
			"Vous pouvez acheter un stockage supplémentaire et des utilisateurs et personnaliser votre forfait en fonction de vos besoins",
		free_features_1: "10 expéditions",
		free_features_2: "100 Mo de stockage",
		free_features_3: "Partenaires commerciaux illimités",
		free_features_4: "1 utilisateur",
		free_plan: "Gratuit",
		free_price: "0 $",
		free_regeneration: "",
		free_title: "Mensuel",
		premium_annual_features_1: "20 utilisateurs",
		premium_annual_features_2: "100 partenaires",
		premium_annual_features_3: "1000 expéditions",
		premium_annual_features_4: "1000 factures",
		premium_annual_price: "83,25 $",
		premium_annual_regeneration: "Renouvellement annuel à 999 $",
		premium_annual_title: "Annuel",
		premium_monthly_features_1: "Expéditions illimitées",
		premium_monthly_features_2: "Partenaires commerciaux illimités",
		premium_monthly_features_3: "2Go de stockage",
		premium_monthly_features_4: "3 utilisateurs",
		premium_monthly_price: "99 $",
		premium_monthly_regeneration: "Renouvellement mensuel à 99 $",
		premium_monthly_title: "Mensuel",
		premium_plan: "Forfait Premium",
		premium_plus_annual_features_1: "Expéditions illimitées",
		premium_plus_annual_features_2: "Partenaires commerciaux illimités",
		premium_plus_annual_features_3: "5Go de stockage",
		premium_plus_annual_features_4: "5 utilisateurs",
		premium_plus_annual_price: "83,25 $",
		premium_plus_annual_regeneration: "Renouvellement annuel à 999 $",
		premium_plus_annual_title: "Annuel",
		premium_plus_monthly_features_1: "Expéditions illimitées",
		premium_plus_monthly_features_2: "Partenaires commerciaux illimités",
		premium_plus_monthly_features_3: "5Go de stockage",
		premium_plus_monthly_features_4: "5 utilisateurs",
		premium_plus_monthly_price: "249 $",
		premium_plus_monthly_regeneration: "Renouvellement mensuel à 249 $",
		premium_plus_monthly_title: "Mensuel",
		premium_plus_plan: "Premium Plus",
		pricing_text: "Nous pouvons répondre à vos besoins de la meilleure façon sans dépasser votre budget.",
		pricing_title: "Antrego garantit de vous offrir le meilleur forfait et prix.",
		save_percentage: "Économisez 16 % par mois",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "Arabe",
		de: "Allemand",
		en: "Anglais",
		es: "Espagnol",
		fr: "Français",
		hi: "Hindi",
		ind: "Indonésien",
		it: "Italien",
		ja: "Japonais",
		ko: "Coréen",
		pt: "Portugais",
		ru: "Russe",
		tr: "Turc",
		vi: "Vietnamien",
		zh: "Chinois",
	},
	$message: {
		cannot_delete_verified_partner: "Vous ne pouvez pas supprimer un partenaire vérifié.",
		confirm: "Êtes-vous sûr de {function} ?",
		created: "{item} créé.",
		deleted: "{item} supprimé.",
		error_on_view_file: "Une erreur s'est produite lors de la visualisation du fichier.",
		file_oversize: "La taille du fichier doit être au maximum {size}.",
		invalid: "{item} invalide",
		migrated: "{item} migré. ({value})",
		process_has_been_started: "Votre {item} a été démarré.",
		sent: "{item} envoyé.",
		sent_email: "E-mail envoyé. ({value})",
		sign_in_failed: "L'e-mail ou le mot de passe est incorrect",
		unsupported_file_type: "Type de fichier non pris en charge",
		updated: "{item} mis à jour.",
	},
	$pageTitles: {
		accept_invitation: "Accepter l'invitation",
		company_profile: "Profil de l'entreprise",
		home: "Accueil",
		landing_page: "Suivi numérique fluide, contrôle partagé",
		partners: "Partenaires",
		pdf_editor: "Éditeur PDF",
		product_groups: "Groupes de produits",
		products: "Produits",
		proforma_invoices: "Factures proforma",
		shipment_details: "Détails de l'expédition",
		shipments: "Expéditions",
		sign_in: "Connexion",
		sign_up: "Inscription",
		subscription: "Abonnement",
		user_profile: "Profil utilisateur",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"Partenaires non enregistrés, vous avez créé leur profil sur notre plateforme, vous pouvez donc afficher et modifier leurs informations.",
		createdHereTitle: "Créé ici :",
		description: "Vous pouvez suivre l'état de votre relation avec vos partenaires. Voici les différences :",
		verifiedPartnersDescription:
			"Ces partenaires ont accepté votre invitation et se sont inscrits sur la plateforme. Leurs informations sont en lecture seule et ne peuvent pas être modifiées.",
		verifiedPartnersTitle: "Partenaires vérifiés :",
	},
	$productGroup: {
		add_child: "Ajouter un enfant",
		add_new_product_group: "Ajouter un nouveau groupe de produits",
		add_to_root: "Ajouter à la racine",
		has_children_fail_message: "Un groupe de produits avec un enfant ne peut pas être supprimé",
		initial_message: "Utilisez ce bouton {0} pour créer votre premier groupe de produits.",
		name: "Nom",
		number_of_product: "Nombre de produits",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} approuvé.",
			create: "Créé par {name}.",
			create_attachment: "{name} a créé une pièce jointe.",
			delete_attachment: "{name} a supprimé une pièce jointe.",
			demand_revise: "{name} a demandé une révision.",
			mark_as_processed: "{name} marqué comme traité.",
			reject: "{name} rejeté.",
			revoke: "{name} révoqué.",
			send: "{name} envoyé au récepteur pour évaluation.",
			update: "Mis à jour par {name}.",
			update_attachment: "{name} a mis à jour une pièce jointe.",
		},
		$actions: {
			approve: "Approuver",
			demand_revise: "Demander une révision",
			mark_as_approved: "Approuvé",
			mark_as_processed: "Traité",
			mark_as_rejected: "Rejeté",
			mark_as_revise_demanded: "Révision demandée",
			mark_as_revoked: "Révoqué",
			mark_as_sent: "Envoyé",
			reject: "Rejeter",
			revoke: "Révoquer",
			send: "Envoyer",
		},
		$status: {
			approved: "Approuvé",
			pending_approval: "En attente d'approbation",
			pending_revise: "Révision en attente",
			processed: "Traité",
			rejected: "Rejeté",
			revoked: "Révoqué",
			template: "Modèle",
		},
		approve_confirm_message: "Êtes-vous sûr d'approuver la facture proforma ?",
		approve_success_message: "La facture proforma a été approuvée.",
		demand_revise_description_message: "Veuillez donner des détails sur vos attentes de révision :",
		demand_revise_success_message: "La facture proforma a été renvoyée à l'expéditeur pour révision.",
		mark_as_processed_confirm_message: "Confirmez-vous de marquer la facture proforma comme traitée ?",
		mark_as_processed_success_message: "La facture proforma a été marquée comme traitée.",
		no_attachment_text: "Aucune pièce jointe ajoutée pour le moment.",
		reject_confirm_message: "Êtes-vous sûr de rejeter la facture proforma ?",
		reject_success_message: "La facture proforma a été rejetée.",
		revoke_confirm_message: "Êtes-vous sûr de révoquer la facture proforma ?",
		revoke_success_message: "La facture proforma a été révoquée.",
		send_confirm_message: "Confirmez-vous l'envoi de la facture proforma au récepteur ?",
		send_success_message: "La facture proforma a été envoyée.",
	},
	$serviceMessage: {
		already_subs_module: "Il existe déjà un abonnement pour ce module.",
		bad_request: "Mauvaise requête",
		code_already_used: "Le code a déjà été utilisé.",
		container_status_not_available: "Statut du conteneur non disponible.",
		existing_email_warning: "L'e-mail existe déjà.",
		existing_employee_error: "Cet utilisateur est déjà ajouté à votre entreprise.",
		existing_employee_for_email_warning: "Le code d'invitation a déjà été envoyé à cet e-mail",
		existing_invitation_warning: "Le code d'invitation a déjà été envoyé à cet e-mail.",
		existing_token_already_have_time: "Le jeton existant a déjà assez de temps pour être utilisé.",
		existing_user: "L'utilisateur existe déjà.",
		five_min_reset_password_mail:
			"Vous devez attendre au moins 5 minutes après avoir demandé un e-mail de réinitialisation du mot de passe.",
		forbidden: "Interdit",
		have_no_corporation: "Vous n'avez pas d'entreprise",
		have_no_custom_agency: "Vous n'avez pas d'agence douanière",
		have_no_employee: "Vous n'avez pas d'employé",
		instance_can_no_delete_existing_booking_code:
			"L'instance ne peut plus être supprimée en raison d'un code de réservation existant.",
		instance_cannot_delete: "L'instance ne peut plus être supprimée.",
		instance_not_found: "Instance non trouvée.",
		internal_server_error: "Erreur interne du serveur",
		invalid_current_password: "Mot de passe actuel invalide.",
		invalid_identifier_or_password: "Identifiant ou mot de passe invalide",
		invalid_invitation_code: "Le code d'invitation est incorrect.",
		invalid_moving_request: "Demande de déménagement invalide",
		invalid_operation: "Opération invalide",
		invalid_refresh_token: "Jeton de rafraîchissement invalide.",
		invalid_unit: "Unité invalide",
		invitation_accepting_is_due_to_the_admin: "L'acceptation de l'invitation est due à l'administrateur.",
		invitation_already_discarded: "L'invitation a déjà été annulée.",
		invitation_already_used: "L'invitation a déjà été utilisée.",
		invitation_has_been_used: "L'invitation a été utilisée.",
		invitation_not_exist_email: "L'invitation n'existe pas à cette adresse e-mail.",
		invitation_not_for_company: "Cette invitation n'est pas destinée à une entreprise.",
		invitation_not_for_custom_agency: "Cette invitation n'est pas destinée à une agence de douane.",
		invitation_not_found: "Invitation non trouvée.",
		invitation_not_valid: "L'invitation n'est plus valide.",
		link_has_been_expired: "Le lien a expiré.",
		link_not_be_used_anymore: "Ce lien ne peut plus être utilisé",
		missing_required_fields: "Champs obligatoires manquants.",
		no_employee_found: "Aucun employé trouvé",
		no_proper_partner_found: "Aucun partenaire approprié trouvé.",
		not_acceptable: "Non acceptable",
		not_allowed_action: "Vous n'êtes pas autorisé à effectuer cette action.",
		not_allowed_use_api: "Vous n'êtes pas autorisé à utiliser cette API.",
		not_found: "Non trouvé",
		not_have_purchase_subscription:
			"Votre plan d'abonnement a atteint la limite maximale pour les définitions. Pour en ajouter davantage, envisagez de passer à un abonnement supérieur.",
		not_permitted_use_api: "Vous n'êtes pas autorisé à utiliser cette API.",
		own_invitation_cannot_accept: "Vous ne pouvez pas accepter votre propre invitation.",
		parent_group_not_found: "Groupe parent non trouvé",
		partnerId_required: "L'ID partenaire est requis.",
		partnership_already_exists: "Le partenaire a déjà été ajouté.",
		password_cant_involve_space_char: "Le mot de passe ne peut pas contenir d'espace.",
		password_min_chars_least: "Le mot de passe doit contenir au moins 6 caractères.",
		payment_required: "Veuillez vous abonner pour ce processus.",
		paytr_integration_error: "Erreur d'intégration PayTR",
		ports_are_required: "Les ports sont requis.",
		product_group_tree_not_found: "Arborescence de groupe de produits non trouvée",
		proforma_invoice_action_not_available: "Le statut de la facture proforma n'est pas disponible pour cette action.",
		proforma_invoice_template_update: "La facture proforma doit être un modèle pour être mise à jour.",
		shipment_email_not_exist: "Veuillez entrer un e-mail valide.",
		shipment_method_is_required: "La méthode d'expédition est requise.",
		shipping_term_is_required: "La condition d'expédition est requise.",
		someone_else_uses_this_email: "Quelqu'un d'autre utilise cette adresse e-mail.",
		someone_else_uses_this_username: "Quelqu'un d'autre utilise ce nom d'utilisateur.",
		something_went_wrong: "Une erreur s'est produite",
		type_can_be_abstract_company: '"type" ne peut être que "entreprise_abstraite"',
		unauthorized: "Non autorisé",
		undefined_result: "Résultat indéfini",
		unexpected_error_occurred: "Une erreur inattendue est survenue",
		unit_already_added: "L'unité a déjà été ajoutée.",
		user_already_employee: "L'utilisateur est déjà un employé.",
		user_already_exists: "L'utilisateur existe déjà dans une entreprise.",
		user_does_not_exist: "L'utilisateur n'existe pas.",
		user_not_found: "Utilisateur non trouvé.",
		username_min_chars_error: '"Nom d\'utilisateur" doit comporter au moins 6 caractères.',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} a ajouté un véhicule.",
				delete_container: "{name} a supprimé un véhicule.",
				update_container: "{name} a mis à jour un véhicule.",
			},
			create: "Créé par {name}.",
			create_attachment: "{name} a ajouté une pièce jointe.",
			create_container: "{name} a ajouté un conteneur.",
			delete_attachment: "{name} a supprimé une pièce jointe.",
			delete_container: "{name} a supprimé un conteneur.",
			update: "Mis à jour par {name}.",
			update_attachment: "{name} a mis à jour une pièce jointe.",
			update_container: "{name} a mis à jour un conteneur.",
		},
		$containerStatus: {
			arrived: "Arrivé",
			off_load: "Déchargé",
			on_load: "En charge",
			on_way: "En route",
			reserved: "Réservé",
		},
		$method: {
			aerial: "Aérien",
			land: "Terrestre",
			railway: "Ferroviaire",
			sea: "Maritime",
		},
		$vehicle: {
			license_number: "Plaque d'immatriculation",
		},
		archiving_success_message: "L'expédition a été archivée.",
		leave_message: "Laissez votre message ou commentaire",
		no_booking_info: "Aucune information de réservation n'a encore été définie. Utilisez le bouton {0} pour ajouter.",
		no_transportation_info: "Aucune information détaillée n'a encore été définie. Utilisez le bouton {0} pour définir.",
		un_archiving_success_message: "L'expédition a été désarchivée.",
	},
	$shortening: {
		number: "N°",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$subscription: {
		$message: {
			check_your_payment_method:
				"Votre abonnement n'a pas pu être renouvelé. Veuillez vérifier votre mode de paiement.",
			fail: "Une erreur inattendue est survenue. Veuillez réessayer plus tard. Si le problème persiste, veuillez le signaler en envoyant un e-mail à info@antrego.net.",
			ok: "Le processus d'achat a été initié. Le résultat du processus sera notifié par e-mail. Votre abonnement commencera lorsque le paiement sera terminé avec succès.",
			payment_process_is_ongoing:
				"Votre processus de paiement est en cours. Le résultat du processus sera notifié par e-mail. Votre abonnement commencera lorsque le paiement sera terminé avec succès.",
			success: "Votre abonnement a été créé avec succès. Vous pouvez commencer à utiliser Antrego facilement.",
			unsubscribed: "Désabonné",
			you_dont_have_any_subscription:
				"Vous n'avez pas d'abonnement. Veuillez choisir un forfait sur la page d'abonnement et commencez à l'utiliser facilement.",
			you_dont_have_any_subscription_text: "Veuillez choisir un plan pour continuer.",
			you_dont_have_any_subscription_title: "Vous n'avez aucun abonnement actif.",
		},
	},
	$unit: {
		acres: "Acres",
		boxes: "Boîtes",
		cubic_meter: "Mètre cube",
		dozen: "Douzaine",
		feet: "Pieds",
		fluid_ounces: "Onces liquides",
		gallons: "Gallons",
		gram: "Gramme",
		hectare: "Hectare",
		inches: "Pouces",
		kilogram: "Kilogramme",
		kilometer: "Kilomètre",
		liter: "Litre",
		meter: "Mètre",
		miles: "Miles",
		milligram: "Milligramme",
		milliliter: "Millilitre",
		millimeter: "Millimètre",
		ounces: "Onces",
		pallets: "Palettes",
		pieces: "Pièces",
		pounds: "Livres",
		quarts: "Quarts",
		square_feet: "Pieds carrés",
		square_inches: "Pouces carrés",
		square_kilometer: "Kilomètre carré",
		square_meter: "Mètre carré",
		square_miles: "Miles carrés",
		square_yards: "Yards carrés",
		ton: "Tonnes",
		yards: "Yards",
	},
	$validation: {
		email: "Veuillez entrer un e-mail valide",
		invalid: "{item} invalide",
		max_length: "{item} doit comporter moins de {length} caractères",
		min_length: "{item} doit comporter plus de {length} caractères",
		must_length: "{item} doit comporter {length} caractères",
		only_number: "{item} doit être un nombre",
		password_repeat_warning: "La répétition du mot de passe n'est pas identique au nouveau mot de passe.",
		required: "{item} est requis",
		required_for_default_language: "{item} est requis pour la langue par défaut de l'entreprise.",
		you_must_approve_distance_sales_agreement: "Vous devez approuver le contrat de vente à distance",
	},
	about: "À propos",
	accept_invitation: "Accepter l'invitation",
	accept_invitation_success_message: "Vous avez utilisé l'invitation avec succès.",
	accessibility: "Accessibilité",
	actions: "Actions",
	active: "Actif",
	activities: "Activités",
	add: "Ajouter",
	add_item: "Ajouter {item}",
	add_new_employee: "Ajouter un nouvel employé",
	add_new_product: "Ajouter un nouveau produit",
	add_template: "Ajouter un modèle",
	address: "Adresse",
	again: "Encore",
	alert: "Alerte",
	already_have_employee_message: "Vous êtes déjà un employé d'une entreprise.",
	amount: "Montant",
	approve: "Approuver",
	archive: "Archiver",
	attachment: "Pièce jointe",
	attachments: "Pièces jointes",
	auto_attached_pdf: "PDF ajouté automatiquement",
	bank: "Banque",
	benefits: "Avantages",
	billed_to: "Facturé à",
	booking: "Réservation",
	booking_code: "Code de réservation",
	booking_details: "Détails de la réservation",
	business_partner: "Partenaire commercial",
	business_partners: "Partenaires commerciaux",
	cancel: "Annuler",
	change_password: "Changer le mot de passe",
	checking_invitation_code_message: "Vérification du code d'invitation",
	choose_image: "Choisissez une image",
	choose_your_plan: "Choisissez votre plan",
	clear: "Effacer",
	click_to_play_the_video: "Cliquez pour lire la vidéo",
	close: "Fermer",
	code: "Code",
	commercial: "Commercial",
	commercial_partner: "Partenaire commercial",
	commercial_partners: "Partenaires commerciaux",
	companies: "Entreprises",
	company: "Entreprise",
	company_address: "Adresse de l'entreprise",
	company_name: "Nom de l'entreprise",
	company_profile: "Profil de l'entreprise",
	configuration: "Configuration",
	confirm: "Confirmer",
	confirm_distance_selling_agreement: "Confirmez le contrat de vente à distance.",
	consult_us: "Consultez-nous",
	contact: "Contact",
	contact_info: "Informations de contact",
	contact_text: "Soyez assuré, nous vous contacterons dès que possible.",
	contact_title: "Consultez-nous, simplifiez vos processus",
	contact_to_us: "Contactez-nous",
	container: "Conteneur",
	container_no: "N° de conteneur",
	containers: "Conteneurs",
	content: "Contenu",
	contract: "Contrat",
	copy_link: "Copier le lien",
	corporation: "Entreprise",
	countries: "Pays",
	country: "Pays",
	create: "Créer",
	create_a_new_account: "Créer un nouveau compte",
	create_company: "Créer une entreprise",
	create_document: "Créer un document",
	created_at: "Créé le",
	created_here: "Créé ici",
	credit_card_info: "Informations sur la carte de crédit",
	credit_card_no: "N° de carte de crédit",
	currencies: "Devises",
	currency: "Devise",
	current_password: "Mot de passe actuel",
	current_plan: "Plan actuel",
	customer_company_address: "Adresse de la société cliente",
	customer_company_name: "Nom de la société cliente",
	customs: "Douanes",
	customs_agencies: "Agences des douanes",
	customs_agency: "Agence des douanes",
	cvv: "CVV",
	daily: "Quotidien",
	dashboard: "Tableau de bord",
	date: "Date",
	day: "Jour",
	default: "Défaut",
	default_language: "Langue par défaut",
	delete: "Supprimer",
	delete_account: "Supprimer le compte",
	delete_confirm_message: "Confirmez-vous la suppression ?",
	delete_document: "Supprimer le document",
	delete_template: "Supprimer le modèle",
	description: "Description",
	detail: "Détail",
	discard: "Annuler",
	distance_sales_agreement: "Le contrat de vente à distance",
	document: "Document",
	document_actions: "Actions sur les documents",
	document_added: "Document ajouté",
	document_manager: "Gestionnaire de documents",
	document_name: "Nom du document",
	document_number: "N° de document",
	document_overwrite: "Enregistrer les modifications comme nouveau document",
	document_save_info: "Ce document sera uniquement enregistré dans cette facture proforma.",
	document_updated: "Document mis à jour",
	download: "Télécharger",
	download_pdf: "Télécharger le PDF",
	edit: "Modifier",
	edit_document: "Modifier le document",
	edit_item: "Modifier {item}",
	edit_product: "Modifier le produit",
	edit_profile: "Modifier le profil",
	edit_template: "Modifier le modèle",
	edit_your_profile_picture: "Modifier votre photo de profil",
	email: "E-mail",
	employee: "Employé",
	employee_type: "Type d'employé",
	employees: "Employés",
	empty_data_warning:
		"Il n'y a pas encore de données dans ce domaine. Vous pouvez commencer à explorer la plateforme avec des données d'exemple.",
	estimated_arrival_day: "Jour d'arrivée estimé",
	expense: "Dépense",
	expenses: "Dépenses",
	expires_at: "Expire le",
	exported_containers: "Conteneurs exportés",
	fail: "Échec",
	failed: "Échoué",
	faq: "FAQ",
	feedback: "Retour d'information",
	file: "Fichier",
	file_name: "Nom du fichier",
	file_preview: "Aperçu du fichier",
	file_view: "Voir le fichier",
	filter: "Filtrer",
	first_name: "Prénom",
	first_name_last_name: "Prénom Nom",
	forget_password: "Mot de passe oublié",
	forget_password_message: "Vous avez oublié votre mot de passe ?",
	forget_password_subtitle: "Veuillez entrer votre adresse e-mail pour réinitialiser votre mot de passe.",
	free: "Gratuit",
	from: "De",
	general: "Par défaut",
	get_started_now: "Commencez maintenant",
	give_us_feedback: "Donnez-nous votre avis",
	go_to_panel: "Accéder au panneau",
	hide: "Cacher",
	home_page: "Page d'accueil",
	i_have_invitation_code: "J'ai un code d'invitation",
	imported_containers: "Conteneurs importés",
	in_progress: "En cours",
	incoming: "Entrant",
	incoming_shipments: "Expéditions entrantes",
	initial: "Initial",
	inputs: "Contenu",
	invitation: "Invitation",
	invitation_code: "Code d'invitation",
	invitation_mail_sent: "E-mail d'invitation envoyé",
	invitations: "Invitations",
	invoice: "Facture",
	invoice_no: "Numéro de facture",
	join: "Rejoindre",
	join_company: "Rejoindre l'entreprise",
	language: "Langue",
	language_options: "Options de langue",
	last_name: "Nom de famille",
	last_one_year: "Dernière année",
	latitude: "Latitude",
	left_a_comment: "A laissé un commentaire",
	link_copied: "Lien copié",
	link_copy_failed: "Échec de la copie du lien",
	loading_country: "Pays de chargement",
	loading_point: "Point de chargement",
	loading_port: "Port de chargement",
	log_in: "se connecter",
	logo: "Logo",
	longitude: "Longitude",
	mail_sent: "E-mail envoyé",
	manage_profile: "Gérer le profil",
	message: "Message",
	message_company_create: "Entreprise créée.",
	message_company_join: "Veuillez entrer le code d'invitation.",
	message_company_joined: "Vous avez rejoint l'entreprise",
	message_employee_delete: "Employé supprimé.",
	message_employee_save: "Employé enregistré.",
	message_join_company: "Entrez votre code pour rejoindre une entreprise existante",
	method: "Méthode",
	migrate: "Migrer",
	migrate_partner: "Migrer l'entreprise",
	mobile_number: "Numéro de mobile",
	month: "Mois",
	month_lowercase: "mois",
	monthly: "Mensuel",
	my_profile: "mon profil",
	my_role: "Mon rôle",
	name: "Nom",
	new: "Nouveau",
	new_company_package: "gratuit",
	new_password: "Nouveau mot de passe",
	new_shipment: "Nouvel envoi",
	next: "Suivant",
	no: "Non",
	no_content: "Pas de contenu",
	no_entity: "Aucune entité",
	no_entity_message: "Aucune entité trouvée.",
	no_text: "Pas de texte",
	not_found_page_message: "Désolé, je n'ai pas pu trouver cette page :(",
	note: "Remarque",
	notes: "Notes",
	number_of_rows: "Nombre de lignes",
	ok: "OK",
	operation_fail: "Échec de l'opération",
	outgoing: "Sortant",
	outgoing_shipments: "Expéditions sortantes",
	packages: "Forfaits",
	packing_list_title: "Liste de colisage",
	parent_group: "Groupe parent",
	partner: "Partenaire",
	partners: "Partenaires",
	partnership_status: "Statut du partenariat",
	passive: "Passif",
	password: "Mot de passe",
	pay: "Payer",
	payback: "Remboursement",
	payback_confirm_message: "Confirmez-vous le remboursement ?",
	payback_warning_message: "Si vous continuez, votre abonnement sera résilié.",
	payment: "Paiement",
	payment_amount: "Montant du paiement",
	payment_awaited: "Paiement attendu",
	payment_history: "Historique des paiements",
	payment_info: "Informations de paiement",
	payment_information: "Informations de paiement",
	payment_success: "Paiement réussi",
	payment_summary: "Résumé du paiement",
	payment_will_be_made_3d_secure: "*Votre paiement sera effectué avec 3D Secure.",
	pdf_attached_message: "PDF ajouté avec succès",
	pdf_editor: "Éditeur PDF",
	pdf_name: "Nom du PDF",
	pending_invitations: "Invitations en attente",
	per_month: "Par mois",
	phone_number: "Numéro de téléphone",
	plate_numbers: "Numéros de plaque",
	premium: "Premium",
	premium_plus: "Premium Plus",
	preview: "Aperçu",
	previous: "Précédent",
	pricing: "Tarification",
	process: "Processus",
	product: "Produit",
	product_group: "Groupe de produits",
	product_group_name: "Nom du groupe de produits",
	product_groups: "Groupes de produits",
	product_name: "Nom du produit",
	products: "Produits",
	profile: "Profil",
	proforma_invoice: "Facture proforma",
	proforma_invoices: "Factures proforma",
	proforma_number: "N° de proforma",
	proforma_title: "Facture pro forma",
	purchase: "Achat",
	quantity: "Quantité",
	receiver: "Récepteur",
	receiver_company: "Entreprise réceptrice",
	receiver_customs_agency: "Agence douanière réceptrice",
	redirection_to_home_link: "Accueil",
	redirection_to_home_message: "Vous pouvez également aller à ",
	redirection_to_sign_in_message: "Vous avez un compte ?",
	redirection_to_sign_up_message: "Vous n'avez pas encore de compte ?",
	reload: "Recharger",
	renewal: "Renouvellement",
	repaid: "Remboursé",
	reset_password: "Réinitialiser le mot de passe",
	rows_per_page: "Lignes par page",
	sales: "Ventes",
	save: "Enregistrer",
	save_as_document: "Enregistrer comme document",
	save_as_new_document: "Enregistrer comme nouveau document",
	save_as_new_template: "Enregistrer comme nouveau modèle",
	save_as_template: "Enregistrer comme modèle",
	save_changes: "Enregistrer les modifications",
	save_pdf_to_attachments: "Enregistrer le PDF dans les pièces jointes",
	saved_card_info: "Informations sur la carte enregistrées",
	search: "Rechercher",
	select: "Sélectionner",
	select_document: "Sélectionnez le document",
	select_document_to_overwrite: "Sélectionner le document à écraser",
	select_file: "Sélectionner un fichier",
	select_file_place_holder: "Veuillez sélectionner une image",
	select_language: "Sélectionner la langue",
	select_template: "Sélectionnez le modèle",
	select_template_to_overwrite: "Sélectionner le modèle à écraser",
	selected_partner: "Partenaire sélectionné",
	send: "Envoyer",
	send_again: "Envoyer à nouveau",
	send_email: "Envoyer un e-mail",
	send_feedback: "Envoyer un avis",
	send_invitation: "Envoyer une invitation",
	sender: "Expéditeur",
	sender_company: "Entreprise expéditrice",
	sender_customs_agency: "Agence douanière expéditrice",
	settings: "Paramètres",
	shipment: "Expédition",
	shipment_method: "Méthode d'expédition",
	shipment_type: "Type d'expédition",
	shipments: "Expéditions",
	shipments_filter: "Filtre des expéditions",
	shipping_term: "Condition d'expédition",
	shipping_terms: "Conditions d'expédition",
	show: "Afficher",
	sign_in: "Connexion",
	sign_out: "Déconnexion",
	sign_out_confirm_message: "Confirmez-vous la déconnexion ?",
	sign_out_success_message: "Au revoir.",
	sign_up: "Inscription",
	social_media: "Médias sociaux",
	social_media_account: "Compte sur les médias sociaux",
	start_time: "Heure de début",
	status: "Statut",
	subscribe: "S'abonner",
	subscription: "Abonnement",
	subscription_info_message:
		"Vous pouvez bénéficier de tous les services de manière illimitée pendant la période d'abonnement.",
	subscription_warning_message:
		"**Vos informations de carte seront stockées en toute sécurité et votre abonnement sera renouvelé régulièrement sauf si vous l'annulez.",
	success: "Succès",
	super: "Super",
	supported_files_img: "Formats de fichier pris en charge : JPG, PNG.",
	switch_language: "Changer de langue",
	switch_theme: "Changer de thème",
	table_inputs: "Contenu du tableau",
	target: "Cible",
	target_partner: "Partenaire cible",
	tax_number: "Numéro fiscal",
	template: "Modèle",
	template_actions: "Actions sur les modèles",
	template_added: "Modèle ajouté",
	template_name: "Nom du modèle",
	template_overwrite: "Enregistrer les modifications comme nouveau modèle",
	template_save_info:
		"Les modèles enregistrés seront disponibles ultérieurement pour les factures proforma et les expéditions.",
	template_updated: "Modèle mis à jour",
	terms_of_sale_and_other_comments: "Conditions de vente - Commentaires",
	theme: "Thème",
	this_month: "Ce mois-ci",
	this_year: "Cette année",
	title: "Titre",
	to: "À",
	toDelete: "Supprimer",
	total: "Total",
	total_amount: "Montant total",
	transaction_no: "N° de transaction",
	transactions: "Transactions",
	try_for_free: "essayer gratuitement",
	type: "Type",
	types: "Types",
	un_archive: "Désarchiver",
	unit: "Unité",
	units: "Unités",
	unloading_country: "Pays de déchargement",
	unloading_point: "Point de déchargement",
	unloading_port: "Port de déchargement",
	unsubscribe: "Se désabonner",
	unsubscribe_confirm_message:
		"Si vous vous désabonnez, vous ne pourrez pas utiliser les fonctionnalités du système efficacement. Êtes-vous sûr de vouloir vous désabonner ?",
	upload_new_image: "Télécharger une nouvelle image",
	use_code: "Utiliser le code",
	use_invitation_code: "Utiliser le code d'invitation",
	use_invitation_code_description: "Saisissez votre code d'invitation envoyé dans votre boîte aux lettres.",
	use_invitation_code_success_message: "Le code d'invitation a été utilisé avec succès.",
	user_profile: "Mon Profil",
	vehicle: "Véhicule",
	vehicles: "Véhicules",
	verified_partner: "Partenaire vérifié",
	verify_password: "Vérifier le mot de passe",
	visibility: "Visibilité",
	warehouse: "Entrepôt",
	warehouses: "Entrepôts",
	website: "Site web",
	week: "Semaine",
	welcome: "Bienvenue",
	what_we_are_doing: "Ce que nous faisons",
	year: "Année",
	year_lowercase: "année",
	yearly: "Annuel",
	yes: "Oui",
	you_dont_have_any_active_subscription: "Vous n'avez aucun abonnement actif.",
};
