export default {
	$accessibility: {
		archived: "संग्रहीत",
		present: "वर्तमान",
	},
	$accessModifier: {
		$description: {
			hidden_from_receiver: "प्राप्तकर्ता से छिपा हुआ",
			hidden_from_sender: "प्रेषक से छिपा हुआ",
			private: "निजी",
			shared_with_custom: "कस्टम के साथ साझा किया गया",
			shared_with_everyone: "सभी के साथ साझा किया गया",
			shared_with_receiver: "प्राप्तकर्ता के साथ साझा किया गया",
			shared_with_sender: "प्रेषक के साथ साझा किया गया",
		},
	},
	$antProduct: {
		limitGBStorage: "{limit}GB संग्रहण",
		limitMBStorage: "{limit}MB संग्रहण",
		limitUser: "{limit} उपयोगकर्ता",
		limitUsers: "{limit} उपयोगकर्ता",
		renewAtPricePerUnit: "{unit} के लिए {currency}{price} पर नवीनीकरण होगा",
		unlimitedModule: "असीमित {module}",
		upToLimitModule: "{limit} {module} तक",
	},
	$company: {
		address:
			"कोटेक्ली मह. डेनिज़ली योलु बुल. टेक्नोपार्क साइट्सी बी ब्लॉक नंबर:4B इंदरू नंबर:14 मेंटेसे / मुग्ला, तुर्की",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12715.523446137679!2d28.3731307!3d37.1793022!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1sen!2str!4v1701805110461!5m2!1sen!2str",
		name: "स्लश सॉफ्टवेयर इंक.",
		phoneNumber: "+90 (555) 444-33-22",
		slogan:
			"एंट्रेगो के साथ अपने व्यवसाय को तेज़ी से बढ़ाएं। अपने शिपमेंट प्रक्रियाओं को डिजिटल, सुरक्षित और आसान तरीके से प्रबंधित करें।",
	},
	$country: {
		afghanistan: "अफगानिस्तान",
		aland: "आलैंड",
		albania: "अल्बानिया",
		algeria: "अल्जीरिया",
		american_samoa: "अमेरिकन समोआ",
		andorra: "अंडोरा",
		angola: "अंगोला",
		anguilla: "एंगुइला",
		antarctica: "अंटार्कटिका",
		antigua_and_barbuda: "एंटिगुआ और बारबुडा",
		argentina: "अर्जेंटीना",
		armenia: "आर्मेनिया",
		aruba: "अरूबा",
		australia: "ऑस्ट्रेलिया",
		austria: "ऑस्ट्रिया",
		azerbaijan: "अज़रबैजान",
		bahamas: "बहामा",
		bahrain: "बहरीन",
		bangladesh: "बांग्लादेश",
		barbados: "बारबाडोस",
		belarus: "बेलारूस",
		belgium: "बेल्जियम",
		belize: "बेलीज",
		benin: "बेनिन",
		bermuda: "बरमूडा",
		bhutan: "भूटान",
		bolivia: "बोलिविया",
		bonaire_sint_eustatius_and_saba: "बोनेयर, सिंट यूस्टेशियस और सबा",
		bosnia_and_herzegovina: "बोस्निया और हर्ज़ेगोविना",
		botswana: "बोत्सवाना",
		bouvet_island: "बोवेट द्वीप",
		brazil: "ब्राज़ील",
		british_indian_ocean_territory: "ब्रिटिश हिंद महासागर क्षेत्र",
		british_virgin_islands: "ब्रिटिश वर्जिन द्वीप",
		brunei: "ब्रुनेई",
		bulgaria: "बुल्गारिया",
		burkina_faso: "बुर्किना फासो",
		burundi: "बुरुंडी",
		cabo_verde: "केप वर्डे",
		cambodia: "कंबोडिया",
		cameroon: "कैमरून",
		canada: "कनाडा",
		cayman_islands: "कैमैन द्वीप",
		central_african_republic: "केंद्रीय अफ्रीकी गणराज्य",
		chad: "चाड",
		chile: "चिली",
		china: "चीन",
		christmas_island: "क्रिसमस द्वीप",
		cocos_islands: "कोकोस [कीलिंग] द्वीप",
		colombia: "कोलंबिया",
		comoros: "कोमोरोस",
		congo_republic: "कांगो गणराज्य",
		cook_islands: "कुक द्वीप",
		costa_rica: "कोस्टा रिका",
		croatia: "क्रोएशिया",
		cuba: "क्यूबा",
		curacao: "कुरासाओ",
		cyprus: "साइप्रस",
		czechia: "चेकिया",
		denmark: "डेनमार्क",
		djibouti: "जिबूती",
		dominica: "डोमिनिका",
		dominican_republic: "डोमिनिकन गणराज्य",
		dr_congo: "कांगो लोकतांत्रिक गणराज्य",
		ecuador: "इक्वाडोर",
		egypt: "मिस्र",
		el_salvador: "एल साल्वाडोर",
		equatorial_guinea: "इक्वेटोरियल गिनी",
		eritrea: "इरिट्रिया",
		estonia: "एस्टोनिया",
		eswatini: "एस्वातिनी",
		ethiopia: "इथियोपिया",
		falkland_islands: "फ़ॉकलैंड द्वीप",
		faroe_islands: "फ़रो द्वीप",
		fiji: "फ़िजी",
		finland: "फिनलैंड",
		france: "फ्रांस",
		french_guiana: "फ़्रेंच गयाना",
		french_polynesia: "फ्रेंच पोलिनेशिया",
		french_southern_territories: "फ्रेंच दक्षिणी क्षेत्र",
		gabon: "गैबॉन",
		gambia: "गाम्बिया",
		georgia: "जॉर्जिया",
		germany: "जर्मनी",
		ghana: "घाना",
		gibraltar: "जिब्राल्टर",
		greece: "ग्रीस",
		greenland: "ग्रीनलैंड",
		grenada: "ग्रेनेडा",
		guadeloupe: "ग्वाडेलूप",
		guam: "गुआम",
		guatemala: "ग्वाटेमाला",
		guernsey: "गर्नसी",
		guinea: "गिनी",
		guinea_bissau: "गिनी-बिसाऊ",
		guyana: "गुयाना",
		haiti: "हैती",
		heard_island_and_mcdonald_islands: "हर्ड द्वीप और मैकडोनाल्ड द्वीप",
		honduras: "होंडुरास",
		hong_kong: "हांगकांग",
		hungary: "हंगरी",
		iceland: "आइसलैंड",
		india: "भारत",
		indonesia: "इंडोनेशिया",
		iran: "ईरान",
		iraq: "इराक",
		ireland: "आयरलैंड",
		isle_of_man: "आइल ऑफ मैन",
		israel: "इज़राइल",
		italy: "इटली",
		ivory_coast: "आइवरी कोस्ट",
		jamaica: "जमैका",
		japan: "जापान",
		jersey: "जर्सी",
		jordan: "जॉर्डन",
		kazakhstan: "कजाकिस्तान",
		kenya: "केन्या",
		kiribati: "किरिबाती",
		kosovo: "कोसोवो",
		kuwait: "कुवैत",
		kyrgyzstan: "किर्गिस्तान",
		laos: "लाओस",
		latvia: "लातविया",
		lebanon: "लेबनान",
		lesotho: "लेसोथो",
		liberia: "लाइबेरिया",
		libya: "लीबिया",
		liechtenstein: "लिकटेंस्टीन",
		lithuania: "लिथुआनिया",
		luxembourg: "लक्जमबर्ग",
		macao: "मकाऊ",
		madagascar: "मेडागास्कर",
		malawi: "मलावी",
		malaysia: "मलेशिया",
		maldives: "मालदीव",
		mali: "माली",
		malta: "माल्टा",
		marshall_islands: "मार्शल द्वीप",
		martinique: "मार्टीनिक",
		mauritania: "मॉरिटानिया",
		mauritius: "मॉरिशस",
		mayotte: "मायोट",
		mexico: "मेक्सिको",
		micronesia: "माइक्रोनेशिया",
		moldova: "मोल्दोवा",
		monaco: "मोनाको",
		mongolia: "मंगोलिया",
		montenegro: "मोंटेनेग्रो",
		montserrat: "मोंटसेराट",
		morocco: "मोरक्को",
		mozambique: "मोज़ाम्बिक",
		myanmar: "म्यांमार",
		namibia: "नामीबिया",
		nauru: "नाउरू",
		nepal: "नेपाल",
		netherlands: "नीदरलैंड्स",
		new_caledonia: "न्यू कैलेडोनिया",
		new_zealand: "न्यूजीलैंड",
		nicaragua: "निकारागुआ",
		niger: "नाइजर",
		nigeria: "नाइजीरिया",
		niue: "नियू",
		norfolk_island: "नॉरफ़ॉक द्वीप",
		north_korea: "उत्तर कोरिया",
		north_macedonia: "उत्तर मैसेडोनिया",
		northern_mariana_islands: "उत्तरी मारियाना द्वीप",
		norway: "नॉर्वे",
		oman: "ओमान",
		pakistan: "पाकिस्तान",
		palau: "पलाऊ",
		palestine: "फिलिस्तीन",
		panama: "पनामा",
		papua_new_guinea: "पापुआ न्यू गिनी",
		paraguay: "पराग्वे",
		peru: "पेरू",
		philippines: "फिलीपींस",
		pitcairn_islands: "पिटकेर्न द्वीप",
		poland: "पोलैंड",
		portugal: "पुर्तगाल",
		puerto_rico: "प्यूर्टो रिको",
		qatar: "कतर",
		reunion: "रीयूनियन",
		romania: "रोमानिया",
		russia: "रूस",
		rwanda: "रवांडा",
		saint_barthelemy: "सेंट बार्थेलेमी",
		saint_helena: "सेंट हेलेना",
		saint_lucia: "सेंट लूसिया",
		saint_martin: "सेंट मार्टिन",
		saint_pierre_and_miquelon: "सेंट पियरे और मिक्वेलोन",
		samoa: "सामोआ",
		san_marino: "सैन मैरिनो",
		sao_tome_and_principe: "साओ टोमे और प्रिंसिपे",
		saudi_arabia: "सऊदी अरब",
		senegal: "सेनेगल",
		serbia: "सर्बिया",
		seychelles: "सेशेल्स",
		sierra_leone: "सिएरा लियोन",
		singapore: "सिंगापुर",
		sint_maarten: "सिंट मार्टेन",
		slovakia: "स्लोवाकिया",
		slovenia: "स्लोवेनिया",
		solomon_islands: "सोलोमन द्वीप",
		somalia: "सोमालिया",
		south_africa: "दक्षिण अफ्रीका",
		south_georgia_and_south_sandwich_islands: "दक्षिण जॉर्जिया और दक्षिण सैंडविच द्वीप",
		south_korea: "दक्षिण कोरिया",
		south_sudan: "दक्षिण सूडान",
		spain: "स्पेन",
		sri_lanka: "श्रीलंका",
		st_kitts_and_nevis: "सेंट किट्स एंड नेविस",
		st_vincent_and_grenadines: "सेंट विंसेंट और ग्रेनेडाइन्स",
		sudan: "सूडान",
		suriname: "सूरीनाम",
		svalbard_and_jan_mayen: "स्वालबार्ड और जान मायेन",
		sweden: "स्वीडन",
		switzerland: "स्विट्ज़रलैंड",
		syria: "सीरिया",
		taiwan: "ताइवान",
		tajikistan: "ताजिकिस्तान",
		tanzania: "तंज़ानिया",
		thailand: "थाईलैंड",
		timor_leste: "तिमोर-लेस्ते",
		togo: "टोगो",
		tokelau: "टोकेलाऊ",
		tonga: "टोंगा",
		trinidad_and_tobago: "त्रिनिदाद और टोबैगो",
		tunisia: "ट्यूनिशिया",
		turkiye: "तुर्किये",
		turkmenistan: "तुर्कमेनिस्तान",
		turks_and_caicos_islands: "तुर्क और कैकोस द्वीप",
		tuvalu: "तुवालु",
		uganda: "युगांडा",
		ukraine: "यूक्रेन",
		united_arab_emirates: "संयुक्त अरब अमीरात",
		united_kingdom: "यूनाइटेड किंगडम",
		united_states: "संयुक्त राज्य अमेरिका",
		uruguay: "उरुग्वे",
		us_minor_outlying_islands: "यू.एस. माइनर आउटलेइंग द्वीप",
		us_virgin_islands: "यू.एस. वर्जिन द्वीप",
		uzbekistan: "उज़्बेकिस्तान",
		vanuatu: "वानुआतू",
		vatican_city: "वैटिकन सिटी",
		venezuela: "वेनेजुएला",
		vietnam: "वियतनाम",
		wallis_and_futuna: "वालिस और फुतुना",
		western_sahara: "पश्चिमी सहारा",
		yemen: "यमन",
		zambia: "ज़ाम्बिया",
		zimbabwe: "जिम्बाब्वे",
	},
	$currency: {
		euro: "यूरो",
		turkish_lira: "तुर्की लीरा",
		us_dollar: "यू.एस. डॉलर",
	},
	$documentManager: {
		message_1: 'टेम्पलेट को संपादित करने के लिए, "टेम्पलेट संपादित करें" बटन पर क्लिक करें और संपादन स्क्रीन पर जाएं।',
		message_2: 'आप "पंक्ति संख्या" बढ़ाकर या घटाकर सूची में पंक्तियों की संख्या बदल सकते हैं।',
		message_3: 'सामग्री क्षेत्रों में किए गए परिवर्तन "पूर्वावलोकन" अनुभाग में तुरंत दिखाई देंगे।',
		message_4: 'PDF बनाने के लिए, "दस्तावेज़ क्रियाएँ" बटन पर क्लिक करें और इसे PDF के रूप में सहेजें।',
		message_5: 'बाद में दस्तावेज़ों तक पहुँचने के लिए, आप "दस्तावेज़ क्रियाएँ" बटन से उन्हें सहेज सकते हैं।',
		message_6: "तालिका की प्रत्येक कोशिका में दर्ज किया गया डेटा पूर्वावलोकन में बिल्कुल वैसा ही दिखाई देगा।",
		message_7: "डेटा तालिका को Excel शीट या किसी इलेक्ट्रॉनिक स्प्रेडशीट की तरह संपादित किया जा सकता है।",
	},
	$employeeType: {
		admin: "प्रशासक",
		default: "डिफ़ॉल्ट",
		exporter: "निर्यातक",
		importer: "आयातक",
	},
	$format: {
		date: "DD-MM-YYYY",
		date_time: "DD-MM-YYYY HH:mm:ss",
		date_time_without_second: "DD-MM-YYYY HH:mm",
	},
	$home: {
		$noCompany: {
			create_company_subtitle: "यहाँ अपनी कंपनी बनाएं।",
			create_company_title: "क्या आप अपनी कंपनी जोड़ना चाहते हैं?",
			description: "आपने सफलतापूर्वक साइन इन कर लिया है। अब आपको एक कंपनी में शामिल होना है या नई कंपनी बनानी है।",
			join_company_subtitle: "अपना कोड दर्ज करें और यहाँ कंपनी में शामिल हों।",
			join_company_title: "क्या आप मौजूदा कंपनी में शामिल होना चाहते हैं?",
			title: "स्वागत है {name}",
		},
		$unauthorized: {
			description: "एंट्रेगो का उपयोग करके अपने निर्यात-आयात प्रक्रियाओं को नियंत्रित करें",
			forget_password_subtitle: "अपना पासवर्ड रीसेट करने के लिए अपना ईमेल दर्ज करें।",
			sign_in_subtitle: "यहाँ साइन इन करें",
			sign_in_title: "क्या आपके पास पहले से एक खाता है?",
			sign_up_subtitle: "यहाँ साइन अप करें",
			sign_up_title: "क्या आप अपना खाता बनाना चाहते हैं?",
			title: "एंट्रेगो में आपका स्वागत है",
		},
	},
	$invitationType: {
		company_partner: "वाणिज्यिक सहयोग आमंत्रण",
		customs_partner: "कस्टम पार्टनर आमंत्रण",
		employee: "कर्मचारी आमंत्रण",
	},
	$landing: {
		$benefits: {
			benefits_title: "लाभ",
			business_process: "आपके व्यवसाय प्रक्रियाओं को तेज करता है।",
			control_title: "एंट्रेगो के साथ नियंत्रण प्राप्त करें।",
			highlight_word: "नियंत्रण",
			risk_of_error: "त्रुटियों की संभावना को कम करता है।",
			text: "एंट्रेगो एक शिपमेंट ट्रैकिंग प्रोग्राम है जो शिपिंग कंपनियों, उनके ग्राहकों और कस्टम दलालों को शिपमेंट प्रक्रियाओं को साझा प्लेटफ़ॉर्म के माध्यम से ट्रैक करने में मदद करता है।",
			time_and_cost_savings: "समय और लागत की बचत करता है।",
		},
		$content: {
			$text: {
				0: "अपने सभी शिपमेंट को एक ही जगह पर ट्रैक करें, और डायनामिक रिपोर्टिंग के साथ अपने वित्त को नियंत्रित करें।",
				1: "अपनी सेवा गुणवत्ता को सुधारने के लिए अपने ग्राहकों के साथ वास्तविक समय के अपडेट साझा करें।",
				2: "अपने शिपमेंट से संबंधित सभी दस्तावेज़ एक ही सिस्टम में देखें और साझा करें।",
				3: "सभी उपकरणों और स्थानों से एंट्रेगो का उपयोग करके अपनी प्रक्रिया को आसानी से नियंत्रित करें।",
				4: "SHA-256 एन्क्रिप्शन तकनीक का उपयोग करके अपने सभी डेटा को संग्रहित करें। सुरक्षा की चिंता न करें।",
				5: "व्यापार प्रक्रिया में सभी भागीदारों के साथ संयुक्त ट्रैकिंग में भाग लें। प्रक्रिया को अधिक कुशलता से प्रबंधित करें।",
			},
			$title: {
				0: "शिपमेंट प्रबंधन",
				1: "ग्राहक संबंध",
				2: "दस्तावेज़ प्रबंधन",
				3: "आसान पहुँच",
				4: "डेटा सुरक्षा",
				5: "संयुक्त ट्रैकिंग",
			},
		},
		$home: {
			text: "सुव्यवस्थित डिजिटल ट्रैकिंग और संयुक्त नियंत्रण के लिए हमें चुनें।",
			title: "सभी शिपिंग प्रक्रियाओं को डिजिटल तरीके से आसानी से प्रबंधित करें",
		},
		$lifecycle: {
			headline: "प्रक्रिया",
			info_button: "जानकारी प्राप्त करें",
			lifecycle_texts: {
				0: "अपनी प्रोफोर्मा इनवॉयस बनाने के लिए अतिरिक्त फ़ाइलें अपलोड करें और इसे तुरंत अपने ग्राहक के साथ साझा करें।",
				1: "अपनी शिपमेंट विवरण दर्ज करें और दस्तावेज़ जोड़ें। अपने ग्राहक को सूचित करें और सभी शिपमेंट प्रक्रियाओं को एक चैनल के माध्यम से संयुक्त रूप से प्रबंधित करें।",
				2: "अपने आयात और निर्यात से संबंधित वित्तीय स्थिति को वास्तविक समय में ट्रैक करें।",
			},
			lifecycle_titles: {
				0: "लॉग इन करें और प्रोफोर्मा इनवॉयस बनाएं",
				1: "शिपमेंट बनाएं",
				2: "वित्तीय गतिविधियों को ट्रैक करें",
			},
			productivity_title: "3 चरणों में उत्पादकता बढ़ाएं और जटिलता और त्रुटियों को कम करें।",
			tracking_guide_title: "आसान ट्रैकिंग गाइड",
		},
		$logistics: {
			logistics_title: "समर्थित लॉजिस्टिक्स प्रकार",
			solutions_title: "हर माल के लिए सही समाधान",
			text: "एंट्रेगो के साथ, अपने सभी वैश्विक आंदोलनों के लिए अपने डिजिटल साथी के रूप में हम आपके साथ खड़े हैं।",
		},
		$pdfEditor: {
			description:
				"हमारा PDF संपादक एक शक्तिशाली उपकरण है जो चालान बनाने और संपादित करने को अधिक व्यावहारिक बनाता है। समय बचाएं और अपने व्यावसायिक साझेदारों को पेशेवर दिखने वाले दस्तावेज़ प्रस्तुत करें।",
			features: {
				design: "चालान डिज़ाइन: अपना लोगो जोड़ें, रंग समायोजित करें, और अपने चालान प्रारूप को अनुकूलित करें।",
				edit: "दस्तावेज़ संपादन: जल्दी से PDF संपादित करें, पाठ जोड़ें या हटाएं।",
				shareSave:
					"साझा करना और सहेजना: अपने बनाए गए दस्तावेज़ों को डाउनलोड करें, साझा करें, या सीधे प्लेटफ़ॉर्म पर सहेजें।",
			},
			longTitle: "PDF संपादक के साथ तेज़ और व्यक्तिगत चालान।",
			note: "यह सेटअप उपयोगकर्ताओं को सुविधाओं का दृश्य रूप से अन्वेषण करने और लिखित रूप में लाभों की समीक्षा करने की अनुमति देता है।",
			title: "चालान बनाना बहुत आसान है!",
		},
		$process: {
			digital_future: "पारंपरिक तरीकों से दूर हो जाएं और डिजिटल भविष्य में कदम रखें",
			digital_shipping: "एंट्रेगो के साथ डिजिटल शिपमेंट ट्रैकिंग",
			digital_shipping_texts: {
				0: "आने वाले ऑर्डर के लिए प्रोफोर्मा इनवॉयस आसानी से एक चैनल के माध्यम से बनाई और ट्रैक की जाती है।",
				1: "प्रोफोर्मा इनवॉयस भेजी जाती है, ग्राहक को तुरंत सूचनाएं मिलती हैं, और उन्हें एंट्रेगो के माध्यम से अनुमोदित किया जाता है।",
				2: "एंट्रेगो के माध्यम से दस्तावेज़ प्रक्रियाओं का प्रबंधन किया जाता है। वे खोते नहीं हैं, कोई देरी नहीं होती है, और त्रुटियों की संभावना कम से कम होती है।",
				3: "आपूर्तिकर्ता, ग्राहक, और कस्टम दलाल तुरंत शिपमेंट विवरण देख सकते हैं और उन्हें संयुक्त रूप से ट्रैक कर सकते हैं।",
				4: "मासिक और वार्षिक रूप से स्वचालित वित्तीय रिपोर्ट तैयार की जाती हैं।",
			},
			question_title: "एंट्रेगो आपके जीवन को कैसे बदलेगा?",
			tracking_method: "अपनी ट्रैकिंग विधियों को डिजिटाइज़ करें और अपनी प्रक्रियाओं को तेज करें।",
			traditional_shipping: "पारंपरिक शिपमेंट ट्रैकिंग",
			traditional_shipping_texts: {
				0: "ऑर्डर अलग-अलग चैनलों के माध्यम से ईमेल, संदेश या फोन के माध्यम से एकत्र किए जाते हैं, जिससे आंतरिक ट्रैकिंग में कठिनाइयाँ होती हैं।",
				1: "प्रोफोर्मा इनवॉयस एक्सेल जैसे उपकरणों का उपयोग करके बनाई जाती है, और अनुमोदन प्रक्रिया ईमेल, व्हाट्सएप या फोन के माध्यम से मैन्युअल रूप से की जाती है।",
				2: "मैन्युअल दस्तावेज़ प्रक्रियाओं के कारण त्रुटियाँ और देरी होती है, जिससे समय और वित्तीय नुकसान होता है।",
				3: "आपूर्तिकर्ताओं, ग्राहकों और कस्टम दलालों के पास शिपमेंट विवरणों को ट्रैक करने के लिए कोई साझा प्लेटफ़ॉर्म नहीं होता है।",
				4: "प्रत्येक शिपमेंट के बाद वित्तीय डेटा को व्यक्तिगत रूप से जाँचा जाता है, जिससे रिपोर्टिंग कठिन होती है।",
			},
		},
		$question: {
			$text: {
				0: "<p>यह एक शिपमेंट ट्रैकिंग प्रोग्राम है जो कंपनियों और उनके ग्राहकों को संयुक्त ट्रैकिंग के साथ मदद करता है, ट्रैकिंग चरण में होने वाली देरी के कारण होने वाले नुकसान को रोकता है।</p>",
				1: "<p>- अपने ग्राहक के साथ सभी वास्तविक समय के अपडेट साझा करें। अपनी सेवा की गुणवत्ता में सुधार करें।<br>\n      - अपने शिपमेंट से संबंधित सभी दस्तावेज़ एक प्रणाली में देखें और साझा करें।<br>\n      - एक जगह से अपने सभी शिपमेंट को ट्रैक करें। डायनामिक रिपोर्टिंग के साथ अपने वित्त को नियंत्रित करें।</p>",
				2: "<p>- समुद्र<br>\n      - वायु<br>\n      - भूमि<br>\n      - ट्रेन</p>",
				3: "<p>इसके सेवाओं के साथ, यह हमेशा आपके शिपमेंट प्रक्रिया को आसान बनाने के लिए आपके हाथ में है।<br>\n      <strong>1. आसान पहुँच</strong><br>\n      वेब ब्राउज़र के माध्यम से सभी उपकरणों और स्थानों से पहुँचा जा सकता है।<br>\n      <strong>2. डेटा सुरक्षा</strong><br>\n      सभी डेटा को SHA-256 एन्क्रिप्शन तकनीक का उपयोग करके संग्रहित किया जाता है।<br>\n      <strong>3. संयुक्त ट्रैकिंग</strong><br>\n      यह प्रक्रिया में सभी पक्षों को ट्रैकिंग के अवसर प्रदान करता है।</strong>",
				4: "<p>हाँ, यह एक सशुल्क एप्लिकेशन है। आपकी सभी शिपमेंट प्रक्रियाओं को डिजिटाइज़ करके, हम आपके काम को आसान बनाना चाहते हैं और आपके लिए उपयोगी विकास जारी रखते हैं। इन सुधारों की निरंतरता और आपके द्वारा उपयोग किए जाने वाले सर्वर स्पेस को सुनिश्चित करने के लिए, हम मासिक शुल्क लेते हैं।</p>",
				5: "<p>नहीं। आपके ग्राहकों को आपके द्वारा की गई शिपमेंट को ट्रैक करने के लिए कोई शुल्क नहीं देना होगा।</p>",
			},
			$title: {
				0: "एंट्रेगो क्या है?",
				1: "शिपमेंट प्रक्रिया के दौरान एंट्रेगो मेरी कंपनी में क्या योगदान करेगा?",
				2: "एंट्रेगो किस प्रकार की लॉजिस्टिक्स का समर्थन करता है?",
				3: "मुझे एंट्रेगो का उपयोग क्यों करना चाहिए?",
				4: "क्या एंट्रेगो एक सशुल्क एप्लिकेशन है?",
				5: "क्या मेरे ग्राहकों को एंट्रेगो के लिए भुगतान करना होगा?",
			},
			save_percentage: "मासिक 16% बचाएं",
			text: "एंट्रेगो के साथ सभी जानकारी आसानी से प्राप्त करें, और यदि आपके पास कोई अन्य प्रश्न हैं, तो हमसे संपर्क करने में संकोच न करें।",
			title: "जिस चीज़ के बारे में आप उत्सुक हैं, उसे जल्दी जानें",
		},
		cancel_anytime: "किसी भी समय रद्द करें",
		customize_package:
			"आप अतिरिक्त संग्रहण और उपयोगकर्ता खरीद सकते हैं और अपनी ज़रूरतों के अनुसार अपने पैकेज को कस्टमाइज़ कर सकते हैं",
		free_features_1: "10 शिपमेंट",
		free_features_2: "100 MB संग्रहण",
		free_features_3: "असीमित वाणिज्यिक भागीदार",
		free_features_4: "1 उपयोगकर्ता",
		free_plan: "मुफ़्त",
		free_price: "$0",
		free_regeneration: "",
		free_title: "मासिक",
		premium_annual_features_1: "20 उपयोगकर्ता",
		premium_annual_features_2: "100 भागीदार",
		premium_annual_features_3: "1000 शिपमेंट",
		premium_annual_features_4: "1000 चालान",
		premium_annual_price: "$83.25",
		premium_annual_regeneration: "$999 पर वार्षिक नवीनीकरण होगा",
		premium_annual_title: "वार्षिक",
		premium_monthly_features_1: "असीमित शिपमेंट",
		premium_monthly_features_2: "असीमित वाणिज्यिक भागीदार",
		premium_monthly_features_3: "2GB संग्रहण",
		premium_monthly_features_4: "3 उपयोगकर्ता",
		premium_monthly_price: "$99",
		premium_monthly_regeneration: "$99 पर मासिक नवीनीकरण होगा",
		premium_monthly_title: "मासिक",
		premium_plan: "प्रीमियम योजना",
		premium_plus_annual_features_1: "असीमित शिपमेंट",
		premium_plus_annual_features_2: "असीमित वाणिज्यिक भागीदार",
		premium_plus_annual_features_3: "5GB संग्रहण",
		premium_plus_annual_features_4: "5 उपयोगकर्ता",
		premium_plus_annual_price: "$83.25",
		premium_plus_annual_regeneration: "$999 पर वार्षिक नवीनीकरण होगा",
		premium_plus_annual_title: "वार्षिक",
		premium_plus_monthly_features_1: "असीमित शिपमेंट",
		premium_plus_monthly_features_2: "असीमित वाणिज्यिक भागीदार",
		premium_plus_monthly_features_3: "5GB संग्रहण",
		premium_plus_monthly_features_4: "5 उपयोगकर्ता",
		premium_plus_monthly_price: "$249",
		premium_plus_monthly_regeneration: "$249 पर मासिक नवीनीकरण होगा",
		premium_plus_monthly_title: "मासिक",
		premium_plus_plan: "प्रीमियम प्लस",
		pricing_text: "हम आपकी ज़रूरतों को आपके बजट को प्रभावित किए बिना सबसे अच्छे तरीके से पूरा कर सकते हैं।",
		pricing_title: "एंट्रेगो आपको सर्वोत्तम पैकेज और मूल्य की गारंटी देता है।",
		save_percentage: "मासिक 16% बचाएं",
	},
	$language: {
		ar: "العربية",
		de: "Deutsch",
		en: "English",
		es: "Español",
		fr: "Français",
		hi: "हिन्दी",
		ind: "Bahasa Indonesia",
		it: "Italiano",
		ja: "日本語",
		ko: "한국어",
		pt: "Português",
		ru: "Русский",
		tr: "Türkçe",
		vi: "Tiếng Việt",
		zh: "中文",
	},
	$languageLocal: {
		ar: "अरबी",
		de: "जर्मन",
		en: "अंग्रेज़ी",
		es: "स्पेनिश",
		fr: "फ़्रेंच",
		hi: "हिन्दी",
		ind: "इंडोनेशियाई",
		it: "इतालवी",
		ja: "जापानी",
		ko: "कोरियाई",
		pt: "पुर्तगाली",
		ru: "रूसी",
		tr: "तुर्की",
		vi: "वियतनामी",
		zh: "चीनी",
	},
	$message: {
		cannot_delete_verified_partner: "आप सत्यापित भागीदार को हटा नहीं सकते।",
		confirm: "क्या आप {function} को पक्का करना चाहते हैं?",
		created: "{item} बनाया गया।",
		deleted: "{item} हटाया गया।",
		error_on_view_file: "फ़ाइल देखने में त्रुटि आई।",
		file_oversize: "फ़ाइल का आकार अधिकतम {size} होना चाहिए।",
		invalid: "अमान्य {item}",
		migrated: "{item} माइग्रेट किया गया। ({value})",
		process_has_been_started: "आपकी {item} शुरू कर दी गई है।",
		sent: "{item} भेजा गया।",
		sent_email: "ईमेल भेजा गया। ({value})",
		sign_in_failed: "ईमेल या पासवर्ड गलत है",
		unsupported_file_type: "असमर्थित फ़ाइल प्रकार",
		updated: "{item} अपडेट किया गया।",
	},
	$pageTitles: {
		accept_invitation: "आमंत्रण स्वीकार करें",
		company_profile: "कंपनी प्रोफ़ाइल",
		home: "मुख्य पृष्ठ",
		landing_page: "निर्बाध डिजिटल ट्रैकिंग, साझा नियंत्रण",
		partners: "भागीदार",
		pdf_editor: "PDF संपादक",
		product_groups: "उत्पाद समूह",
		products: "उत्पाद",
		proforma_invoices: "प्रोफोर्मा इनवॉयस",
		shipment_details: "शिपमेंट विवरण",
		shipments: "शिपमेंट्स",
		sign_in: "साइन इन करें",
		sign_up: "साइन अप करें",
		subscription: "सदस्यता",
		user_profile: "उपयोगकर्ता प्रोफ़ाइल",
	},
	$partnershipStatusTooltip: {
		createdHereDescription:
			"पंजीकृत साझेदार नहीं हैं, आपने हमारे प्लेटफ़ॉर्म पर उनकी प्रोफ़ाइल बनाई है, ताकि आप उनकी जानकारी देख और संपादित कर सकें।",
		createdHereTitle: "यहाँ बनाया गया:",
		description: "आप अपने साझेदारों के साथ अपने संबंध की स्थिति को ट्रैक कर सकते हैं। यहाँ अंतर दिए गए हैं:",
		verifiedPartnersDescription:
			"इन साझेदारों ने आपका निमंत्रण स्वीकार कर लिया है और प्लेटफ़ॉर्म पर पंजीकरण किया है। उनकी जानकारी केवल दृश्य है और संपादित नहीं की जा सकती।",
		verifiedPartnersTitle: "सत्यापित साझेदार:",
	},
	$productGroup: {
		add_child: "बाल जोड़ें",
		add_new_product_group: "नया उत्पाद समूह जोड़ें",
		add_to_root: "रूट में जोड़ें",
		has_children_fail_message: "एक उत्पाद समूह जिसमें बाल हैं, उसे हटाया नहीं जा सकता",
		initial_message: "अपना पहला उत्पाद समूह बनाने के लिए इस {0} बटन का उपयोग करें।",
		name: "नाम",
		number_of_product: "उत्पादों की संख्या",
	},
	$proformaInvoice: {
		$actionDescription: {
			approve: "{name} को मंज़ूरी दी गई।",
			create: "{name} द्वारा बनाया गया।",
			create_attachment: "{name} ने एक अनुलग्नक बनाया।",
			delete_attachment: "{name} ने एक अनुलग्नक हटाया।",
			demand_revise: "{name} ने संशोधन की मांग की।",
			mark_as_processed: "{name} को संसाधित के रूप में चिह्नित किया गया।",
			reject: "{name} को अस्वीकार कर दिया गया।",
			revoke: "{name} को रद्द कर दिया गया।",
			send: "{name} को मूल्यांकन के लिए प्राप्तकर्ता को भेजा गया।",
			update: "{name} द्वारा अपडेट किया गया।",
			update_attachment: "{name} ने एक अनुलग्नक अपडेट किया।",
		},
		$actions: {
			approve: "मंज़ूरी दें",
			demand_revise: "संशोधन की मांग करें",
			mark_as_approved: "मंज़ूर किया गया",
			mark_as_processed: "संसाधित",
			mark_as_rejected: "अस्वीकृत",
			mark_as_revise_demanded: "संशोधन मांगा गया",
			mark_as_revoked: "रद्द",
			mark_as_sent: "भेजा गया",
			reject: "अस्वीकार करें",
			revoke: "रद्द करें",
			send: "भेजें",
		},
		$status: {
			approved: "मंज़ूर किया गया",
			pending_approval: "मंज़ूरी लंबित",
			pending_revise: "संशोधन लंबित",
			processed: "संसाधित",
			rejected: "अस्वीकृत",
			revoked: "रद्द",
			template: "टेम्पलेट",
		},
		approve_confirm_message: "क्या आप प्रोफोर्मा इनवॉयस को मंज़ूरी देने की पुष्टि करते हैं?",
		approve_success_message: "प्रोफोर्मा इनवॉयस को मंज़ूरी दी गई है।",
		demand_revise_description_message: "कृपया अपने संशोधन अपेक्षा के बारे में कुछ विवरण दें:",
		demand_revise_success_message: "प्रोफोर्मा इनवॉयस को संशोधन के लिए प्रेषक को वापस भेज दिया गया है।",
		mark_as_processed_confirm_message:
			"क्या आप प्रोफोर्मा इनवॉयस को संसाधित के रूप में चिह्नित करने की पुष्टि करते हैं?",
		mark_as_processed_success_message: "प्रोफोर्मा इनवॉयस को संसाधित के रूप में चिह्नित किया गया है।",
		no_attachment_text: "कोई अनुलग्नक अभी तक जोड़ा नहीं गया है।",
		reject_confirm_message: "क्या आप प्रोफोर्मा इनवॉयस को अस्वीकार करने की पुष्टि करते हैं?",
		reject_success_message: "प्रोफोर्मा इनवॉयस को अस्वीकार कर दिया गया है।",
		revoke_confirm_message: "क्या आप प्रोफोर्मा इनवॉयस को रद्द करने की पुष्टि करते हैं?",
		revoke_success_message: "प्रोफोर्मा इनवॉयस को रद्द कर दिया गया है।",
		send_confirm_message: "क्या आप प्रोफोर्मा इनवॉयस को प्राप्तकर्ता को भेजने की पुष्टि करते हैं?",
		send_success_message: "प्रोफोर्मा इनवॉयस भेजी गई है।",
	},
	$serviceMessage: {
		already_subs_module: "इस मॉड्यूल के लिए पहले से ही एक सदस्यता है।",
		bad_request: "खराब अनुरोध",
		code_already_used: "कोड पहले ही उपयोग किया जा चुका है।",
		container_status_not_available: "कंटेनर स्थिति उपलब्ध नहीं है।",
		existing_email_warning: "ईमेल पहले से मौजूद है।",
		existing_employee_error: "यह उपयोगकर्ता पहले से ही आपकी कंपनी में जोड़ा गया है।",
		existing_employee_for_email_warning: "इस ईमेल पर पहले ही आमंत्रण कोड भेजा जा चुका है",
		existing_invitation_warning: "इस ईमेल पर पहले ही आमंत्रण कोड भेजा जा चुका है।",
		existing_token_already_have_time: "मौजूदा टोकन का उपयोग करने के लिए पहले से पर्याप्त समय है।",
		existing_user: "उपयोगकर्ता पहले से मौजूद है।",
		five_min_reset_password_mail: "आपको पासवर्ड रीसेट ईमेल का अनुरोध करने के 5 मिनट बाद तक प्रतीक्षा करनी होगी।",
		forbidden: "निषिद्ध",
		have_no_corporation: "आपके पास कोई निगम नहीं है",
		have_no_custom_agency: "आपके पास कोई कस्टम एजेंसी नहीं है",
		have_no_employee: "आपके पास कोई कर्मचारी नहीं है",
		instance_can_no_delete_existing_booking_code: "मौजूदा बुकिंग कोड के कारण इंस्टेंस अब हटाया नहीं जा सकता।",
		instance_cannot_delete: "इंस्टेंस अब हटाया नहीं जा सकता।",
		instance_not_found: "इंस्टेंस नहीं मिला।",
		internal_server_error: "आंतरिक सर्वर त्रुटि",
		invalid_current_password: "अमान्य वर्तमान पासवर्ड।",
		invalid_identifier_or_password: "अमान्य पहचानकर्ता या पासवर्ड",
		invalid_invitation_code: "आमंत्रण कोड गलत है।",
		invalid_moving_request: "अमान्य चलती अनुरोध",
		invalid_operation: "अमान्य ऑपरेशन",
		invalid_refresh_token: "अमान्य रिफ्रेश टोकन।",
		invalid_unit: "अमान्य इकाई",
		invitation_accepting_is_due_to_the_admin: "आमंत्रण को स्वीकार करना प्रशासक पर निर्भर है।",
		invitation_already_discarded: "आमंत्रण पहले ही त्याग दिया गया है।",
		invitation_already_used: "आमंत्रण पहले ही उपयोग किया जा चुका है।",
		invitation_has_been_used: "आमंत्रण का उपयोग किया गया है।",
		invitation_not_exist_email: "आमंत्रण ईमेल पता नहीं है।",
		invitation_not_for_company: "यह आमंत्रण किसी कंपनी के लिए नहीं है।",
		invitation_not_for_custom_agency: "यह आमंत्रण कस्टम एजेंसी के लिए नहीं है।",
		invitation_not_found: "आमंत्रण नहीं मिला।",
		invitation_not_valid: "आमंत्रण अब मान्य नहीं है।",
		link_has_been_expired: "लिंक समाप्त हो गया है।",
		link_not_be_used_anymore: "इस लिंक का अब उपयोग नहीं किया जा सकता",
		missing_required_fields: "आवश्यक फ़ील्ड्स अनुपलब्ध हैं।",
		no_employee_found: "कोई कर्मचारी नहीं मिला",
		no_proper_partner_found: "कोई उचित भागीदार नहीं मिला।",
		not_acceptable: "स्वीकार्य नहीं",
		not_allowed_action: "आप इस क्रिया की अनुमति नहीं है।",
		not_allowed_use_api: "आप इस एपीआई का उपयोग करने की अनुमति नहीं है।",
		not_found: "नहीं मिला",
		not_have_purchase_subscription:
			"आपकी सदस्यता योजना ने परिभाषाओं के लिए अधिकतम सीमा तक पहुँच चुकी है। अधिक जोड़ने के लिए कृपया अपनी सदस्यता योजना को अपग्रेड करने पर विचार करें।",
		not_permitted_use_api: "आपको इस एपीआई का उपयोग करने की अनुमति नहीं है।",
		own_invitation_cannot_accept: "आप अपना आमंत्रण स्वीकार नहीं कर सकते।",
		parent_group_not_found: "मूल समूह नहीं मिला",
		partnerId_required: "भागीदार आईडी आवश्यक है।",
		partnership_already_exists: "भागीदार पहले से ही जोड़ा गया है।",
		password_cant_involve_space_char: "पासवर्ड में रिक्त स्थान चर नहीं हो सकता।",
		password_min_chars_least: "पासवर्ड कम से कम 6 अक्षर होना चाहिए।",
		payment_required: "कृपया इस प्रक्रिया के लिए सदस्यता लें।",
		paytr_integration_error: "PayTR एकीकरण में त्रुटि",
		ports_are_required: "बंदरगाह आवश्यक हैं।",
		product_group_tree_not_found: "उत्पाद समूह वृक्ष नहीं मिला",
		proforma_invoice_action_not_available: "प्रोफोर्मा इनवॉयस की स्थिति इस क्रिया के लिए उपलब्ध नहीं है।",
		proforma_invoice_template_update: "प्रोफोर्मा इनवॉयस को टेम्पलेट के रूप में अपडेट किया जाना चाहिए।",
		shipment_email_not_exist: "कृपया मान्य ईमेल दर्ज करें।",
		shipment_method_is_required: "शिपिंग विधि आवश्यक है।",
		shipping_term_is_required: "शिपिंग शर्त आवश्यक है।",
		someone_else_uses_this_email: "यह ईमेल पता कोई और उपयोग कर रहा है।",
		someone_else_uses_this_username: "यह उपयोगकर्ता नाम कोई और उपयोग कर रहा है।",
		something_went_wrong: "कुछ गलत हुआ",
		type_can_be_abstract_company: '"प्रकार" संपत्ति केवल "सार_कंपनी" हो सकती है',
		unauthorized: "अनधिकृत",
		undefined_result: "अपरिभाषित परिणाम",
		unexpected_error_occurred: "अप्रत्याशित त्रुटि आई",
		unit_already_added: "इकाई पहले से ही जोड़ी गई है।",
		user_already_employee: "उपयोगकर्ता पहले से ही एक कर्मचारी है।",
		user_already_exists: "उपयोगकर्ता पहले से ही एक कंपनी में है।",
		user_does_not_exist: "उपयोगकर्ता मौजूद नहीं है।",
		user_not_found: "उपयोगकर्ता नहीं मिला।",
		username_min_chars_error: '"उपयोगकर्ता नाम" कम से कम 6 वर्ण का होना चाहिए।',
	},
	$shipment: {
		$actionDescription: {
			$land: {
				create_container: "{name} ने एक वाहन जोड़ा।",
				delete_container: "{name} ने एक वाहन हटाया।",
				update_container: "{name} ने एक वाहन अपडेट किया।",
			},
			create: "{name} द्वारा बनाया गया।",
			create_attachment: "{name} ने एक अनुलग्नक जोड़ा।",
			create_container: "{name} ने एक कंटेनर जोड़ा।",
			delete_attachment: "{name} ने एक अनुलग्नक हटाया।",
			delete_container: "{name} ने एक कंटेनर हटाया।",
			update: "{name} द्वारा अपडेट किया गया।",
			update_attachment: "{name} ने एक अनुलग्नक अपडेट किया।",
			update_container: "{name} ने एक कंटेनर अपडेट किया।",
		},
		$containerStatus: {
			arrived: "पहुँच गया",
			off_load: "लोड से उतर गया",
			on_load: "लोड पर",
			on_way: "रास्ते में",
			reserved: "आरक्षित",
		},
		$method: {
			aerial: "हवाई",
			land: "भूमि",
			railway: "रेलवे",
			sea: "समुद्री",
		},
		$vehicle: {
			license_number: "लाइसेंस प्लेट",
		},
		archiving_success_message: "शिपमेंट संग्रहीत कर दिया गया है।",
		leave_message: "अपना संदेश या टिप्पणी छोड़ें",
		no_booking_info: "कोई बुकिंग जानकारी अभी तक सेट नहीं की गई है। {0} बटन का उपयोग करके जोड़ें।",
		no_transportation_info: "कोई विवरण जानकारी अभी तक सेट नहीं की गई है। {0} बटन का उपयोग करके सेट करें।",
		un_archiving_success_message: "शिपमेंट को अन-संग्रहीत कर दिया गया है।",
	},
	$shortening: {
		number: "संख्या",
	},
	$socialMedia: {
		facebook: "फेसबुक",
		instagram: "इंस्टाग्राम",
		linked_in: "लिंक्डइन",
		twitter: "ट्विटर",
	},
	$subscription: {
		$message: {
			check_your_payment_method: "आपकी सदस्यता का नवीनीकरण नहीं किया जा सका। कृपया अपना भुगतान विधि जांचें।",
			fail: "अप्रत्याशित त्रुटि आई। कृपया बाद में पुनः प्रयास करें। यदि समस्या बनी रहती है, तो कृपया info@antrego.net पर ईमेल भेजकर इसे रिपोर्ट करें।",
			ok: "खरीद प्रक्रिया शुरू कर दी गई है। प्रक्रिया का परिणाम ईमेल द्वारा सूचित किया जाएगा। जब भुगतान सफलतापूर्वक पूरा हो जाएगा, तो आपकी सदस्यता शुरू हो जाएगी।",
			payment_process_is_ongoing:
				"आपकी भुगतान प्रक्रिया जारी है। प्रक्रिया का परिणाम ईमेल द्वारा सूचित किया जाएगा। जब भुगतान सफलतापूर्वक पूरा हो जाएगा, तो आपकी सदस्यता शुरू हो जाएगी।",
			success: "आपकी सदस्यता सफलतापूर्वक बनाई गई है। आप आसानी से एंट्रेगो का उपयोग शुरू कर सकते हैं।",
			unsubscribed: "सदस्यता समाप्त",
			you_dont_have_any_subscription:
				"आपकी कोई सदस्यता नहीं है। कृपया सदस्यता पृष्ठ पर एक पैकेज चुनें और इसे आसानी से उपयोग करना शुरू करें।",
			you_dont_have_any_subscription_text: "जारी रखने के लिए कृपया एक योजना चुनें।",
			you_dont_have_any_subscription_title: "आपके पास कोई सक्रिय सदस्यता नहीं है।",
		},
	},
	$unit: {
		acres: "एकड़",
		boxes: "बक्से",
		cubic_meter: "घन मीटर",
		dozen: "दर्जन",
		feet: "फीट",
		fluid_ounces: "तरल औंस",
		gallons: "गैलन",
		gram: "ग्राम",
		hectare: "हेक्टेयर",
		inches: "इंच",
		kilogram: "किलोग्राम",
		kilometer: "किलोमीटर",
		liter: "लीटर",
		meter: "मीटर",
		miles: "मील",
		milligram: "मिलीग्राम",
		milliliter: "मिलीलीटर",
		millimeter: "मिलीमीटर",
		ounces: "औंस",
		pallets: "पैलेट्स",
		pieces: "टुकड़े",
		pounds: "पाउंड",
		quarts: "क्वार्ट्स",
		square_feet: "वर्ग फीट",
		square_inches: "वर्ग इंच",
		square_kilometer: "वर्ग किलोमीटर",
		square_meter: "वर्ग मीटर",
		square_miles: "वर्ग मील",
		square_yards: "वर्ग गज",
		ton: "टन",
		yards: "गज",
	},
	$validation: {
		email: "कृपया मान्य ईमेल दर्ज करें",
		invalid: "अमान्य {item}",
		max_length: "{item} को {length} से कम वर्णों का होना चाहिए",
		min_length: "{item} को {length} से अधिक वर्णों का होना चाहिए",
		must_length: "{item} को {length} वर्णों का होना चाहिए",
		only_number: "{item} एक संख्या होनी चाहिए",
		password_repeat_warning: "पासवर्ड पुनरावृत्ति नए पासवर्ड के समान नहीं है।",
		required: "{item} आवश्यक है",
		required_for_default_language: "{item} कंपनी की डिफ़ॉल्ट भाषा के लिए आवश्यक है।",
		you_must_approve_distance_sales_agreement: "आपको डिस्टेंस सेल्स अनुबंध को मंज़ूरी देनी होगी",
	},
	about: "के बारे में",
	accept_invitation: "आमंत्रण स्वीकार करें",
	accept_invitation_success_message: "आपने आमंत्रण का सफलतापूर्वक उपयोग किया है।",
	accessibility: "पहुँच",
	actions: "क्रियाएँ",
	active: "सक्रिय",
	activities: "गतिविधियाँ",
	add: "जोड़ें",
	add_item: "{item} जोड़ें",
	add_new_employee: "नया कर्मचारी जोड़ें",
	add_new_product: "नया उत्पाद जोड़ें",
	add_template: "टेम्पलेट जोड़ें",
	address: "पता",
	again: "फिर से",
	alert: "चेतावनी",
	already_have_employee_message: "आप पहले से ही एक कंपनी के कर्मचारी हैं।",
	amount: "राशि",
	approve: "मंज़ूरी दें",
	archive: "संग्रह",
	attachment: "अनुलग्नक",
	attachments: "अनुलग्नक",
	auto_attached_pdf: "स्वतः जोड़ा गया पीडीएफ़",
	bank: "बैंक",
	benefits: "लाभ",
	billed_to: "बिल प्राप्तकर्ता",
	booking: "बुकिंग",
	booking_code: "बुकिंग कोड",
	booking_details: "बुकिंग विवरण",
	business_partner: "व्यापार भागीदार",
	business_partners: "व्यापार भागीदार",
	cancel: "रद्द करें",
	change_password: "पासवर्ड बदलें",
	checking_invitation_code_message: "आमंत्रण कोड की जाँच कर रहा है",
	choose_image: "छवि चुनें",
	choose_your_plan: "अपनी योजना चुनें",
	clear: "साफ़ करें",
	click_to_play_the_video: "वीडियो चलाने के लिए क्लिक करें",
	close: "बंद करें",
	code: "कोड",
	commercial: "वाणिज्यिक",
	commercial_partner: "वाणिज्यिक भागीदार",
	commercial_partners: "वाणिज्यिक भागीदार",
	companies: "कंपनियाँ",
	company: "कंपनी",
	company_address: "कंपनी का पता",
	company_name: "कंपनी का नाम",
	company_profile: "कंपनी प्रोफ़ाइल",
	configuration: "विन्यास",
	confirm: "पुष्टि करें",
	confirm_distance_selling_agreement: "डिस्टेंस सेल्स अनुबंध की पुष्टि करें।",
	consult_us: "हमसे सलाह लें",
	contact: "संपर्क करें",
	contact_info: "संपर्क जानकारी",
	contact_text: "आश्वस्त रहें, हम जल्द ही आपसे संपर्क करेंगे।",
	contact_title: "हमसे सलाह लें, अपनी प्रक्रियाओं को सरल बनाएं",
	contact_to_us: "हमसे संपर्क करें",
	container: "कंटेनर",
	container_no: "कंटेनर नंबर",
	containers: "कंटेनर",
	content: "सामग्री",
	contract: "अनुबंध",
	copy_link: "लिंक कॉपी करें",
	corporation: "कंपनी",
	countries: "देश",
	country: "देश",
	create: "बनाएँ",
	create_a_new_account: "एक नया खाता बनाएं",
	create_company: "कंपनी बनाएं",
	create_document: "दस्तावेज़ बनाएँ",
	created_at: "बनाया गया",
	created_here: "यहां बनाया गया",
	credit_card_info: "क्रेडिट कार्ड जानकारी",
	credit_card_no: "क्रेडिट कार्ड नंबर",
	currencies: "मुद्राएँ",
	currency: "मुद्रा",
	current_password: "वर्तमान पासवर्ड",
	current_plan: "वर्तमान योजना",
	customer_company_address: "ग्राहक कंपनी का पता",
	customer_company_name: "ग्राहक कंपनी का नाम",
	customs: "सीमा शुल्क",
	customs_agencies: "कस्टम एजेंसियाँ",
	customs_agency: "कस्टम एजेंसी",
	cvv: "सीवीवी",
	daily: "दैनिक",
	dashboard: "डैशबोर्ड",
	date: "तिथि",
	day: "दिन",
	default: "डिफ़ॉल्ट",
	default_language: "डिफ़ॉल्ट भाषा",
	delete: "हटाएं",
	delete_account: "खाता हटाएं",
	delete_confirm_message: "क्या आप हटाने की पुष्टि करते हैं?",
	delete_document: "दस्तावेज़ हटाएं",
	delete_template: "टेम्पलेट हटाएं",
	description: "विवरण",
	detail: "विस्तार",
	discard: "त्यागें",
	distance_sales_agreement: "द डिस्टेंस सेल्स अनुबंध",
	document: "दस्तावेज़",
	document_actions: "दस्तावेज़ क्रियाएँ",
	document_added: "दस्तावेज़ जोड़ा गया",
	document_manager: "दस्तावेज़ प्रबंधक",
	document_name: "दस्तावेज़ का नाम",
	document_number: "दस्तावेज़ संख्या",
	document_overwrite: "संपादन को नए दस्तावेज़ के रूप में सहेजें",
	document_save_info: "यह दस्तावेज़ केवल इस प्रोफॉर्मा चालान के अंदर सहेजा जाएगा।",
	document_updated: "दस्तावेज़ अपडेट किया गया",
	download: "डाउनलोड करें",
	download_pdf: "PDF डाउनलोड करें",
	edit: "संपादित करें",
	edit_document: "दस्तावेज़ संपादित करें",
	edit_item: "{item} संपादित करें",
	edit_product: "उत्पाद संपादित करें",
	edit_profile: "प्रोफ़ाइल संपादित करें",
	edit_template: "टेम्पलेट संपादित करें",
	edit_your_profile_picture: "अपनी प्रोफ़ाइल चित्र संपादित करें",
	email: "ईमेल",
	employee: "कर्मचारी",
	employee_type: "कर्मचारी प्रकार",
	employees: "कर्मचारी",
	empty_data_warning:
		"इस क्षेत्र में अभी तक कोई डेटा नहीं है। आप नमूना डेटा के साथ प्लेटफ़ॉर्म का अन्वेषण करना शुरू कर सकते हैं।",
	estimated_arrival_day: "अनुमानित आगमन दिन",
	expense: "व्यय",
	expenses: "व्यय",
	expires_at: "समाप्ति तिथि",
	exported_containers: "निर्यात किए गए कंटेनर",
	fail: "असफल",
	failed: "असफल",
	faq: "अक्सर पूछे जाने वाले प्रश्न",
	feedback: "प्रतिपुष्टि",
	file: "फ़ाइल",
	file_name: "फ़ाइल का नाम",
	file_preview: "फ़ाइल पूर्वावलोकन",
	file_view: "फ़ाइल देखें",
	filter: "फ़िल्टर करें",
	first_name: "पहला नाम",
	first_name_last_name: "पहला नाम अंतिम नाम",
	forget_password: "पासवर्ड भूल गए",
	forget_password_message: "क्या आप अपना पासवर्ड भूल गए हैं?",
	forget_password_subtitle: "कृपया अपना ईमेल पता दर्ज करें ताकि आप अपना पासवर्ड रीसेट कर सकें।",
	free: "मुफ़्त",
	from: "से",
	general: "डिफ़ॉल्ट",
	get_started_now: "अब शुरू करें",
	give_us_feedback: "हमें अपनी प्रतिक्रिया दें",
	go_to_panel: "पैनल पर जाएं",
	hide: "छिपाएं",
	home_page: "मुख्य पृष्ठ",
	i_have_invitation_code: "मेरे पास आमंत्रण कोड है",
	imported_containers: "आयातित कंटेनर",
	in_progress: "प्रगति पर है",
	incoming: "आने वाला",
	incoming_shipments: "आने वाली शिपमेंट्स",
	initial: "प्रारंभिक",
	inputs: "सामग्री",
	invitation: "आमंत्रण",
	invitation_code: "आमंत्रण कोड",
	invitation_mail_sent: "आमंत्रण ईमेल भेजा गया",
	invitations: "आमंत्रण",
	invoice: "चालान",
	invoice_no: "चालान संख्या",
	join: "शामिल हों",
	join_company: "कंपनी में शामिल हों",
	language: "भाषा",
	language_options: "भाषा विकल्प",
	last_name: "अंतिम नाम",
	last_one_year: "पिछले 1 साल",
	latitude: "अक्षांश",
	left_a_comment: "एक टिप्पणी छोड़ी",
	link_copied: "लिंक कॉपी किया गया",
	link_copy_failed: "लिंक कॉपी विफल",
	loading_country: "लोडिंग देश",
	loading_point: "लोडिंग बिंदु",
	loading_port: "लोडिंग पोर्ट",
	log_in: "लॉग इन करें",
	logo: "लोगो",
	longitude: "देशांतर",
	mail_sent: "ईमेल भेजा गया",
	manage_profile: "प्रोफ़ाइल प्रबंधित करें",
	message: "संदेश",
	message_company_create: "कंपनी बनाई गई।",
	message_company_join: "कृपया निमंत्रण कोड दर्ज करें।",
	message_company_joined: "आप कंपनी में शामिल हुए हैं",
	message_employee_delete: "कर्मचारी हटाया गया।",
	message_employee_save: "कर्मचारी सहेजा गया।",
	message_join_company: "मौजूदा कंपनी में शामिल होने के लिए अपना कोड दर्ज करें",
	method: "विधि",
	migrate: "स्थानांतरित करें",
	migrate_partner: "कंपनी स्थानांतरित करें",
	mobile_number: "मोबाइल नंबर",
	month: "महीना",
	month_lowercase: "महीना",
	monthly: "मासिक",
	my_profile: "मेरा प्रोफ़ाइल",
	my_role: "मेरी भूमिका",
	name: "नाम",
	new: "नया",
	new_company_package: "नि: शुल्क",
	new_password: "नया पासवर्ड",
	new_shipment: "नई शिपमेंट",
	next: "अगला",
	no: "नहीं",
	no_content: "कोई सामग्री नहीं",
	no_entity: "कोई इकाई नहीं",
	no_entity_message: "कोई इकाई नहीं मिली।",
	no_text: "कोई पाठ नहीं",
	not_found_page_message: "क्षमा करें, मुझे यह पृष्ठ नहीं मिल सका :(",
	note: "नोट",
	notes: "टिप्पणियां",
	number_of_rows: "पंक्तियों की संख्या",
	ok: "ठीक है",
	operation_fail: "संचालन विफल",
	outgoing: "जाने वाला",
	outgoing_shipments: "जाने वाली शिपमेंट्स",
	packages: "पैकेज",
	packing_list_title: "पैकिंग सूची",
	parent_group: "मूल समूह",
	partner: "भागीदार",
	partners: "भागीदार",
	partnership_status: "साझेदारी स्थिति",
	passive: "निष्क्रिय",
	password: "पासवर्ड",
	pay: "भुगतान करें",
	payback: "वापसी",
	payback_confirm_message: "क्या आप वापसी की पुष्टि करते हैं?",
	payback_warning_message: "यदि आप जारी रखते हैं, तो आपकी सदस्यता समाप्त कर दी जाएगी।",
	payment: "भुगतान",
	payment_amount: "भुगतान राशि",
	payment_awaited: "भुगतान की प्रतीक्षा",
	payment_history: "भुगतान इतिहास",
	payment_info: "भुगतान जानकारी",
	payment_information: "भुगतान जानकारी",
	payment_success: "भुगतान सफल",
	payment_summary: "भुगतान सारांश",
	payment_will_be_made_3d_secure: "*आपका भुगतान 3डी सुरक्षित के साथ किया जाएगा।",
	pdf_attached_message: "पीडीएफ़ सफलतापूर्वक जोड़ा गया",
	pdf_editor: "पीडीएफ संपादक",
	pdf_name: "पीडीएफ नाम",
	pending_invitations: "लंबित निमंत्रण",
	per_month: "प्रति माह",
	phone_number: "फोन नंबर",
	plate_numbers: "प्लेट नंबर",
	premium: "प्रीमियम",
	premium_plus: "प्रीमियम प्लस",
	preview: "पूर्वावलोकन",
	previous: "पिछला",
	pricing: "मूल्य निर्धारण",
	process: "प्रक्रिया",
	product: "उत्पाद",
	product_group: "उत्पाद समूह",
	product_group_name: "उत्पाद समूह का नाम",
	product_groups: "उत्पाद समूह",
	product_name: "उत्पाद का नाम",
	products: "उत्पाद",
	profile: "प्रोफ़ाइल",
	proforma_invoice: "प्रोफोर्मा इनवॉयस",
	proforma_invoices: "प्रोफोर्मा इनवॉयस",
	proforma_number: "प्रोफोर्मा नंबर",
	proforma_title: "प्रोफार्मा चालान",
	purchase: "खरीद",
	quantity: "मात्रा",
	receiver: "प्राप्तकर्ता",
	receiver_company: "प्राप्तकर्ता कंपनी",
	receiver_customs_agency: "प्राप्तकर्ता कस्टम एजेंसी",
	redirection_to_home_link: "मुख्य पृष्ठ",
	redirection_to_home_message: "आप भी जा सकते हैं ",
	redirection_to_sign_in_message: "क्या आपके पास खाता है?",
	redirection_to_sign_up_message: "क्या आपके पास अभी तक एक खाता नहीं है?",
	reload: "पुनः लोड करें",
	renewal: "नवीनीकरण",
	repaid: "वापस किया गया",
	reset_password: "पासवर्ड रीसेट करें",
	rows_per_page: "प्रति पृष्ठ पंक्तियाँ",
	sales: "बिक्री",
	save: "सहेजें",
	save_as_document: "दस्तावेज़ के रूप में सहेजें",
	save_as_new_document: "नए दस्तावेज़ के रूप में सहेजें",
	save_as_new_template: "नए टेम्पलेट के रूप में सहेजें",
	save_as_template: "टेम्पलेट के रूप में सहेजें",
	save_changes: "परिवर्तन सहेजें",
	save_pdf_to_attachments: "पीडीएफ को अनुलग्नकों में सहेजें",
	saved_card_info: "सहेजा गया कार्ड जानकारी",
	search: "खोजें",
	select: "चुनें",
	select_document: "दस्तावेज़ चुनें",
	select_document_to_overwrite: "अधिलेखित करने के लिए दस्तावेज़ चुनें",
	select_file: "फ़ाइल चुनें",
	select_file_place_holder: "कृपया एक छवि चुनें",
	select_language: "भाषा चुनें",
	select_template: "टेम्पलेट चुनें",
	select_template_to_overwrite: "अधिलेखित करने के लिए टेम्पलेट चुनें",
	selected_partner: "चयनित भागीदार",
	send: "भेजें",
	send_again: "फिर से भेजें",
	send_email: "ईमेल भेजें",
	send_feedback: "प्रतिपुष्टि भेजें",
	send_invitation: "आमंत्रण भेजें",
	sender: "प्रेषक",
	sender_company: "प्रेषक कंपनी",
	sender_customs_agency: "प्रेषक कस्टम एजेंसी",
	settings: "सेटिंग्स",
	shipment: "शिपमेंट",
	shipment_method: "शिपमेंट विधि",
	shipment_type: "शिपमेंट प्रकार",
	shipments: "शिपमेंट्स",
	shipments_filter: "शिपमेंट्स फ़िल्टर",
	shipping_term: "शिपिंग शर्त",
	shipping_terms: "शिपिंग शर्तें",
	show: "दिखाएँ",
	sign_in: "साइन इन करें",
	sign_out: "साइन आउट करें",
	sign_out_confirm_message: "क्या आप साइन आउट की पुष्टि करते हैं?",
	sign_out_success_message: "अलविदा।",
	sign_up: "साइन अप करें",
	social_media: "सोशल मीडिया",
	social_media_account: "सोशल मीडिया खाता",
	start_time: "प्रारंभ समय",
	status: "स्थिति",
	subscribe: "सदस्यता लें",
	subscription: "सदस्यता",
	subscription_info_message: "आप सदस्यता अवधि के दौरान सभी सेवाओं का असीमित रूप से लाभ उठा सकते हैं।",
	subscription_warning_message:
		"**आपकी कार्ड जानकारी सुरक्षित रूप से संग्रहीत की जाएगी और आपकी सदस्यता को तब तक नियमित रूप से नवीनीकृत किया जाएगा जब तक आप इसे रद्द नहीं करते।",
	success: "सफलता",
	super: "सुपर",
	supported_files_img: "समर्थित फ़ाइलें: JPG, PNG।",
	switch_language: "भाषा बदलें",
	switch_theme: "थीम बदलें",
	table_inputs: "तालिका सामग्री",
	target: "लक्ष्य",
	target_partner: "लक्ष्य भागीदार",
	tax_number: "कर संख्या",
	template: "टेम्पलेट",
	template_actions: "टेम्पलेट क्रियाएँ",
	template_added: "टेम्पलेट जोड़ा गया",
	template_name: "टेम्पलेट का नाम",
	template_overwrite: "संपादन को नए टेम्पलेट के रूप में सहेजें",
	template_save_info: "सहेजे गए टेम्पलेट बाद में प्रोफॉर्मा चालानों और शिपमेंट में उपयोग के लिए उपलब्ध होंगे।",
	template_updated: "टेम्पलेट अपडेट किया गया",
	terms_of_sale_and_other_comments: "बिक्री की शर्तें - टिप्पणियाँ",
	theme: "थीम",
	this_month: "इस महीने",
	this_year: "इस वर्ष",
	title: "शीर्षक",
	to: "से",
	toDelete: "हटाएं",
	total: "कुल",
	total_amount: "कुल राशि",
	transaction_no: "लेन-देन संख्या",
	transactions: "लेनदेन",
	try_for_free: "मुफ़्त में आज़माएं",
	type: "प्रकार",
	types: "प्रकार",
	un_archive: "अन-संग्रह",
	unit: "इकाई",
	units: "इकाइयाँ",
	unloading_country: "अनलोडिंग देश",
	unloading_point: "अनलोडिंग बिंदु",
	unloading_port: "अनलोडिंग पोर्ट",
	unsubscribe: "सदस्यता समाप्त करें",
	unsubscribe_confirm_message:
		"यदि आप सदस्यता समाप्त करते हैं, तो आप सिस्टम सुविधाओं का प्रभावी रूप से उपयोग नहीं कर पाएंगे। क्या आप वास्तव में सदस्यता समाप्त करना चाहते हैं?",
	upload_new_image: "नई छवि अपलोड करें",
	use_code: "कोड का उपयोग करें",
	use_invitation_code: "आमंत्रण कोड का उपयोग करें",
	use_invitation_code_description: "अपना आमंत्रण कोड दर्ज करें जो आपके मेलबॉक्स में भेजा गया था।",
	use_invitation_code_success_message: "आमंत्रण कोड का सफलतापूर्वक उपयोग किया गया है।",
	user_profile: "मेरा प्रोफ़ाइल",
	vehicle: "वाहन",
	vehicles: "वाहन",
	verified_partner: "प्रमाणित साझेदार",
	verify_password: "पासवर्ड सत्यापित करें",
	visibility: "दृश्यता",
	warehouse: "गोदाम",
	warehouses: "गोदाम",
	website: "वेबसाइट",
	week: "सप्ताह",
	welcome: "स्वागत है",
	what_we_are_doing: "हम क्या कर रहे हैं",
	year: "वर्ष",
	year_lowercase: "साल",
	yearly: "वार्षिक",
	yes: "हाँ",
	you_dont_have_any_active_subscription: "आपके पास कोई सक्रिय सदस्यता नहीं है।",
};
